.business-fiber-block {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;

  background-color: $slate;
  background-image: url(../images/business-fiber-bg.jpg);
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100%;
  background-size: cover;
  margin-top: rem-calc(30);

  .business-fiber-block-content {
    position: relative;
    padding: rem-calc(40) 20%;
    text-align: center;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: rem-calc(-30);
      display: block;
      width: 100%;
      height: rem-calc(30);
      background-color: $slate;
    }
  }

  h2 {
    color: $lumos-purple;
    font-size: rem-calc(34);
    line-height: rem-calc(46);
    margin: 0 0 rem-calc(30);
  }

  .button {
    padding-left: 8%;
    padding-right: 8%;
    margin: rem-calc(7) rem-calc(16);
  }

  .feature-bubble {
    position: relative;
    padding: rem-calc(24) 13%;
    margin: 0 5% rem-calc(30);
    font-size: rem-calc(21);
    line-height: rem-calc(21);
    color: $lumos-purple;
    border: rem-calc(1) solid $purple;
    border-radius: rem-calc(10);
    background-color: rgba($white, 0.75);
    text-align: left;

    &:after {
      content: '\f00c';
      position: absolute;
      left: rem-calc(-30);
      top: rem-calc(-30);
      display: block;
      width: rem-calc(60);
      height: rem-calc(60);

      font-family: 'Font Awesome 5 Free';
      font-weight: bold;
      color: $white;
      text-align: center;
      font-size: rem-calc(30);
      line-height: rem-calc(60);


      background-color: $vivid-violet;
      border-radius: 50%;
    }
  }

}

@include breakpoint(small down) {
  .business-fiber-block {
    h2 {
      font-size: rem-calc(24);
      line-height: rem-calc(32);
      margin-bottom: rem-calc(50);
    }
  }
}

@include breakpoint(small down) {
  .business-fiber-block {
    .business-fiber-block-content {
      padding: rem-calc(35);
    }
    h2 {
      text-align: left;
    }
    .button {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
    .feature-bubble {
      margin: 0 0 rem-calc(30);
      font-size: rem-calc(18);
      line-height: rem-calc(24);
    }
  }
}
