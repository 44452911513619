/******************************************************************

Stylesheet: Main Stylesheet

Here's where the magic happens. Here is where you import
all of your Sass files so they can compile into one
CSS file.

******************************************************************/

@font-face {
  font-family: 'Authenia-Solid';
  src: url('fonts/Script-webfont/38FF02_0_0.eot');
  src: url('fonts/Script-webfont/38FF02_0_0.eot?#iefix') format('embedded-opentype'),
       url('fonts/Script-webfont/38FF02_0_0.woff2') format('woff2'),
       url('fonts/Script-webfont/38FF02_0_0.woff') format('woff'),
       url('fonts/Script-webfont/38FF02_0_0.ttf') format('truetype');
  font-display: swap;
}


// Light
@font-face {
  font-family: "EuclidCircularA";
  src: url("fonts/EuclidCircularA/EuclidCircularA-Light-WebS.woff2") format("woff2"),
       url("fonts/EuclidCircularA/EuclidCircularA-Light-WebS.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

// Light Italic
@font-face {
  font-family: "EuclidCircularA";
  src: url("fonts/EuclidCircularA/EuclidCircularA-LightItalic-WebS.woff2") format("woff2"),
       url("fonts/EuclidCircularA/EuclidCircularA-LightItalic-WebS.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}


// Regular
@font-face {
  font-family: "EuclidCircularA";
  src: url("fonts/EuclidCircularA/EuclidCircularA-Regular-WebS.woff2") format("woff2"),
       url("fonts/EuclidCircularA/EuclidCircularA-Regular-WebS.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

// Regular Italic
@font-face {
  font-family: "EuclidCircularA";
  src: url("fonts/EuclidCircularA/EuclidCircularA-RegularItalic-WebS.woff2") format("woff2"),
       url("fonts/EuclidCircularA/EuclidCircularA-RegularItalic-WebS.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}


// Medium
@font-face {
  font-family: "EuclidCircularA";
  src: url("fonts/EuclidCircularA/EuclidCircularA-Medium-WebS.woff2") format("woff2"),
       url("fonts/EuclidCircularA/EuclidCircularA-Medium-WebS.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

// Medium Italic
@font-face {
  font-family: "EuclidCircularA";
  src: url("fonts/EuclidCircularA/EuclidCircularA-MediumItalic-WebS.woff2") format("woff2"),
       url("fonts/EuclidCircularA/EuclidCircularA-MediumItalic-WebS.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}


// SemiBold
@font-face {
  font-family: "EuclidCircularA";
  src: url("fonts/EuclidCircularA/EuclidCircularA-Semibold-WebS.woff2") format("woff2"),
       url("fonts/EuclidCircularA/EuclidCircularA-Semibold-WebS.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

// SemiBold Italic
@font-face {
  font-family: "EuclidCircularA";
  src: url("fonts/EuclidCircularA/EuclidCircularA-SemiboldItalic-WebS.woff2") format("woff2"),
       url("fonts/EuclidCircularA/EuclidCircularA-SemiboldItalic-WebS.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}


// Bold
@font-face {
  font-family: "EuclidCircularA";
  src: url("fonts/EuclidCircularA/EuclidCircularA-Bold-WebS.woff2") format("woff2"),
       url("fonts/EuclidCircularA/EuclidCircularA-Bold-WebS.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

// Bold Italic
@font-face {
  font-family: "EuclidCircularA";
  src: url("fonts/EuclidCircularA/EuclidCircularA-Bold-WebS.woff2") format("woff2"),
       url("fonts/EuclidCircularA/EuclidCircularA-Bold-WebS.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}



// Import local Foundation overrides - Don't link to Bower version as it will be overwritten whenever updated
@import "settings";

// Import foundation.scss from vendor files
@import "../../../node_modules/foundation-sites/scss/foundation";

// Import Motion-UI from vendor files
@import "../../../node_modules/motion-ui/src/motion-ui";
@include motion-ui-transitions;
@include motion-ui-animations;

// Select the components you want to use
@include foundation-global-styles;

// Select the grid you would like to use
// @include foundation-grid;
// @include foundation-flex-grid;
@include foundation-xy-grid-classes;

@include foundation-flex-classes;
@include foundation-typography;
@include foundation-forms;
@include foundation-button;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;

// Import your custom styles
@import "global";
// @import "layout";

@import "responsive-table";

// @import "header";
@import "headerimage";
// @import "adpjobsblock";
// @import "businesshomepageheader";
// @import "aqui";
@import "customeralert";
@import "promobanner";
@import "solutionsofferings";
@import "orderdetails";
@import "pagesubmenublock";
@import "calloutblock";
@import "addresschecker";
// @import "myaccount";
@import "archive";
@import "blogblock";
// @import "helpcenter";
@import "servicesblock";
// @import "serviceslider";
@import "promoheaderblock";
// @import "availablefeatureblock";
@import "fiberspeedblock";
@import "featuredproductsblock";
@import "businessfiberblock";
@import "businesscontactblock";
@import "leadershipblock";
@import "careerperksblock";
@import "businessproductsblock";
// @import "wholesalebannerblock";
@import "businessproductpageblocks";
// @import "techsolservicesblock";
// @import "techsolproduct";
// @import "techsolblockdiv";
// @import "techsollocations";
// @import "techsolsideimage";
// @import "techsolgateddocument";
@import "investorblock";
@import "basicpostsblock";
@import "contactusblock";
// @import "techsoldocs";
// @import "techsolauthorblock";
@import "videoblock";
@import "shopfiber";
// @import "tvlisting";
@import "chatblock";
@import "simpleheaderblock";
@import "outageblock";
@import "offers";
@import "specialheaderblock";
@import "faqblock";
@import "specialcolorbannerblock";
@import "specialtwocolumnblock";
// @import "footer";

// @import "myaccount/account-main";

// @import "wf-business-header";
@import "businesslanding";
// @import "wf-promo-landing-header";
@import "page-header-block";

@import "blocks/blocks";
@import "components/components";
@import "myaccount/myaccount";
