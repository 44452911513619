header.header, header.header.sticky.is-stuck {
  z-index: 999;
}

.top-nav {
  position: relative;
  width: 100%;
  background-color: $lumos-purple;
  color: $white;

  &:not(.campaign) {
    .header-logo-container {
      max-width: 180px;
    }
  }

  .logo-row {
    display: flex;
    align-items: center;
    padding: 10px 0;

    @include breakpoint(large) {
      padding: 0;
    }

    > div {
      flex: 1 1 auto;
    }

    .mobile-burger {
      flex: 0 0 auto;
    }
  }

  .mobile-nav-buttons {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;

    button.button {
      margin-left: 8px !important;
    }
  }

  .header-logo-container {
    padding: 0 10px;
    img {
      width: auto;
      height: 24px;
      // margin: 0 20px 0 20px;

      @include breakpoint(large) {
        width: 121px;
        height: auto;
        max-height: unset;
        margin-bottom: 28px;
      }
    }
  }

  ul {
    background-color: transparent;
  }

  .button:not([type="submit"]) {
    margin: 0;
    &.search {
      color: $white;
      &:before {
        background-image: url(../images/icon-magnifier/white.svg) !important;
      }
    }
  }

  .no-padding {
    padding-left: 0;
    padding-right: 0;
  }

  .header-service-address {
    max-width: 50%;
    -ms-overflow-style: none;
    overflow: hidden;
  }

  &.campaign {
    #primary-nav {
      p, a {
        color: $vivid-violet;
        margin: 0;
      }

      a:hover {
        text-decoration: underline;
      }
    }

    .header-service-address {
      max-width: 100%;
    }

    .header-logo-container {
      img {
        @include breakpoint(large) {
          margin: 15px 0;
        }
      }
    }
  }

  &.offers {
    position: relative;
    background-color: $white;
    padding: rem-calc(25) 0;
    border-bottom: rem-calc(20) solid $dark-blue;

    // #header_logo, #header_logo_alt {
    //   max-width: rem-calc(250);
    //   padding: 0;
    // }

    #primary-nav {
      color: $ns-green;
      font-size: rem-calc(12);
      display: inline-block;
      text-transform: uppercase;
      font-family: "Lato","HelveticaNeue","Helvetica Neue",Helvetica,Arial,sans-serif;
      letter-spacing: rem-calc(2);
      a {
        color: $ns-green;
      }
      p {
        margin: 0;
      }
    }
  }
}

.mobile-burger {
  .burger-menu {
    position: relative;
    display: block;
    width: 26px;
    padding: 0;
    margin: 0 10px;
    transition: all 0.5s ease;

    &:before, &:after, >span {
      content: '';
      position: relative;
      top: 0;
      display: block;
      width: 100%;
      height: 4px;
      background-color: $vivid-violet;
      border-radius: 4px;
      margin: 0 auto;
      transition: all 0.5s ease;
    }

    &:after {
      margin-top: 5px;
    }

    // span {
    //   font-size: 0;
    //   margin-top: 5px;
    // }

    &[aria-expanded="true"] {
      transform: rotate(180deg);
      &:before {
        top: 9px;
        transform: rotate(-45deg);
      }
      > span {
        width: 4px;
      }
      &:after {
        top: -9px;
        transform: rotate(45deg);
      }
    }
  }
}

.is-dropdown-submenu {
  min-width: unset;
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-right>a:after,
.is-dropdown-submenu .is-dropdown-submenu-parent.opens-right>button.link-button:after {
  display: none;
}

.account-banner {
  position: fixed;
  width: 100%;
  color: $white;
  height: rem-calc(60);
  background-color: $ns-promo-green;
  z-index: 100;
  font-size: rem-calc(20);
  font-weight: bold;
  transform: translateY(rem-calc(-60));
  transition: transform .5s ease;

  @include breakpoint(medium down) {
    font-size: rem-calc(13);
    line-height: rem-calc(19);
  }

  br {
    display: none;
    @include breakpoint(medium down) {
      display: block;
    }
  }

  & > .grid-container {
    height: 100%;
    & > .grid-x {
      justify-content: center;
      height: 100%;
      .cell {
        position: relative;
        @include breakpoint(medium down) {
          padding-left: 5%;
          padding-right: 5%;
        }
      }
    }
  }

  &.open {
    transform: translateY(rem-calc(0));
  }

  .assistant-close {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    font-size: rem-calc(20);
    line-height: rem-calc(40);
    width: rem-calc(40);
    height: rem-calc(40);
    border: rem-calc(1) solid $white;
    border-radius: 50%;
    text-align: center;

    &:hover {
      color: $ns-promo-green;
      background-color: $white;
      border-color: $white;
    }

    @include breakpoint(medium down) {
      right: 5%;
    }
  }

  a {
    color:$white;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.top-bar {
  background-color: transparent;

  ul {
    background-color: transparent;
  }

  a {
    color: $light-blue;
  }
}

// .header-logo-container {
//   padding: 0 10px;
// }

// #header_logo, #header_logo_alt {
//   width: 100%;
//   max-width: 121px;
// }

#primary-nav, #primary-nav-mobile {
  a {
    text-align: center;
  }
  .menu .active>a, .menu .is-active>a,
  .menu .current-page-ancestor>a {
    background-color: transparent;
    color: $white;
    border-color: $white;
  }

  & > .menu {
    // margin-top: rem-calc(18);
    flex-wrap: nowrap;
    align-items: flex-end;

    & > li {
      @include breakpoint(large) {
        flex: 1 1 auto;
      }
      &:last-child {
        & > a {
          margin-right: 0;
        }
      }
      & > a {
        text-align: center;
        padding: rem-calc(25) 2px;
        margin-right: rem-calc(10);
        color: $white;
        font-weight: bold;
        font-size: 16px;
        line-height: 120%;
        transition: all 0.2s ease;
        border-bottom: rem-calc(8) solid transparent;
        background-color: transparent;

        @include breakpoint(large) {
          width: 100%;
          display: inline-block;
        }

        &:after {
          // display: none;
          border-color: transparent;
          right: 0;
          width: 16px;
          height: 16px;
          background-image: url(../images/icon-chevron-down-white.svg);
          background-repeat: no-repeat;
          background-size: 100% 100%;
          background-position: center;
          border: 0;
          top: 50%;
          transform: translateY(-50%);
          right: 0px;
          margin: 0;
        }

        &:hover {
          color: $white;
          border-color: $vivid-violet;
        }
      }
      &.is-dropdown-submenu-parent {
        & > a {
          padding-right: 20px;
        }
      }
    }
  }

  .main-submenu {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    height: 0;
    max-height: 0vh;
    width: 100%;
    max-width: 1200px;
    background: linear-gradient(to bottom, rgba($lumos-purple,1) 0%, rgba($lumos-purple,1) 20%, rgba($lumos-purple,0.95) 100%);
    border: 0;
    list-style: none;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    -ms-overflow-style: none;
    overflow: hidden;
    display: flex;
    opacity: 0;
    margin: 0;
    transition: all 0.6s ease;

    .close-menu-accordion, .mobile-close {
      display: none;
      position: absolute;
      top: 0;
      right: rem-calc(15);
      border-color: transparent;
    }

    &:before {
      content: '';
      position: absolute;
      left: -50vw;
      top: rem-calc(-2);
      display: block;
      width: 150vw;
      border-bottom: rem-calc(2) solid $white;
    }

    &.js-dropdown-active, &.is-active {
      opacity: 1;
      height: auto;
      max-height: 100vh;
      -ms-overflow-style: none;
      overflow: unset;
      align-items: flex-end;
      overflow: scroll;
    }

    .menu-desc {
      width: 100%;
      text-align: center;
      font-size: rem-calc(24);
      padding: rem-calc(30);
      border-bottom: rem-calc(1) solid $white;
      color: $white;

      p {
        margin: 0;
      }
    }

    a {
      font-size: rem-calc(15);
      font-weight: bold;
    }

    li:not(.menu-desc) {
      flex: 0 0 25%;
      padding: rem-calc(30);
      transition: all 0.4s ease;


      p {
        text-align: center;
      }

      .button {
        // border-color: $ns-light-blue;
        // color: $white;

        &.green {
          background-color: $purple;
          color: $white;
          border-color: $purple;
        }
      }

      &:hover {
        background-color: $white;
        color: $lumos-purple;
        transform: translateY(rem-calc(-5));

        .button {
          border-color: $purple;
          background-color: $purple;
          color: $white;

          // &.green {
          //   background-color: $ns-green;
          //   color: $white;
          //   border-color: $white;
          // }

          &:hover {
            background: $purple;
            color: $white;
          }
        }
      }
    }
  }

  .regular-menu {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    height: 0;
    max-height: 0vh;
    min-width: 100%;
    max-width: 1200px;
    background: linear-gradient(to bottom, rgba($lumos-purple,1) 0%, rgba($lumos-purple,1) 20%, rgba($lumos-purple,0.95) 100%);
    border: 0;
    list-style: none;
    // align-items: flex-start;
    justify-content: center;
    // flex-wrap: nowrap;
    flex-flow: row;
    // flex-direction: row;
    -ms-overflow-style: none;
    overflow: hidden;
    display: flex;
    opacity: 0;
    margin: 0;
    -ms-overflow-style: none;
    overflow: 0;
    transition: all 0.6s ease;

    &:before {
      content: '';
      position: absolute;
      left: -50vw;
      top: rem-calc(-2);
      display: block;
      width: 150vw;
      border-bottom: rem-calc(2) solid $white;
    }

    &.js-dropdown-active, &.is-active {
      opacity: 1;
      height: auto;
      max-height: 100vh;
      -ms-overflow-style: none;
      overflow: unset;
    }

    .close-menu-accordion, .mobile-close {
      display: none;
      position: absolute;
      top: rem-calc(5);
      right: rem-calc(5);
      text-align: center;
      border-color: transparent;
      z-index:1;

      &:hover {
        background-color: transparent;
      }
    }

    a {
      color: $white;
    }

    & > li {
      position: relative;
      display: block;
      // width: 20%;
      padding: rem-calc(10) rem-calc(30) rem-calc(25);
      transition: all 0.4s ease;

      &.dark-blue {
        background-color: rgba($vivid-violet, 0.4);
      }

      &.medium-dark-blue {
        background-color: rgba($vivid-violet, 0.2);
      }

      a {
        display: inline-block;
        text-decoration: none;
        // text-decoration: underline;
        &:hover {
          color: $white;
          text-decoration: underline;
        }
        &[href="#"] {
          display: none;
        }
      }

      &:before, &:after {
        content: '';
        position: absolute;
        top: 0;
        display: block;
        width: 0;
        height: 100%;
        opacity: 0.25;
        transition: all 0.2s ease;
      }

      &:before {
        right: 100%;
        background: linear-gradient(to right, rgba($lumos-purple,0) 0%, rgba($lumos-purple,1) 100%);
      }
      &:after {
        left: 100%;
        background: linear-gradient(to right, rgba($lumos-purple,1) 0%, rgba($lumos-purple,0) 100%);
      }

      &:first-child {
        &:before {
          display: none;
        }
      }

      &:last-child {
        &:after {
          display: none;
        }
      }

      &:hover {
        background-color: rgba($lumos-purple, 0.9);
        &:before, &:after {
          width: rem-calc(30);
        }

        ul.menu {
          & > li:not(.button) {
            & > a {
              color: $white;
              &:hover {
                color: $white;
              }
            }
          }
          & > li.button {
            // &:hover {
            //   background-color: $white;
            //   color: $ns-green;
            //   a {
            //     color: $ns-green;
            //   }
            // }
          }
        }

      }

      & > .button {
        font-size: rem-calc(16);
        font-weight: bold;
        border-bottom: rem-calc(1) solid $vivid-violet;
        padding: rem-calc(15) 0;
        margin: 0 rem-calc(0) rem-calc(20);
        width: 100%;
        &:hover {
          color: $white;
        }
      }

      .menu .active>a, .menu .is-active>a,
      .menu .current-page-ancestor>a {
        background-color: transparent;
      }
      ul.menu {
        display: block;
        position: relative;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border: 0;
        & > li {
          border: 0;
          font-size: rem-calc(13);
          font-weight: bold;
          padding: 0;
          // padding: 0 rem-calc(30);
          p {
            margin: 0;
          }
          & > a {
            font-size: rem-calc(13);
            border: 0;
            padding: rem-calc(9) 0;
            color: $white;

            &.underline {
              // text-decoration: underline;
            }
          }
          &.button {
            // background: $ns-green;
            // border: rem-calc(1) solid $white;
            // border-radius: rem-calc(5);
            // text-align: center;

            a {
              width: 100%;
              text-align: center;
              &:hover {
                // text-decoration: none;
                // color: $white;
              }
            }

            &:hover {
              // text-decoration: none;
              // color: $white;
            }
          }
        }
      }
    }

    ul {
      padding: 0;
      margin: 0;
      list-style: none;
    }

    li {
      border: 0;
      a {
        color: $white;
        padding: rem-calc(9) 0;
        &:hover {

        }
      }
    }

    .button {
      border: 0;
      border-radius: 0;
      text-align: left;
      color: $white;

      &:hover {

      }
    }

    @include breakpoint(medium down) {
      flex-flow: column;
      & > li {
        padding: 0;
        ul.menu {
          display: none;
          background-color: $lumos-purple;
          border-bottom: rem-calc(1) solid $vivid-violet;
          a {
            color: $white;
          }
          &.js-dropdown-active {
            display: block;
            max-height: 35vh;
            overflow-y: scroll;

            &::-webkit-scrollbar {
                -webkit-appearance: none;
                width: 7px;
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 4px;
                background-color: rgba(255,255,255,.5);
                -webkit-box-shadow: 0 0 1px rgba(0,0,0,.5);
            }
          }

          p {
            padding-top: rem-calc(13);
            text-align: center;
          }

        }
        a {
          font-size: rem-calc(16);
        }
      }
      li {
        width: 100%;
        padding-bottom: 0;
        .button {
          width: 100%;
          text-align: center;
          margin: 0;
        }
      }
    }
  }

  .normal-menu {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    height: 0;
    max-height: 0vh;
    width: 100%;
    max-width: 1200px;
    background-color: $lumos-purple;

    border: 0;
    list-style: none;
    justify-content: center;
    display: block;
    text-align: center;

    padding: 5px 0;

    opacity: 0;
    margin: 0;
    -ms-overflow-style: none;
    overflow: 0;

    transition: all 0.6s ease;

    // &:after {
    //   content: '';
    //   position: absolute;
    //   left: -50vw;
    //   top: rem-calc(-2);
    //   display: block;
    //   width: 150vw;
    //   border-bottom: rem-calc(2) solid $white;
    // }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      display: block;
      width: 150vw;
      border-bottom: 2px solid $white;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      display: block;
      width: 100vw;
      height: 100%;
      background-color: $lumos-purple;
      z-index: -1;
    }


    &.js-dropdown-active, &.is-active {
      opacity: 1;
      height: auto;
      max-height: 100vh;
      -ms-overflow-style: none;
      overflow: unset;
    }

    & > li {
      position: relative;
      display: inline-block;
      vertical-align: top;
      width: auto;
      // width: 20%;
      padding: 0;
      transition: all 0.4s ease;

      & > .button {
        font-size: rem-calc(16);
        font-weight: bold;
        border:0;
        padding: 15px 5px;
        margin: 0 10px 0;
        color: $white;
        &:hover {
          color: $vivid-violet;
        }
      }

      .menu .active>a, .menu .is-active>a,
      .menu .current-page-ancestor>a {
        background-color: transparent;
      }

      ul.menu {
        display: block;
        position: relative;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border: 0;
        & > li {
          border: 0;
          font-size: rem-calc(13);
          font-weight: bold;
          padding: 0 rem-calc(30);
          & > a {
            font-size: rem-calc(13);
            border: 0;
            padding: rem-calc(9) 0;
            color: $white;
            &:hover {
              color: $ns-light-blue;
            }
          }
        }
      }
    }
  }
}

#primary-nav-mobile{
  position: relative;
  height: auto;

  // &:before {
  //   content: '';
  //   position: absolute;
  //   top: 0;
  //   left: 50%;
  //   transform: translateX(-50%);
  //   display: block;
  //   width: 100vw;
  //   height: 100%;
  //   background: $white;
  //   z-index: 0;
  // }
  &:after {
    content: '';
    z-index: 0;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    width: 100vw;
    height: 100%;
    background-color: $lumos-purple;
    z-index: 0;
  }

  .menu .active>a, .menu .is-active>a,
  .menu .current-page-ancestor>a {
    background-color: transparent;
    color: $white;
    border-color: $white;
  }

  & > .menu {
    position: relative;
    flex-wrap: nowrap;
    // margin-top: rem-calc(18);
    align-items: center;
    justify-content: center;
    z-index: 1;

    & > li {
      width: auto;
      & > a {
        padding: rem-calc(15) rem-calc(15) rem-calc(15) rem-calc(15);
        margin: 0;
        font-size: rem-calc(16);
        line-height: rem-calc(16);
        border: 0;
        font-weight: 500;

        @include breakpoint(small down) {
          padding: rem-calc(15) rem-calc(7) rem-calc(15);
          font-size: rem-calc(13);
        }

        &:hover {
          background-color: $purple-pastel-2;
          color: $lumos-purple;
          .button {
            border-color: $white;
            color: $white;
            &:hover {
              background: $middle-blue;
              color: $white;
            }
          }
        }
      }
    }
  }

  .main-submenu {
    height: 100vh;
    padding-bottom: rem-calc(30);
    z-index: 9999;

    .menu-desc {
      border: 0;
      padding: rem-calc(25) rem-calc(50);
      p {
        font-size: rem-calc(22);
        line-height: rem-calc(26);
      }
    }
    li:not(.menu-desc) {
      width: 100%;
      display: block;
      flex-basis: auto;
      padding: 0;
      p {
        display: none;
      }
      .button {
        width: 80%;
        margin: rem-calc(10) auto;
        &:hover {
          border-color: $white;
          background-color: $white;
          color: $nav-lower-blue;
        }
      }
      &:hover {
        background-color: transparent;
        color: inherit;
        transform: translateY(0);
        .button {
          border-color: $white;
          background-color: $white;
          color: $nav-lower-blue;
          &:hover {
            border-color: $white;
            background-color: $white;
            color: $nav-lower-blue;
          }
        }
      }
    }
  }

}

#utility-nav, #utility-nav-left {
  font-size: rem-calc(13);
  // padding-bottom: rem-calc(20);
  li {
    margin: 0 0;
  }
  a {
    line-height: rem-calc(13);
    padding: 15px 15px 10px;
    transition: all 0.2s ease;
    white-space: nowrap;
    color: $white;
    // border-top: rem-calc(6) solid transparent;

    &:hover {
      color: $vivid-violet;
      text-decoration: underline;
    }
  }

  .active>a, .is-active>a,
  .current-page-ancestor>a {
    background-color: transparent;
    color: $white;
    border-color: $white;
  }

  a[href*="#ns-account-login"], a[href*="?ns_logout=1"] {
    color: $vivid-violet;

    &:hover {
      text-decoration: underline;
    }
  }
}

#utility-nav-left {
  li {
    &:first-child {
      margin-left: 0;
    }
  }
}

#utility-nav {
  justify-content: flex-end;

}

#availability-status {
  position: relative;
  font-size: rem-calc(12);

  .full-address {
    max-width: 200px;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
  }
  .avail-icon {
    font-size: rem-calc(12);
    display: inline-block;
    margin-right: 4px;

    &.alert {
      color: $vivid-violet;
      height: 13px;
    }

    &.no {
      color: $vivid-violet;
      display: none;
    }

    &.yes {
      color: $vivid-violet;
      display: none;
    }

    &.edit {
      position: absolute;
      // height: 100%;
      // height: calc(100% - 16px);
      // top: 16px;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      padding-left: rem-calc(3);
      color: $vivid-violet;
      display: none;
      margin-left: 4px;
      // background-color: $dark-blue;
      // &:after {
      //   content: '';
      //   position: absolute;
      //   top: 0;
      //   right: 100%;
      //   height: 100%;
      //   width: rem-calc(10);
      //   background: linear-gradient(to right, rgba($dark-blue, 0) 0%,rgba($dark-blue, 1) 100%);
      // }
    }
  }
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    &:hover {
      color: $vivid-violet;
    }
  }

  .full-address {

  }
}

.top-nav-spacer {
  display: block;
  width: 100%;
  height: 102px;
  // margin-bottom: rem-calc(60);

  @include breakpoint(large) {
    height: 125px;
  }

  &.non-sticky {
    @include breakpoint(medium down) {
      display: none;
    }
  }
}

.mobile-burger {
  .burger-menu {
    position: relative;
    display: block;
    width: 26px;
    padding: 0;
    margin: 0 9px;
    transition: all 0.5s ease;

    &:before, &:after, > span {
      content: '';
      position: relative;
      top: 0;
      display: block;
      width: 100%;
      height: 4px;
      background-color: $vivid-violet;
      border-radius: 4px;
      margin: 0 auto;
      transition: all 0.5s ease;
    }

    > span {
      top: 3px;
    }

    &:after {
      margin-top: 6px;
    }

    // span {
    //   font-size: 0;
    //   margin-top: 5px;
    // }

    &[aria-expanded="true"] {
      transform: rotate(180deg);
      &:before {
        top: 5px;
        transform: rotate(-45deg);
      }
      > span {
        width: 0px;
      }
      &:after {
        top: -9px;
        transform: rotate(45deg);
      }
    }
  }
}

.search-chat-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  width: 100%;
  justify-content: flex-end;
}

// .mobile-nav-buttons {
//   // padding-bottom: rem-calc(18);
//   padding-right: rem-calc(15);
// }

#off-canvas {
  background-color: rgba($lumos-purple, 0.95);
  .grid-container {
    padding-left: 0;
    padding-right: 0;
  }
}

// .off-canvas-close {
//   font-size: rem-calc(30);
//   line-height: rem-calc(63);
//   background-color: $nav-lower-blue;
//
//   a {
//     color: $white;
//   }
// }

.off-canvas-search {
  position: relative;
  height: rem-calc(63);
  background-color: $white;

  display: flex;
  justify-content: center;
  align-items: center;

  .search-form {
    border: 0;
    border-radius: 0;
    line-height: rem-calc(63);
    padding: 0 10px;
    label {
      // position: absolute;
      // left: 0;
      // top: 0;
      // width: 100%;
    }
    input[type=search] {
      font-size: rem-calc(14);
      line-height: rem-calc(63);
      height: rem-calc(63);
      // padding-right: rem-calc(60);
      // padding-left: rem-calc(30);
      color: $lumos-purple;
      width: 100%;
      background-color: transparent;
      border-radius: 0;

      &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: $lumos-purple;
      }
      &::-moz-placeholder { /* Firefox 19+ */
        color: $lumos-purple;
      }
      &:-ms-input-placeholder { /* IE 10+ */
        color: $lumos-purple;
      }
      &:-moz-placeholder { /* Firefox 18- */
        color: $lumos-purple;
      }
    }

    button[type="submit"] {
      @extend .button;
      margin-right: 10px !important;
    }

    // button[type=submit] {
    //   position: absolute;
    //   right: 0;
    //   display: inline-block;
    //   font-size: 0;
    //   background-color: $nav-lower-blue;
    //   color: $white;
    //   width: rem-calc(36);
    //   cursor: pointer;
    //   transition: all 0.2s ease;
    //   padding: 0;
    //   margin: rem-calc(14) rem-calc(20) rem-calc(13) 0;
    //   text-shadow: none;
    //   text-align: center;
    //   i {
    //     font-size: rem-calc(18);
    //     line-height: rem-calc(36);
    //   }
    //   &:hover {
    //     color: $link-blue;
    //   }
    // }
  }
}

.off-canvas-close, .off-canvas-search {
  border-bottom: rem-calc(1) solid $white;
}

.off-canvas-menu {
  color: $white;
  // margin-left: rem-calc(15);
  margin-bottom: rem-calc(100);

  .menu .active>a {
    background-color: $vivid-violet;
    color: $lumos-purple;
  }

  li {
    border-top: rem-calc(1) solid rgba($white, 0.25);
    padding-top: rem-calc(10);
    a {
      outline: none;
      font-size: rem-calc(24);
      line-height: rem-calc(50);
    }
    &:last-child {
      border-bottom: rem-calc(1) solid rgba($white, 0.25);
    }
  }

  a {
    color: $white;
  }
}

.off-canvas-menu-container {
  -ms-overflow-style: none;
  overflow: scroll;
  height: 100%;
}

.mobile-address-checker-container {
  background-color: $dark-blue-5;
  color: $white;
  padding: rem-calc(12) rem-calc(15);
  position: absolute;
  bottom: 0;
  width: 100%;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    width: 100vw;
    height: 100%;
    background: $white;
    z-index: 0;
  }
  &:after {
    content: '';
    z-index: 0;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    width: 100vw;
    height: 100%;
    background-color: rgba($lumos-purple, 0.8);
    z-index: 0;
  }

  > .grid-x {
    position: relative;
    z-index: 1;
  }

  h3 {
    font-size: rem-calc(22);
    font-weight: bold;
    margin: 0;
  }

  p {
    font-size: rem-calc(16);
    margin: 0;
  }

  .button {
    margin: 0;
    font-size: rem-calc(18);
    line-height: 36px;
    width: 38px;
    text-align: center;
    padding: 0;
  }
}

#off-canvas-search {

}

.off-canvas-search {

  .search-form {
    border: 0;
    border-radius: 0;
    height: rem-calc(52);
    width: 100%;

    label {
      float: left;
      width: 100%;
      height: 100%;
    }

    input[type=search] {
      color: $text-gray;
      font-size: rem-calc(18);
      line-height: rem-calc(52);
      height: rem-calc(52);
    }

    // button[type=submit] {
    //   float: right;
    //   width: rem-calc(38);
    //   text-align: center;
    //   padding: 0;
    //   margin: rem-calc(7) rem-calc(15) rem-calc(7) 0;
    //   background-color: $middle-blue;
    //   color: #fff;
    //   outline: none;
    //   i {
    //     font-size: rem-calc(18);
    //     line-height: rem-calc(38);
    //     text-shadow: none;
    //   }
    // }
  }
}

// @include breakpoint(medium down) {
//   .top-nav {
//     // height: rem-calc(63);
//     padding-top: 0;
//     border-color: $nav-lower-blue;
//     & > .grid-container:first-child{
//       padding-left: 0;
//       padding-right: 0;
//     }
//
//     // .button, .button.chat, .button.search {
//     //   width: rem-calc(35);
//     //   text-align: center;
//     //   font-size: rem-calc(18);
//     //   padding: 0;
//     //   line-height: rem-calc(35);
//     //   margin: 0;
//     // }
//   }
//
//   #header_logo, #header_logo_alt {
//     width: auto;
//     height: rem-calc(50);
//     padding-left: rem-calc(5);
//     padding-right: rem-calc(5);
//     padding-top: rem-calc(10);
//     padding-bottom: rem-calc(10);
//   }
//
//   #primary-nav, #primary-nav-mobile {
//     .main-submenu, .regular-menu {
//       .close-menu-accordion, .mobile-close {
//         display: block;
//         border-color: transparent;
//       }
//     }
//
//     .regular-menu {
//       flex-flow: column;
//       & > li {
//         padding: 0;
//         ul.menu {
//           display: none;
//           background-color: $dark-blue;
//           border-bottom: rem-calc(1) solid $ns-light-blue;
//           a {
//             color: $white;
//           }
//           &.js-dropdown-active {
//             display: block;
//             max-height: 35vh;
//             overflow-y: scroll;
//
//             &::-webkit-scrollbar {
//                 -webkit-appearance: none;
//                 width: 7px;
//             }
//             &::-webkit-scrollbar-thumb {
//                 border-radius: 4px;
//                 background-color: rgba(255,255,255,.5);
//                 -webkit-box-shadow: 0 0 1px rgba(0,0,0,.5);
//             }
//           }
//
//           p {
//             padding-top: rem-calc(13);
//             text-align: center;
//           }
//
//         }
//         a {
//           font-size: rem-calc(16);
//         }
//       }
//       li {
//         width: 100%;
//         padding-bottom: 0;
//         .button {
//           width: 100%;
//           text-align: center;
//           margin: 0;
//         }
//       }
//     }
//   }
// }
//
// @include breakpoint(small down) {
//   .mobile-address-checker-container {
//     h3 {
//       font-size: rem-calc(14);
//     }
//
//     p {
//       font-size: rem-calc(12);
//     }
//   }
// }
