.lumos-page-header {
  position: relative;
  width: 100%;
  // height: 60vh;
  height: 540px;
  @include breakpoint(medium down) {
    height: auto;
    padding: rem-calc(40) 0;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    width: 100vw;
    height: 10px;
    background-color: $ns-yellow;
  }

  &.no-cross {
    .background-image {
      width: 100%;
    }
    .background-overlay {
      &:before {
        background-image: linear-gradient(to right, rgba($black, 1) 0%, rgba($black, 0) 100%);
      }
    }
    &:after {
      display: none;
    }
  }

  .background-image-container {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    width: 100vw;
    height: 100%;
  }

  .background-image {
    position: absolute;
    top: 0;
    right: 0;
    width: 82%;
    height: 100%;

    background-size: cover;
    background-position: 100% 25%;
    background-repeat: no-repeat;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 100%;
      width: 100vw;
      height: 100%;
      background: $black;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: -30px;
      display: block;
      width: 20%;
      height: 100%;
      background: linear-gradient(to right, rgba($black, 1) 0%, rgba($black, 1) 20%, rgba($black, 0) 100%);
    }
  }

  .background-overlay {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    width: 100%;
    height: 100%;
    mix-blend-mode: multiply;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      width: 77%;
      height: 100%;

      content: '';
      background-image: url(../images/header-overlay-cross-gradient.svg);
      background-size: 100% auto;
      background-position: 0% 50%;
      background-repeat: no-repeat;
      // opacity: 0.5;

      @include breakpoint(xlarge down) {
        background-size: auto 100%;
        // background-position: 100% 50%;
      }

      @include breakpoint(large down) {
        width: 100%;
      }

      @include breakpoint(medium down) {

      }
    }
  }

  .grid-container {
    position: relative;
    height: 100%;
    padding: 0 5%;

    font-size: rem-calc(16);
    line-height: rem-calc(24);
    color: $white;

    & > .grid-x {
      height: 100%;
    }

    .copy-container {
      // padding-right: 3%;
      @include breakpoint(medium down) {
        padding-right: 0;
      }
    }

    h2 {
      font-size: rem-calc(60);
      line-height: rem-calc(64) !important;
      margin-bottom: rem-calc(30);
      font-weight: 100;

      @include breakpoint(medium down) {
        font-size: rem-calc(40);
        line-height: rem-calc(44) !important;
        margin-bottom: rem-calc(20);
      }

      &.smaller {
        font-size: rem-calc(40);
        @include breakpoint(medium down) {
          font-size: rem-calc(32);
        }
      }

      & > span:not(.no-wrap):not(.no-break) {
        display: block;
        font-size: rem-calc(80);
        line-height: rem-calc(110);
        font-weight: 100;
        color: $ns-yellow;

        @include breakpoint(medium down) {
          font-size: rem-calc(62);
          line-height: rem-calc(92);
        }

        & > span {
          font-size: rem-calc(31);
          line-height: rem-calc(31);
        }
      }
    }

    p, ul {
      font-size: rem-calc(20);
      line-height: rem-calc(28);
      margin-bottom: 30px;
      @include breakpoint(medium down) {
        font-size: rem-calc(14);
        line-height: rem-calc(22);
        margin-bottom: rem-calc(20);
      }
      &:last-of-type {
        margin-bottom: 0;
      }
      strong {
        text-transform: uppercase;
      }
    }

    .button {
      margin-bottom: 0;
    }
  }


  &.light-theme {

    &:after {
      display: none;
    }
    .background-overlay {
      mix-blend-mode: normal;
      @include breakpoint(medium) {

      }
      &:before {
        background-image: linear-gradient(to right, rgba($white, 1) 0%, rgba($white, 0.8) 100%);

        @include breakpoint(medium) {
          background-image: linear-gradient(to right, rgba($white, 1) 0%, rgba($white, 0) 100%);
        }
      }
    }
    .background-image {
      width: 100%;
      &:before, &:after {
        display: none;
      }
    }

    .grid-container {
      color: $text-gray;

      h1, h2, h3 {
        color: $light-blue-4;
      }

      h2 {
        font-weight: 400;
        @include breakpoint(medium) {
          font-size: 47px;
          line-height: 1;
          font-weight: 400;
        }
      }

      p {
        font-size: 18px;
        @include breakpoint(medium) {
          font-size: 27px;
          line-height: 40px;
        }
      }
    }

    &.twopanel {
      position: relative;
      padding-top: 0;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        display: block;
        width: 100vw;
        height: 100%;
        background-color: $white;
      }

      @include breakpoint(large) {
        height: 460px;
      }

      .grid-container {
        h2 {
          text-transform: uppercase;
          font-size: rem-calc(30);
          line-height: rem-calc(32) !important;
          color: $ns-promo-features-blue;
          @include breakpoint(large) {
            font-size: rem-calc(36);
            line-height: rem-calc(38) !important;
          }
        }
        p {
          font-size: 18px;
          line-height: 26px;
          color: $light-blue-5;
          @include breakpoint(medium) {
            font-size: 24px;
            line-height: 32px;
          }
        }
      }

      .copy-container {
        padding: 20px 0;
        @include breakpoint(medium) {
          padding-right: 8%;
        }
      }

      .bg-container {
        position: relative;
        height: 100%;
        min-height: 300px !important;

        @include breakpoint(medium) {
          min-height: 500px !important;
        }

        .background-image-container {
          left: 50%;
          transform: translateX(-50%);
          width: 100vw;

          @include breakpoint(large) {
            left: 0;
            transform: none;
            width: 50vw;
          }

          .background-image {
            background-position: 50% 100%;
          }
        }
      }

    }
  }
}
