.outage-page {
  background-color: #c8ced4;

  .general-content, .outage-block, .outage-form .business-contact-block {
    & > .grid-container > .grid-x > .cell {
      background-color: $light-gray;
      position: relative;
      &:before, &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        opacity: .07;
        width: rem-calc(90);
        height: 100%;
      }
      &:before {
        background: linear-gradient(90deg,transparent,transparent 75%,#000);
        right: 100%;
      }

      &:after {
        background: linear-gradient(-90deg,transparent,transparent 75%,#000);
        left: 100%;
      }
    }
  }

  .outage-form .business-contact-block {
    & > .grid-container > .grid-x > .cell {
      &:before, &:after {
        height: calc(100% + 18px);
      }
    }
  }

}

.outage-block {
  position: relative;

  hr {
    content: '';
    display: block;
    border-bottom: rem-calc(2) solid $medium-gray;
    width: 35%;
    padding-top: rem-calc(40);
    margin: 0 auto rem-calc(40);
  }

  & > .grid-container > .grid-x {
    justify-content: center;
  }

  &:before {
    margin-top: 0;
  }
}

.service-statuses {
  padding: rem-calc(30) rem-calc(60) 0;

  @include breakpoint(small down) {
    padding: rem-calc(30) 0 0;
  }

  ul {
    list-style: none;
    margin: 0;
    columns: 1;
    margin: 0 auto;
    width: 50%;

    @include breakpoint(medium down) {
      width: 75%;
    }

    @include breakpoint(small down) {
      columns: 1;
      width: 100%;
    }
  }

  .status {
    background-color: $medium-gray-4;
    margin-bottom: rem-calc(20);
    border-radius: rem-calc(6);

    .cell {
      padding: rem-calc(15);
    }
    .icon {
      position: relative;
      font-size: rem-calc(50);
      color: $medium-gray;
      i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .fa-check-circle {
        color: $ns-green;
      }
    }
    .info {
      font-size: rem-calc(18);
      border-left: rem-calc(2) solid $medium-gray;
    }

    p {
      margin: 0;

      &.date-time {
        text-transform: uppercase;
      }
    }
  }
}

.outage-form {
  padding-bottom: rem-calc(45);
  .business-contact-block {
      background-color: transparent;
  }

  .business-contact-block .gform_fields li.inline-field .ginput_container_radio,
  .business-contact-block .gform_fields li.inline-field ul.gfield_radio {
    & > li {
      display: block;

      @include breakpoint(small down) {
        display: inline-block;
      }
    }
  }

  .business-contact-block form.two-column .gform_body>.gform_fields {
    display: flex;
    flex-wrap: wrap;
    & > li {
      flex: 1 0 50%;

      &:nth-last-child(3) {
        flex: 1 0 30%;
      }

      &:nth-last-child(2) {
        flex: 1 0 70%;
      }

      @include breakpoint(small down) {
        flex: 1 0 100%;
        &:nth-last-child(3) {
          flex: 1 0 100%;
        }
        &:nth-last-child(2) {
          flex: 1 0 100%;
        }
      }
    }
  }


  .business-contact-block-content {
    padding-top: 0;
  }

  &:after {
    content: '';
    display: block;
    max-width: rem-calc(1084);
    height: 18px;
    background-image: url(../images/ns-footer-stripe.svg);
    background-size: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    margin: 0 auto;
  }
}
