.section-header {
  margin-bottom: rem-calc(16);
}

.fa-leaf {
  color: $ns-green;
}

.account-subhead {
  margin-bottom: rem-calc(20);
}

.red-button {
  color: $alert-red !important;
}

.button.white {
  padding-left: 10px;
  padding-right: 10px;
  &:hover, &:focus {
    background-color: $slate;
  }
}

.ns-login-page {
  // margin-top: rem-calc(30);
  // margin-bottom: rem-calc(60);

  h1 {
    // margin-bottom: rem-calc(30);
  }

  hr {
    border-bottom: rem-calc(1) solid $lumos-purple;
  }

  & > .grid-x {
    & > .cell {
      margin-top: rem-calc(30);
      margin-bottom: rem-calc(30);
    }
  }

  // button {
  //   color: $link-blue;
  //   cursor: pointer;
  //   // &.button {
  //   //   color: $white;
  //   //   // &.hollow {
  //   //   //   color: $link-blue;
  //   //   // }
  //   // }
  // }

  // .button {
  //   width: 100%;
  //   @include breakpoint(medium) {
  //     width: auto;
  //     padding-left: 20%;
  //     padding-right: 20%;
  //   }
  // }

  .login-links {
    margin-bottom: rem-calc(16);
  }

  .new-user {
    // border-bottom: rem-calc(1) solid $ns-light-blue;
    // @include breakpoint(medium) {
    //   border-right: rem-calc(1) solid $ns-light-blue;
    // }
  }
}

.date-picker {
  background-color: $white;
  border: rem-calc(1) solid $medium-gray-3;
  border-radius: rem-calc(5);
  background-image: url(../images/icon-calendar.svg);
  background-size: auto 60%;
  background-position: 95% 50%;
  background-repeat: no-repeat;
  cursor: pointer;

  // &.is-invalid-input {
  //   background-color: #faedeb;
  // }

  &[readonly] {
    cursor: pointer;
  }
}

.alert-banner {
  background-color: $ns-yellow;
  color: $text-gray;
  font-weight: bold;
  font-size: rem-calc(20);
  padding: rem-calc(15) 0;

  position: fixed;
  width: 100%;
  min-height: rem-calc(60);
  z-index: 100;
  transform: translateY(-100%);
  transition: transform 0.5s ease;

  .cell {
    padding-right: 40px;
  }

  a {
    color: $text-gray;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  &.error {
    background-color: $alert-red;
    color: $white;

    a {
      color: $white;
    }

    .alert-close {
      color: $white;
      border: rem-calc(1) solid $white;
    }
  }

  .cell {
    position: relative;
  }

  &.open {
    transform: translateY(rem-calc(0));
    .promo-open {
      top: 0%;
    }
  }

  .alert-close {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    color: $text-gray;
    font-size: rem-calc(20);
    line-height: rem-calc(40);
    display: block;
    width: rem-calc(40);
    height: rem-calc(40);
    border: rem-calc(1) solid  $text-gray;
    border-radius: 50%;
    text-align: center;
    transition: all 0.2s ease;

    &:hover {
      color: $ns-promo-green;
      background-color: $white;
      border-color: $ns-promo-green;
    }
  }
}

.feature-spotlight {
  position: relative;
  background-color: $slate;
  margin-top: rem-calc(60);
  padding-top: rem-calc(30);
  padding-bottom: 0;
  border-bottom: 8px solid $lumos-purple;

  .cell {
    position: relative;
    z-index: 1;
  }

  // & > .grid-container > .grid-x {
  //   justify-content: center;
  //
  //   & > .cell > .grid-x {
  //     justify-content: center;
  //   }
  // }

  .callout-row {
    .callout {
      padding: rem-calc(20) rem-calc(10);
    }
    .cell {
      // margin: 0 rem-calc(5);
      margin-bottom: rem-calc(30);
      &:first-child {
        // margin-left: 0;
      }
      &:last-child {
        // margin-right: 0;
      }
    }
  }

  .callout {
    border-color: $purple;
    background: linear-gradient(0deg, rgba(176, 3, 211, 0.1), rgba(176, 3, 211, 0.1)), #FFFFFF;
    padding: 20px 10%;

    @include breakpoint(medium) {
      margin-bottom: rem-calc(90);
    }
    &.white {
      background-color: #FFF;
      border-color: $purple;
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: rem-calc(-30);
    left: 0;
    width: 50%;
    background-color: $slate;
    height: rem-calc(30);
  }

  &:after {
    // content: '';
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    //
    // width: 80%;
    // height: 80%;
    // border-radius: 50%;
    //
    // background-image: radial-gradient(circle closest-side, $white, rgba($white, 0) );
    //
    // z-index: 2;
    // pointer-events: none;
  }
  h2 {
    font-size: rem-calc(34);
    // color: $lumos-purple;
    margin-bottom: rem-calc(32);
  }

  h3 {
    font-size: rem-calc(24);
    // color: $lumos-purple;
    margin-bottom: rem-calc(15);
  }

  h6 {
    font-size: rem-calc(16);
    font-weight: bold;
    margin-bottom: rem-calc(16);
  }

  .headline {
    margin-bottom: rem-calc(32);
  }

  // .button {
  //   max-width: 100%;
  //   min-width: 75%;
  //   width: 75%;
  //   display: block;
  //   margin-top: 0;
  //   margin-left: auto;
  //   margin-right: auto;
  //
  //   @include breakpoint(medium) {
  //     display: inline-block;
  //     width: auto;
  //     margin-left: 0;
  //   }
  //
  //   // &:hover {
  //   //   background-color: #003a5f;
  //   //   color: $white;
  //   // }
  //
  //   &:last-child {
  //     margin-bottom: 0;
  //   }
  // }

  .background {
    height: 307px;
    width: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    @include breakpoint(medium) {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      z-index: 0;
      margin-left: -15px;
      background-position: bottom right;
    }
  }
}


.payment-form {
  position: relative;
  background-color: $white;

  border-top: rem-calc(1) solid $dark-gray;
  border-right: rem-calc(1) solid $bubble-border;
  border-bottom: rem-calc(1) solid $bubble-border;
  border-left: rem-calc(1) solid $bubble-border;

  border-bottom-right-radius: rem-calc(5);
  border-bottom-left-radius: rem-calc(5);

  margin-top: 10px;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: -10px;

  hr {
    border-bottom: rem-calc(2) dotted $lumos-purple;
  }

  .cell {
    small {
      margin: 0;
    }
    &.slim {
      padding-top: 0;
      padding-bottom: 0;
    }
    .select-wrap {
      select {
        font-size:rem-calc(16);
      }
    }

  }
}

#automatic-payment-form {
  @include breakpoint(medium) {
    border-top-right-radius: 0;
  }
}

.schedule-payment-row {
  & > .cell {
    display: flex;
    flex-direction: row;
    align-items: center;

    input {

    }
  }
}

.payment-selector {

  .cell {
    position: relative;
    padding: rem-calc(10) rem-calc(15);

    span, input, select, button:not(.close-button) {
      position: relative;
      z-index: 1;
      margin: 0 !important;
    }

    & > .grid-x {
      justify-content: left;
      &.payment-form {
        justify-content: center;
      }
    }
  }
  label {
    margin-right: 0;

    .helpButton {
      float: none;
      display: none;
      position: absolute;
      left: 14px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
      background-color: $white;
    }
  }
  input[type="number"] {
    width: 35%;
    margin: 0;
  }
  input[type="radio"]+label {
  }
  input[type="radio"]:checked+label {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: rem-calc(5);
      border: rem-calc(1) solid $purple;
      background: linear-gradient(0deg, rgba(176, 3, 211, 0.1), rgba(176, 3, 211, 0.1)), #FFFFFF;
    }
  }
  input[type="radio"]:disabled+label {
    color: $medium-gray;
    .helpButton {
      display: block;
    }
  }
  @include breakpoint(large) {
    input[type="radio"][data-toggle-show]:checked+label {
      &:before {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: 0;
      }
      &:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: rem-calc(1);
        border-left: rem-calc(1) solid $purple;
        border-right: rem-calc(1) solid $purple;
        background: linear-gradient(0deg, rgba(176, 3, 211, 0.1), rgba(176, 3, 211, 0.1)), #FFFFFF;
      }
    }
  }

  .select-wrap {
    @include breakpoint(small down) {
      margin-bottom: rem-calc(10) !important;
    }
    select {
      font-size:rem-calc(13);
    }
  }

  // .button.white {
  //   padding: 11px 15px;
  //   width: 100%;
  //   font-size: rem-calc(13);
  //   background-color: transparent;
  //   border-color: $purple;
  //   color: $purple;
  //   i {
  //     display: none;
  //   }
  //
  //   &:focus, &:hover {
  //     background-color: $white;
  //     outline: none;
  //   }
  //
  //   &.open {
  //     border-color: $dark-gray;
  //     color: $text-gray;
  //     background-color: $white;
  //     position: relative;
  //     text-align: left;
  //     border-color: $dark-gray;
  //     border-bottom-left-radius: 0;
  //     border-bottom-right-radius: 0;
  //     border-bottom: 0;
  //     i {
  //       display: block;
  //       float: right;
  //     }
  //     &:after {
  //       content: '';
  //       position: absolute;
  //       top: 100%;
  //       left: -1px;
  //       width: calc(100% + 2px);
  //       height: 13px;
  //       display: block;
  //       background-color: $white;
  //       border-left: rem-calc(1) solid $dark-gray;
  //       border-right: rem-calc(1) solid $dark-gray;
  //     }
  //   }
  // }
}

.account-header {
  padding-top: rem-calc(30);
  label {
    display: inline-block;
    font-weight: bfold;
  }
  .select-wrap {
    width: auto;
    display: inline-block;
    background-color: $white;
    margin-left: rem-calc(10);
    margin-bottom: 0;
    select {
      padding-right: rem-calc(40);
      appearance: none;
      background-image: none;
    }
  }

  .breadcrumbs {
    font-size: rem-calc(13);
    margin-left: rem-calc(-10);
    a, span[aria-current="page"] {
      display: inline-block;
      margin: 0 rem-calc(10);
    }
    a {
      color: $text-gray;
      font-weight: bold;
      &:hover {
        color: $link-blue;
        text-decoration: none;
      }
    }
  }



  .account-selector {
    label, .select-wrap, a {
      width: 100%;
      display: block;
      margin-bottom: rem-calc(8);
      margin-left: 0;
      padding-left: 0;
      @include breakpoint(medium) {
        width: auto;
        display: inline-block;
        margin-right: rem-calc(10);
      }
    }
  }
}

.callout {

  .price {
    font-size: rem-calc(34);
    sup {
      font-size: rem-calc(24);
    }
  }

  .bubble {
    padding: rem-calc(20) rem-calc(30);
  }

  label[for="totaldue"], label[for="otheramount"] {
    font-weight: normal;
    span[data-total-due] {
      font-weight: bold;
    }
  }

  & > .grid-x {
    justify-content: center;
    margin: 20px 0px;

    &.submitrow {
      margin: 20px 0px;
    }
  }

  & > .grid-x.bubble {
    margin: rem-calc(20) 0;
  }

  &.slim {
    padding: rem-calc(20);
    & > .grid-x {
      margin: 0;
    }

    h6 {
      margin-bottom: rem-calc(5);
    }
  }

  &.highlight {
    border-color: $purple;
    background: linear-gradient(0deg, rgba(176, 3, 211, 0.1), rgba(176, 3, 211, 0.1)), #FFFFFF;
    font-weight: bold;
    & > .grid-x {
      margin: 0;
    }
    &.yellow {
      border: rem-calc(2) solid $purple;
      background-color: $white;
    }
  }

  &.clear {
    border: 0;
    background-color: transparent;
    padding: 0;
    &:before {
      background-color: $light-gray;
    }
  }

  &.edit-mode {

  }

  &:before {
    display: none;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $white;
    z-index: 10;
  }

  &:after {
    // display: none;
    // content: '\f1ce';
    // font-family: Font Awesome\ 5 Free;
    // font-weight: 700;
    // font-size: rem-calc(80);
    // color: $purple;
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    // animation: endLoading 0.25s linear forwards;

    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
    width: 80px;
    height: 80px;
    border: 15px solid rgba($purple, 0.25);
    border-top-color: $purple;
    border-radius: 50%;
    animation: spin 1s ease-in-out infinite;

    z-index: 10;
  }

  &.loading {
    overflow: hidden;
    min-height: 200px;

    &:before, &:after {
      display: block;
    }

    &:after {
      // animation: loadingAccountSection 4s linear infinite;
    }

    &.submit {
      &:before {
        background-color: rgba($white, 0.8);
      }
    }
  }

  &.white {
    background-color: #FFF;
    border-color: #0074BD;
  }

} // end .callout


.cell.button-container {
  position: relative;

  &:before {
    display: none;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($white,0.9);
    z-index: 10;
  }

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
    width: 40px;
    height: 40px;
    border: 8px solid rgba($purple, 0.25);
    border-top-color: $purple;
    border-radius: 50%;
    animation: spin 1s ease-in-out infinite;

    z-index: 10;
  }

  &.loading {
    // overflow: hidden;

    &:before, &:after {
      display: block;
    }

    &:after {
      // animation: loadingAccountSection 4s linear infinite;
    }

    &.submit {
      &:before {
        background-color: rgba($white, 0.8);
      }
    }
  }
}

.account-screen {

  & > .grid-x {
    justify-content: center;

    & > .cell > .grid-x {
      justify-content: center;
    }
  }

  h1 {
    font-family: $body-font-faimly-header;
  }

  h2, h3 {
    font-family: $body-font-family;
    display: inline-block;
  }

  h1 {
    font-size: rem-calc(26);
    line-height: rem-calc(36);
    // color: $lumos-purple;

    @include breakpoint(medium) {
      font-size: rem-calc(36);
      line-height: rem-calc(46);
    }

    @include breakpoint(large) {
      font-size: rem-calc(42);
      line-height: rem-calc(52);
    }
  }

  h2 {
    font-size: rem-calc(24);
    line-height: rem-calc(34);
    // color: $lumos-purple;
    margin-bottom: 0;
    &.offset {
      padding-left: 1rem;
      padding-right: 1rem;
    }

  }

  h3, .amount {
    font-size: rem-calc(24);
    line-height: rem-calc(24);
    font-weight: bold;
    margin: 0 0 rem-calc(12);

    &.big {
      font-size: rem-calc(34);
      line-height: rem-calc(42);
    }
  }

  h4 {
    font-size: rem-calc(13);
    font-weight: bold;
    // color: $dark-gray;
  }

  h5 {
    font-size: rem-calc(16);
    text-transform: uppercase;
    font-weight: bold;
    // color: $light-blue;
    margin-bottom: rem-calc(16);
  }

  h6 {
    font-weight: bold;
    // color: $lumos-purple;
    margin-bottom: 1rem;
  }

  table {
    border: 0;
    button {
      cursor: pointer;
    }
    thead, tbody {
      border: 0;
    }
    thead {
      tr {
        background-color: $lumos-purple;
        color: $white;
        border: 0;
        &:hover {
          background-color: $lumos-purple;
          color: $white;
        }
      }
      th {
        background-color: transparent;
        text-align: center;
      }
    }
    tbody {
      tr {
        border: 0;
        border-bottom: rem-calc(1) solid $form-gray;

        @include breakpoint(small down) {
          border: rem-calc(1) solid $form-gray;
          border-top: rem-calc(5) solid $lumos-purple;
        }

        &:hover {
          // background-color: $bubble-blue;
          background: linear-gradient(0deg, rgba(176, 3, 211, 0.1), rgba(176, 3, 211, 0.1)), #FFFFFF;
        }

        &:nth-child(2n) {
          background: $medium-gray-6;
          &:hover {
            background: linear-gradient(0deg, rgba(176, 3, 211, 0.1), rgba(176, 3, 211, 0.1)), #FFFFFF;
          }
        }
      }
      td {
        text-align: center;
      }
    }

    &.authorized-list {
      thead {
        th {
          text-align: left;
        }
      }
      tbody {
        td {
          text-align: left;
        }
      }
    }
  }



  .bubble {
    background: linear-gradient(0deg, rgba(176, 3, 211, 0.1), rgba(176, 3, 211, 0.1)), #FFFFFF;
    border: rem-calc(1) solid $purple;
    border-radius: rem-calc(10);
    padding: rem-calc(10) rem-calc(20) rem-calc(10) rem-calc(18);

    h3, .amount {
      margin: 0;
    }
  }

  .calendar-graphic {
    background-color: $white;
    border-radius: rem-calc(10);
    padding: rem-calc(10);
    font-size: rem-calc(13);

    @include breakpoint(small down) {
      margin-bottom: rem-calc(16);
    }

    .calendar-container {
      display: inline-block;
      width: rem-calc(100);
      height: rem-calc(116);
      background-image: url(../images/icon-calendar-container.svg);
      background-size: 100% 100%;
      padding: rem-calc(24) 0 0;
    }
    *[data-month] {
      display: block;
      font-size: rem-calc(16);
      line-height: rem-calc(16);
      font-weight: bold;
    }
    *[data-date] {
      display: block;
      font-size: rem-calc(48);
      line-height: rem-calc(48);
      padding-top: rem-calc(13);
    }
  }

  small {
    font-size: rem-calc(12);
    line-height: rem-calc(18);
    color: $medium-gray-5;
  }

  .legal {
    font-size: rem-calc(13);

  }

  .last-payment {
    font-weight: bold;
  }

  // .button {
  //   width: 100%;
  //   font-weight: normal;
  //   font-size: rem-calc(16);
  //
  //   &.return-old-my-account {
  //     display: inline-block;
  //     width: auto;
  //     font-size: 11px;
  //     opacity: 0.6;
  //     transition: all 0.2s ease;
  //     margin-bottom: 0;
  //
  //     &:hover {
  //       opacity: 1;
  //     }
  //   }
  // }

  .submission-message {
    button {
      width: auto;
      padding-left: 15%;
      padding-right: 15%;
      &.small {
        font-size: rem-calc(14);
        font-weight: bold;

        &:hover {
          cursor: pointer;
          color: $link-blue;
        }
      }
    }
  }

  .editButton, .helpButton, .closeButton {
    float: right;
  }



  .editButton {
    &:before {
      content: '\f303';
      font-family: Font Awesome\ 5 Free;
      font-weight: bold;
      font-size: rem-calc(14);
      margin-right: rem-calc(5);
    }
  }

  .helpButton {
    font-size: rem-calc(12);
    line-height: rem-calc(14);
    font-weight: bold;
    width: rem-calc(16);
    text-align: center;
    color: $purple;
    border: rem-calc(1) solid $purple;
    border-radius: 50%;
  }

  .linkButton, .editButton {
    font-size: rem-calc(16);
    line-height: rem-calc(24);
    color: $purple;
    font-weight: bold;
    cursor: pointer;
    padding: 0 rem-calc(10);
  }

  .closeButton {
    font-size: rem-calc(16);
    line-height: rem-calc(24);
    color: $dark-gray;
    font-weight: bold;
    cursor: pointer;
    padding: 0 rem-calc(10);
  }

  .callout {

    padding: 30px 16px;

    &.slim {
      padding: 20px 16px;
    }

    &.clear {
      padding: 0;
    }

    &.highlight {
      padding: rem-calc(10) rem-calc(15);
    //   padding: rem-calc(3) rem-calc(12);
    //   background-color: rgba($ns-yellow, 0.5);
    }

    &.email {
      margin-top: rem-calc(20);
      padding-bottom: 0;
      padding-left: rem-calc(50);
      padding-right: rem-calc(50);
      @include breakpoint(medium down) {
        padding-left: 5%;
        padding-right: 5%;
      }
    }

  } // end .callout

  .services-accordion {
    .accordion {
      & > li {
        border: rem-calc(1) solid $text-gray;
        border-radius: rem-calc(5);
        background-color: $white;
        margin-bottom: rem-calc(16);
        & > a {
          color: $text-gray;
          font-size: rem-calc(16);
          padding: .85em 1em;
          border-radius: rem-calc(5);
          background-color: $white;
        }
      }

      .accordion-item.is-active {
        .accordion-title {
          background: $lumos-purple;
          color: $white;
        }
      }
    }
  }

  .add-account {
    .helpButton {
      margin-bottom: rem-calc(10);
      float: none;
    }
    label {
      padding-top: 0;
    }
    .accordion {
      background-color: transparent;
      margin-top: 18px;
      margin-bottom: 22px;

      .accordion-item, &>li {
        border: 0;
      }
      .accordion-title {
        color: $purple;
        border-color: $purple;
        border-radius: .375rem;
        position: relative;
        top: 2px;
        display: block;
        width: 100%;
        max-width: 339px;
        font-size: 16px;
        padding: .85em 1em;
        margin-bottom: 0;
        margin-left: auto;
        margin-right: auto;
        @include breakpoint(small only) {
          max-width: none;
          width: auto;
        }
        &:before {
          content: '';
        }
        &:after, .icon {
          transition: 250ms ease;
        }
        &:after {
          content: '\00D7';
          float: right;
          display: inline-block;
          font-size: 30px;
          color: #888888;
          line-height: 18px;
          opacity: 0;
        }
        .icon {
          opacity: 1;
          float: left;
        }
        &[aria-expanded="true"] {
          border-color: $purple;
          border-bottom: none;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          background: linear-gradient(0deg, rgba(176, 3, 211, 0.1), rgba(176, 3, 211, 0.1)), #FFFFFF;
          z-index: 1;
          @include breakpoint(small only) {
            margin-left: -1rem;
            margin-right: -1rem;
            max-width: none;
            width: auto;
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
          }
          &:after {
            opacity: 1;
          }
          .icon {
            opacity: 0;
          }
        }
      }
      .accordion-content {
        background: linear-gradient(0deg, rgba(176, 3, 211, 0.1), rgba(176, 3, 211, 0.1)), #FFFFFF;
        border: 1px solid $purple;
        border-radius: 10px;
        position: relative;
        @include breakpoint(small only) {
          margin-left: -1rem;
          margin-right: -1rem;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }
      }
      &.acct-edit-panel .accordion-content {
        @include breakpoint(small only) {
          margin-left: 0;
          margin-right: 0;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }
      }
    }
  }

  .callout-img {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    max-width: 132px;
  }

  .callout-row {
    margin-top: 40px;
    .callout {
      height: 100%;
    }
  }
  small {
    line-height: 1.5;
    display: block;
    color: #666666;
    margin-bottom: 1em;
    font-weight: normal;
  }

  .promosquare {
    background-color: $lumos-purple;
    border: rem-calc(4) solid $vivid-violet;
    border-radius: rem-calc(20);
    color: $white;
    padding: rem-calc(30);

    p {
      font-size: rem-calc(13);
    }

    // .button {
    //   width: auto;
    //   font-weight: bold;
    //   padding-left: 15%;
    //   padding-right: 15%;
    //   margin: 0;
    // }

    .promo-description {
      span {
        display: initial;
        font-size: rem-calc(14);
      }
      strong {
        font-size: rem-calc(60);
        font-weight: normal;
      }
      small {
        display: inline-block;
        font-size: rem-calc(20);
        color: $white;
      }
    }
  }

  .show-for-edit {
    display: none;
  }

  .hide-for-edit {
    display: flex;
  }

  .edit-mode {
    .display-only[data-display-for] {
      display: none;
    }
    input, .select-wrap {
      display: inline-block;
      margin-bottom: 10px;
      // background-color: $white;
    }

    .select-wrap {
      display: block;
    }

    .show-for-edit {
      display: flex;
    }

    .hide-for-edit {
      display: none;
    }
  }

  .alert {
    font-size: rem-calc(13);
    line-height: rem-calc(18);
    color: $alert-red-2;
  }

  form {

    fieldset {
      border: rem-calc(1) solid $purple;
      background: linear-gradient(0deg, rgba(176, 3, 211, 0.1), rgba(176, 3, 211, 0.1)), #FFFFFF;
      padding: rem-calc(30);
      border-radius: rem-calc(10);
    }

    .form-error {
      position: relative;
      border: rem-calc(1) solid rgba(0,0,0,.25);
      background-color: #f7e4e1;
      padding: rem-calc(5) rem-calc(15);
      border-radius: rem-calc(5);
      font-size: rem-calc(14);
      z-index: 1;
    }

    .tt-input {
      border: 1px solid #cacaca;
    }

    pre {
      background-color: $white;
    }

    .grid-x {
      justify-content: center;
    }


    // .button {
    //   width: auto;
    //   font-weight: bold;
    //   padding-left: 15%;
    //   padding-right: 15%;
    //   margin: 0;
    // }

    label {
      font-weight: bold;
      padding: rem-calc(9) 0;
    }

    .display-only {
      padding: rem-calc(9) 0;
    }

    label, input, .display-only {
      font-size: rem-calc(16);
      line-height: rem-calc(22);
    }

    input, .select-wrap {
      display: none;
      margin: 0;
    }

    table {
      th {
        text-align: left;
      }
    }
  }

  .current-services, .revised-services {
    .accordion-title {
      margin-bottom: rem-calc(16);
      &, &:hover, &:focus {
        border-color: transparent;
        font-weight: bold;
      }
      &[aria-expanded=false] {
        background: $purple;
        color: $white;
      }
    }

    .accordion .accordion-item.is-active .accordion-title {
      background-color: transparent;;
      color: $text-gray;
      border: rem-calc(1) solid rgba($black, 0.25);
      &:hover {
        background: $promo-fade;
      }
    }

    button.button {
      margin-top: rem-calc(16);
    }

    .accordion>li.accordion-item {
      border-color: transparent;
    }

    .accordion-content {
      padding: 0;
      border-color: transparent;
    }

    p {
      font-size: rem-calc(14);
      margin: 0;
      font-style: italic;
    }

    table {
      font-size: rem-calc(14);
      tr {
        border-bottom: 0;
        background-color: transparent;
        border: 0;
        &:nth-child(2n) {
          background-color: transparent;
          border: 0;
        }
      }
      th {
        font-weight: normal;
        text-align: left;
      }
      td {
        font-weight: bold;
        text-align: right;
      }

      th, td {
        padding-left: rem-calc(5);
        padding-right: rem-calc(5);
      }

      tfoot {
        th, td {
          font-weight: bold;
        }
      }
    }
  }

} // end .account-screen

.confirm-addition {
  table {
    thead, tfoot {
      th {
        text-align: left;
        &:nth-child(2) {
          text-align: right;
        }
      }
      td {
        text-align: right;
      }
    }
    tbody {
      th {
        text-align: left;
      }
      td {
        text-align: right;
      }

      tr {
        &, &:nth-child(2n) {
          background: transparent;
        }
      }
    }

    tfoot {
      background: linear-gradient(0deg, rgba(176, 3, 211, 0.1), rgba(176, 3, 211, 0.1)), #FFFFFF;
      font-size: rem-calc(20);
    }
  }
}

.confirm-promotion {
  .promotion-details {
    span {
      display: block;
      font-size: rem-calc(16);
    }

    strong {
      font-size: rem-calc(60);
    }

    small {
      font-size: rem-calc(20);
    }
  }

}

.promise-to-pay-message {
  font-size: rem-calc(14);
  background-color: $ns-yellow;
  padding: rem-calc(5) rem-calc(10);
  margin-bottom: rem-calc(16);
}

.invoice_message {
  padding: rem-calc(20) 5%;
  margin-bottom: rem-calc(15);
  background-color: $alert-red;
  color: $white;
  border-radius: .375rem;
  font-weight: bold;

  p {
    margin: 0;
  }

  a {
    color: $white;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}

.password-change {
  padding: 60px 0;

  h1 {
    font-size: 2.75rem;
    line-height: 3.3125rem;
    padding-bottom: 20px;
  }
}

@keyframes endLoading {
  0% {
    transform: translate(-50%, -50%) rotate(0deg) scale(1);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg) scale(0);
  }
}
