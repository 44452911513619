.special-header-block {
  margin: 0;
  padding: rem-calc(40) rem-calc(20);
  height: auto;

  @include breakpoint(large) {
    height: rem-calc(425);
    padding: rem-calc(40) rem-calc(0);
  }

  & > .grid-container {
    & > .grid-x {
      @include breakpoint(large) {
        padding: 0 rem-calc(40);
      }
    }
  }

  .cell {
    height: auto;
    padding-bottom: rem-calc(20);
  }

  .logocontain {
    display: inline-block;
  }

  .segralogo, .northstatelogo {
    max-width: 385px;
  }

  p {
    &.welcometo {
      font-family: $body-font-faimly-header;
      font-size: rem-calc(28);
      color: #00baee;
      margin-bottom: rem-calc(12);
    }

    &.centerof {
      font-size: rem-calc(22);
      margin: rem-calc(12) 0;
    }
  }
}
