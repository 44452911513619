.promo-spacer {
  display: block;
  width: 100%;
  height: 20px;
}

.promo-limited-banner {
  position: relative;
  padding: 15px 0;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    width: 100vw;
    height: 100%;
    background-color: $ns-yellow-2;
  }

  span {
    position: relative;
    font-size: 24px;
    line-height: 26px;
    letter-spacing: 1px;
    // font-weight: bold;
    font-family: Sailec W05 Medium,serif;
    text-transform: uppercase;

    @include breakpoint(medium down) {
      font-size: rem-calc(20);
      line-height: rem-calc(22);
    }
  }
}

.promo-header {
  position: relative;
  padding: 0 0;
  height: 550px;

  @include breakpoint(medium down) {
    height: auto;
  }

  &:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    display: block;
    width: 100vw;
    height: 100%;

    // background-color: $dark-blue-4;
  }

  .main-image {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
    height: 100%;
    background-position: 50% 0%;
    background-size: 100%;
    background-size: cover;
    background-repeat: no-repeat;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to right, rgba($white, 1) 0%, rgba($white, 0.8) 40%, rgba($white, 0) 60%, rgba($white, 0) 100%);
    }

    @include breakpoint(medium down) {
      background-position: calc(100% + 80px) 50%;
      &:after {
        background: linear-gradient(to right, rgba($white, 1) 0%, rgba($white, 0.8) 60%, rgba($white, 0) 100%);
      }
    }

    @include breakpoint(small down) {
      &:after {
        background: linear-gradient(to right, rgba($white, 1) 0%, rgba($white, 0.8) 90%, rgba($white, 0.95) 100%)
      }
    }
  }

  .decor {
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    width: 100vw;
    height: 1px;
    border-bottom: 1px solid $white;

    &:before {
      content: '';
      position: absolute;
      left: 18%;
      top: 50%;
      transform: translateY(-50%);
      display: block;
      width: 18px;
      height: 18px;
      background-color: $dark-blue-4;
      background-image: url(../images/decor-dot.svg);
      background-position: center;
      background-size: 100% 100%;
    }
  }

  .copy-cell {
    padding-top: 40px;
    padding-bottom: 40px;
    padding-right: 2%;
  }

  .image-cell {
    height: 100%;
    @include breakpoint(medium down) {
      height: auto;
    }
    .image-container {
      float: left;
    }


    @include breakpoint(medium down) {
      .image-container {
        float: none;
        height: 275px;
      }
    }
  }

  .image-container {
    position: relative;
    width: 140%;
    height: 100%;
    background-position: center;
    background-size: 100%;
    background-size: cover;
    background-repeat: no-repeat;

    @include breakpoint(medium down) {
      width: 100vw;
      left: 50%;
      transform: translateX(-50%);
    }
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 30%;
      height: 100%;
      background-image: linear-gradient(90deg, rgba($dark-blue-4, 1) 0%, rgba($dark-blue-4, 0) 100%);
    }
    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      display: block;
      width: 20%;
      height: 100%;
      background-image: linear-gradient(270deg, rgba($dark-blue-4, 1) 0%, rgba($dark-blue-4, 0) 100%);
    }
  }

  .header-image {
    position: relative;
    padding: 0;
    // margin-top: 20px;

    position: relative;
    height: 100%;

    background-position: 100% 50%;
    background-size: 50% auto;
    background-repeat: no-repeat;

    // border: rem-calc(1) solid $form-gray;

    @include breakpoint(medium down) {
      padding: 0 0 rem-calc(50);
      background-position: right;
    }

    @include breakpoint(small down) {
      padding: 0 0 rem-calc(20);
    }
  }

  .header-container {
    position: relative;
    // padding: 0 rem-calc(60);

    @include breakpoint(medium down) {
      // padding: 0 rem-calc(30);
    }
  }

  .stayconnected-container {
    padding: rem-calc(50) 0 rem-calc(50);
  }

  .header-container, .copy-container {
    @include breakpoint(large) {
      padding-left: rem-calc(25);
    }
  }

  .copy-container {
    padding-right: 3%;
    max-width: 510px;
  }

  // .main-image {
  //   margin-bottom: rem-calc(30);
  //   width: rem-calc(500);
  // }

  h1 {
    font-size: rem-calc(36);
    line-height: rem-calc(46);
    color: $dark-blue-4;
    margin: 0 0 30px;

    span {
      display: block;
      color: $ns-green;
    }

    @include breakpoint(medium down) {
      font-size: rem-calc(28);
      line-height: rem-calc(34);
    }
  }

  h2 {
    font-size: rem-calc(24);
    line-height: rem-calc(34);
    color: $dark-blue-4;
    margin-bottom: rem-calc(30);
  }

  hr {
    position: relative;
    border-bottom: rem-calc(2) dotted $dark-blue;
    margin: rem-calc(40) 0;

    @include breakpoint(medium down) {
      margin: rem-calc(20) 5%;
    }
  }

  p {
    color: $dark-blue-4;
    a {
      color: $light-blue-3;
    }
  }

  .promo-logo {
    margin-bottom: 0;
    max-width: rem-calc(250);

    @include breakpoint(medium down) {
      margin-bottom: rem-calc(15);
    }
  }

  a.button {
    width: auto;
    margin: 20px 0 0;
    font-size: 18px;
    min-width: 250px;
    background-color: $ns-light-blue;
  }

  .header-caption {
    position: relative;
    margin-top: rem-calc(-30);

    @include breakpoint(small down) {
      margin-top: 0;
    }
  }

  .header-caption-container {
    position: relative;
    padding: rem-calc(45) 5%;
    background-color: $light-gray;

    @include breakpoint(medium down) {
      padding: rem-calc(30) 5%;
    }

    p {
      margin: 0;
    }
  }

  .shadow-corner-left, .shadow-corner-right {
    position: relative;
    // &:before {
    //   content: "";
    //   display: block;
    //   position: absolute;
    //   top: rem-calc(30);
    //   opacity: .07;
    //   width: 100%;
    //   height: rem-calc(60);
    // }
  }

  .shadow-corner-left {
    &:before {
      background: linear-gradient(45deg,transparent,transparent 50%,#000);
      right: 0;
    }
  }
  .shadow-corner-right {
    &:before {
      background: linear-gradient(-45deg,transparent,transparent 50%,#000);
      left: 0;
    }
  }

  .promo-bubble {
    position: relative;

    margin: rem-calc(-60) 0 rem-calc(10) auto;

    display: flex;
    display: flex;
    align-items: center;
    justify-content: center;

    width: rem-calc(125);
    height: rem-calc(125);

    background-color: $ns-yellow-2;

    border-radius: 50%;

    font-size: rem-calc(18);
    line-height: rem-calc(20);
    font-weight: bold;

    @include breakpoint(large) {
      position: absolute;
      top: rem-calc(-36);
      right: 6.75%;
      margin: 0;
    }

    div {
      position: relative;
      color: $black;
    }

    &:before {
      content: '';
      position: absolute;
      bottom: rem-calc(-10);
      left: 0;
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 80px 36.5px 0 36.5px;
      border-color: $ns-yellow-2 transparent transparent transparent;
      transform: rotate(33deg);
    }
  }

  .promo-description {
    margin-left: 10%;
    padding-left: rem-calc(30);
    margin-right: calc(10% + 20px);
    border-left: rem-calc(1) solid $form-gray;

    font-size: rem-calc(16);
    line-height: rem-calc(24);

    &.showbubble {
      @include breakpoint(large) {
        margin-right: calc(10% + 70px);
      }
    }
  }
}
