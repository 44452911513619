.offers-landing-page {
  background-color: $white;
  background-image: url("../images/ns_corner_shape.png");
  background-position: left top;
  background-size: 20vw;
  background-repeat: no-repeat;
  padding: rem-calc(60) 0;

  & > .grid-container {
    padding-left: 5%;
    padding-right: 5%;
  }

  .header-image {
    margin-left: -5%;
    margin-right: -5%;
  }

  h1 {
    font-size: rem-calc(28);
    margin-bottom: rem-calc(20);
    color: $dark-blue;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    @include breakpoint(medium) {
      font-size: rem-calc(42);
    }
    @include breakpoint(large) {
      font-size: rem-calc(60);
      margin-bottom: rem-calc(50);
    }
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $dark-blue;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  }

  .entry-content {
    & > h1,
    & > h2,
    & > h3,
    & > h4,
    & > h5,
    & > h6,
    & > ol,
    & > p,
    & > ul,
    .wp-block-button,
    .wp-block-columns {
      max-width: 1200px;
      margin-left: auto;
      margin-right: auto;
      padding-left: 0;
      padding-right: 0;
    }
  }

  .wp-block-group {
    .wp-block-group__inner-container {
      width: 100%;
      margin: 0 auto;
      @include breakpoint(medium) {
        width: 80%;
      }
    }
  }

  .input-group {
    display: flex;
    width: 100%;
    margin-bottom: 1rem;
    align-items: stretch;
    border-collapse: separate;
  }

  .input-group-btn {
    position: relative;
    white-space: nowrap;
  }

  .tt-input {
    border: 1px solid #cacaca;
  }

  #street1 {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
  }

  #geolocate-button {
    color: #333;
    background-color: #e7e7e7;
    border-color: #ccc;
    padding: 8px 12px;
    font-size: 15px;
    line-height: 1.4;
    border: 1px solid #cacaca;
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }


  // Aqui Styles - 1
  #street1 {
    width: inherit;
  }

  .apt-col {
    margin-left: 0.9375rem;
    margin-top: 0;
  }

  .help-block {
    margin-top: 40px;
  }

  #checkaddress.green-btn {
    margin-left: 10px;
    margin-top: -10px;
  }

  .apt-col .tt-hint,
  .apt-col .tt-hint {
    width: 100% !important;
  }

  .aqui-container > .container {
    width: 100%;
  }

  .top-message,
  .top-message a {
    color: #a5abb3 !important;
    font-weight: bold;
  }

  .top-message p {
    font-size: 20px;
  }

  .change-address {
    padding: 8px 12px;
    color: #a5abb3;
    background-color: transparent;
    border-color: #cccccc;
    display: inline-block;
  }

  #check-address fieldset {
    border: 0;
  }

  #fiber-available-congrat p,
  #informSuccessMessage {
    color: #a5abb3 !important;
    font-weight: bold;
    font-size: 20px;
  }

  #fiber-available-congrat div h3 {
    font-weight: bold !important;
    font-size: 20px !important;
    text-transform: uppercase !important;
    color: #1b7093 !important;
  }

  #commingSoon {
    display: block;
    width: 100%;
    height: 41px;
    border: 0;
    background-color: #fff !important;
    outline: none;
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;
    cursor: pointer;
    color: #0077a7 !important;
    font-weight: 700 !important;
    text-transform: uppercase;
    margin-bottom: 4px;
  }
  @media screen and (max-width:900px) {
    .thank-you-header img {
      /* width: 70%; */
    }

    .thank-you-header .ribbon img {
      width: 175px;
    }
  }
  @media screen and (max-width:768px) {
    .thank-you-header .ribbon img {
      width: 150px;
    }
  }
  @media screen and (max-width:550px) {
    .thank-you-header .ribbon img {
      width: 125px;
    }
  }
  @media screen and (max-width:500px) {
    .thank-you-header img {
      /* width: 65%; */
    }
  }

  .form-wrapper .submit-content h3 {
    color: #55c8ec;
    margin-bottom: 0;
  }

  .form-wrapper .disclaimer label {
    color: #8a8a8a;
    text-transform: uppercase;
    font-size: 10px;
  }

  .form-wrapper .one-column-upgrade {
    margin-bottom: 2em;
  }

  .form-wrapper .one-column-upgrade h3 {
    color: #0d2c4c;
    position: relative;
    margin-bottom: 0;
  }

  .form-wrapper .one-column-upgrade h3 span {
    color: #55c8ec;
  }

  .form-wrapper .one-column-upgrade input {
    vertical-align: middle;
  }

  .form-wrapper .one-column-upgrade label {
    color: #0d2c4c;
    font-size: 18px;
    line-height: 36px;
    vertical-align: baseline;
  }
  @media print, screen and (min-width: 40em) {
    .form-wrapper .one-column-upgrade label {
      font-size: 24px;
      line-height: 36px;
    }
  }

  .form-wrapper .one-column-upgrade label p {
    font-weight: normal;
    font-size: 21px;
  }

  .form-wrapper .one-column-upgrade label .lightblue {
    color: #55c8ec;
  }

  .form-wrapper .one-column-upgrade label .lightblue .large {
    font-size: 36px;
    font-weight: bold;
    vertical-align: middle;
  }

  .form-wrapper .one-column-upgrade label .lightblue sup {
    font-size: 16px;
  }

  .bigger {
    font-size: 24px;
  }

  .main-content {
    padding: 3em 0 0;
  }

  .main-content .main-callout p {
    line-height: 1.2;
  }
  // Aqui Styles - 2
  .info-top {
    font-size: 12px;
    margin-top: 0;
    color: #2f9b4c;
    font-weight: bold;
  }

  .info-top a {
    color: #2f9b4c;
    white-space: nowrap;
  }

  .info-top a:hover {
    text-decoration: underline;
  }

  .main {
    background-size: 20vw;
  }

  .thank-you-header {
    padding: 20px 0 10px;
  }

  .thank-you-header h1 {
    font-size: 28px;
    margin-bottom: 20px;
    transition: all 0.2s ease;
  }

  .thank-you-header img {
    max-width: 1200px;
  }

  .main-content {
    padding: 0 30px;
  }

  .main-content .main-callout p {
    line-height: 1.5;
  }

  .container-fluid {
    padding: 0;
  }

  .footer .disclaimers {
    padding: 40px 5%;
  }

  .bigger {
    font-size: 16px;
    transition: all 0.2s ease;
  }

  .form-wrapper .one-column-upgrade h3 {
    margin-bottom: 15px;
  }

  input[name="FiberInternetOptions"] + label {
    border: 2px solid #cacaca;
    border-radius: 8px;
    transition: all 0.2s ease;
    width: 100%;
    max-width: 600px;
  }

  input[name="FiberInternetOptions"]:checked + label {
    border: 2px solid #56b7e6;
    background-color: #E6F4FB;
  }

  .form-wrapper .one-column-upgrade label {
    padding: 10px 20px;
    margin: 5px 0;
    cursor: pointer;
    transition: all 0.2s ease;
  }

  .form-wrapper .one-column-upgrade label:hover {
    border: 2px solid #56b7e6;
  }

  .form-wrapper .one-column-upgrade input[type="radio"] {
    display: none;
  }

  .promo-terms {
    display: block;
  }

  .aqui-container .container-fluid .grid-x {
    justify-content: center;
  }

  .aqui-container .container-fluid .input-group-btn {
    width: auto;
  }

  .aqui-container .container-fluid .grid-x .address-col {
    width: 65%;
    padding-bottom: 30px;
    margin-left: 0;
  }

  .aqui-container .container-fluid .grid-x .apt-col {
    width: 30%;
    margin-left: 0;
  }

  .aqui-container .container-fluid .grid-x .checkaddress-col {
    width: 100%;
  }

  .aqui-container .change-address {
    display: inline-block;
    vertical-align: middle;
    margin: 1rem 0;
    font-size: 12px;
    font-family: inherit;
    padding: 0.5em 2em;
    -webkit-appearance: none;
    border: 1px solid transparent;
    border-radius: 0;
    transition: background-color 0.25s ease-out, color 0.25s ease-out;
    line-height: 1;
    text-align: center;
    cursor: pointer;
    background-color: #cacaca;
    color: #fff;
    border-radius: 25px;
  }

  #stayconnectedform .address .small-6 {
    width: 100%;
  }

  #stayconnectedform .disclaimer .cell {
    padding: 0;
  }

  #stayconnectedform .disclaimer .medium-1 {
    text-align: right;
  }

  #stayconnectedform .disclaimer .medium-9 {
    text-align: left;
  }

  #stayconnectedform .disclaimer label {
    margin: 0;
  }

  #stayconnectedform .disclaimer input[type="checkbox"] {
    margin-right: 10px;
  }

  .products-form-inner .col-md-12 {
    margin-left: 0;
    width: 100%;
  }

  .address-checker-inner {
    position: relative;
  }

  .address-checker-inner .greyout {
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    z-index: 2;
    opacity: 0.5;
  }
  @media screen and (min-width: 640px) {
    .info-top {
      margin-top: 18px;
    }

    .thank-you-header {
      padding: 40px 0 10px;
    }

    .thank-you-header h1 {
      font-size: 42px;
      margin-bottom: 30px;
    }

    .bigger {
      font-size: 25px;
    }

    .promo-terms {
      display: inline-block;
    }
  }
  @media screen and (min-width: 1024px) {
    .thank-you-header {
      padding: 60px 0 10px;
    }

    .thank-you-header h1 {
      font-size: 60px;
      margin-bottom: 50px;
    }

    .main-content {
      padding: 30px 0 50px;
    }

    #stayconnectedform .address .small-6 {
      width: 50%;
    }

    .aqui-container .container-fluid .grid-x .address-col {
      width: 41.6666666667%;
    }

    .aqui-container .container-fluid .grid-x .apt-col {
      width: 16.6666666667%;
      margin-left: 0.9375rem;
    }

    .aqui-container .container-fluid .grid-x .checkaddress-col {
      width: 25%;
    }
  }

  h3 span {
    color: #55c8ec;
  }

  .gform_wrapper {

    .ginput_container.ginput_container_consent {
      text-align: center;
    }
    .gfield_radio {
      li {
        text-align: center;
      }

      input[type="radio"] {
        opacity: 0;
        position: absolute;
      }

      input[type="radio"]:checked + label {
        border: 2px solid #56b7e6;
        background-color: #E6F4FB;
      }

      input[type="radio"] + label  {
        border: 2px solid #cacaca;
        border-radius: 8px;
        transition: all 0.2s ease;
        width: 100%;
        max-width: 600px;
      }


      label  {
        padding: 10px 20px;
        margin: 5px 0;
        font-size: 18px;
    line-height: 36px;
        @include breakpoint(large) {
          font-size: 24px;
          line-height: 36px;
        }
        .lightblue {
          color: #55c8ec;
          margin: 0;
          .large {
            font-size: 36px;
            font-weight: bold;
            display: inline-block;
            vertical-align: middle;
            margin: 0;
          }
          sup {
            display: inline-block;
            font-size: 16px;
          }
        }
        .promo-terms {
          display: block;
          margin: 0;
          span {
            margin:0;
          }
          .small {
            display:inline-block;
          }
          @include breakpoint(large) {
            display: inline-block;
          }
        }
      }
    }
  }

  .gform_wrapper .gform_footer {
    text-align: center;
  }

  .ginput_container.ginput_recaptcha > div {
    margin: 0 auto;
  }

  .gform_wrapper {
    div.validation_error {
      border-color: $alert-red;
      color: $alert-red;
    }
    li.gfield.gfield_error,
    li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning {
      border: 0;
      background-color: transparent;
    }
    .gfield_error .gfield_label {
      color: $alert-red;
    }
    .ginput_container_consent {
      .gfield_required {
        display: none;
      }
    }
    .gfield_required {
      color: $alert-red;
    }
    .validation_message {
      color: $alert-red;
    }
    li.gfield_error input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]),
    li.gfield_error textarea {
      border-color: $alert-red;
    }
    .gfield_checkbox li, .gfield_radio li {
      margin: 0;
    }
    li.hidden_label input {
      display: inline-block;
      vertical-align: top;
      margin-top: 6px;
    }

    [type=checkbox]+label[for] {
      text-align: left;
      display: inline-block;
      width: calc(100% - 50px);
      vertical-align: top;
    }
  }

}

.hidden {
  display: none;
}
