.wp-block-cover {

  &.full-width {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
    padding: 40px 0;

    @include breakpoint(large) {
      min-height: 500px;
    }

    .wp-block-columns {
      gap: 0;
      margin: 0 auto;
      max-width: 825px;
      padding-left: 30px;
      padding-right: 30px;
      @include breakpoint(large) {
        padding-left: 0px;
        padding-right: 0px;
      }
    }

    h2 {
      font-size: 32px;
      line-height: 120%;
      @include breakpoint(medium) {
        font-size: 48px;
      }
    }

    .wp-block-buttons {
      justify-content: center;
      @include breakpoint(large) {
        justify-content: flex-start;
      }
      .wp-block-button, .wp-block-button__link {
        width: 100%;
        @include breakpoint(medium) {
          width: auto;
        }
      }
    }
  }

  &.homepage-q3-2021 {
    position: relative;
    justify-content: flex-start;
    background-color: transparent;
    min-height: 335px;

    background-color: $lumos-text-blue;

    // &:before {
    //   content: '';
    //   position: absolute;
    //   top: 0;
    //   left: 50%;
    //   transform: translateX(-50%);
    //   display: block;
    //   width: 100vw;
    //   height: 100%;
    //   background-color: $lumos-text-blue;
    //   z-index: -1;
    // }

    &:after {
      content: '';
      position: absolute;
      bottom: -1em;
      left: 50%;
      transform: translateX(-50%);
      display: block;
      width: 100vw;
      height: 60px;
      min-height: unset;
      background-image: url(../images/header-gradient.png);
      background-repeat: repeat-x;
      background-size: auto 100%;
      z-index: 1;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      display: block;
      width: 100vw;
      height: 100%;
      background: linear-gradient(to right, rgba($lumos-text-blue, 1) 0%, rgba($lumos-text-blue, 0.65) 100%);
      z-index: 1;

      @include breakpoint(large) {
        background: linear-gradient(to right, rgba($lumos-text-blue, 1) 0%, rgba($lumos-text-blue, 0) 100%);
      }
      @include breakpoint(xlarge) {
        background: linear-gradient(to right, rgba($lumos-text-blue, 1) 0%, rgba($lumos-text-blue, 0) 77%, rgba($lumos-text-blue, 0) 100%);
      }
    }

    @include breakpoint(large) {
      min-height: 460px;
    }

    > img {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);

      display: block;
      width: 100vw;
    }

    .wp-block-cover__inner-container {
      position: relative;
      max-width: $global-width;
      margin: 0 auto;

      @include breakpoint(medium) {
        padding: 60px 1%;
      }
    }

    h2 {
      max-width: 500px;
    }

    p {
      color: $lumos-search-blue;
      max-width: 615px;
      @include breakpoint(medium) {
        font-size: 34px;
        line-height: 42px;
      }
    }
  }
  &.q3-2021-header {
    position: relative;
    justify-content: flex-start;
    background-color: transparent;
    min-height: 335px;
    background-color: $lumos-text-blue;

    &:after {
      content: '';
      position: absolute;
      bottom: -1em;
      left: 50%;
      transform: translateX(-50%);
      display: block;
      width: 100vw;
      height: 60px;
      min-height: unset;
      background-image: url(../images/header-gradient.png);
      background-repeat: repeat-x;
      background-size: auto 100%;
      z-index: 1;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      display: block;
      width: 100vw;
      height: 100%;
      background: linear-gradient(to right, rgba($lumos-text-blue, 1) 0%, rgba($lumos-text-blue, 0.65) 100%);
      z-index: 1;

      @include breakpoint(large) {
        background: linear-gradient(to right, rgba($lumos-text-blue, 1) 0%, rgba($lumos-text-blue, 0) 100%);
      }
      @include breakpoint(xlarge) {
        background: linear-gradient(to right, rgba($lumos-text-blue, 1) 0%, rgba($lumos-text-blue, 0) 77%, rgba($lumos-text-blue, 0) 100%);
      }
    }

    @include breakpoint(large) {
      min-height: 460px;
    }

    > img {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);

      display: block;
      width: 100vw;
    }

    .wp-block-cover__inner-container {

      @include breakpoint(medium) {
        width: 50%;
        padding: 60px 1% 100px;
      }
    }

    h2 {
      font-size: 36px;
      line-height: 40px;
      font-weight: 100;
      margin-bottom: 20px;

      @include breakpoint(small down) {
        text-align: center;
      }

      @include breakpoint(large) {
        font-size: 42px;
        line-height: 52px;
      }

      @include breakpoint(xlarge) {
        font-size: 64px;
        line-height: 72px;
      }
    }

    p {
      position: relative;
      width: 100%;
      padding: 13px 0;
      font-size: 24px;
      line-height: 34px;
      @include breakpoint(small down) {
        text-align: center;
      }
      @include breakpoint(large) {
        width: 225px;
      }
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: -75px;
        display: block;
        width: calc(100% + 150px);
        height: 100%;
        background: linear-gradient(to right, rgba(#59CBE8, 0) 0%, rgba(#59CBE8, 1) 50%, rgba(#59CBE8, 0) 100%);
        z-index: -1;
      }
    }
  }

  &.ebb-header {
    position: relative;
    width: 100vw;
    left: 50%;
    transform: translateX(-50%);
    min-height: 456px;

    .wp-block-cover__inner-container {
      padding: 0 3%;
      @include breakpoint(medium) {
        max-width: 1086px;
      }

      .wf-ebb-heading {
        max-width: 567px;
        line-height: 1 !important;
        margin-bottom: 35px;

        @include breakpoint(small only) {
          font-size: 45px !important;
          line-height: 1.1 !important;
        }
      }

      .wf-ebb-sub-heading {
        line-height: 1 !important;
        max-width: 568px;

        @include breakpoint(small only) {
          font-size: 30px !important;
        }
      }
    }

    &:before {
      // @include breakpoint(small only) {
      //   content: '';
      //   position: absolute;
      //   top: 0;
      //   left: 50%;
      //   bottom: 0;
      //   right: 0;
      //   z-index: 1;
      //   opacity: 0.8;
      //   transform: translateX(-50%);
      //   display: block;
      //   width: 100vw;
      //   background-color: $lumos-purple;
      // }
    }


  }

  &.wf-full-cover {
    img {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      display: block;
      width: 100vw;

      // @include breakpoint(small only) {
      //   object-position: 100% 49% !important;
      // }

    }
  }
}
