// Base Button Style

.button {
  border-color: $primary-color;
  color: $white;
  padding: 12px 32px 12px;
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  font-style: normal;
  transition: background-color 0.2s ease, border-color 0.2s ease;

  &.no-margin {
    margin: 0 !important;
  }

  &.low-padding {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  &.large {
    font-size: 18px;
    padding: 15px 32px 15px;
  }

  &.small {
    font-size: 14px;
    padding: 10px 32px 10px;
  }

  &:hover {
    border-color: $vivid-violet;
    background-color: $vivid-violet;
    color: $lumos-purple;
  }

  &:focus {
    border-color: $lumos-purple;
    background-color: $lumos-purple;
    color: $vivid-violet;
  }

  // &:focus {
  //   border-color: $purple;
  //   border-width: 4px;
  //   padding: 12px 29px 12px;
  //   background-color: $white;
  //   color: $lumos-purple;
  // }

  &:disabled, &:disabled:hover {
    border-color: $light-gray;
    background-color: $light-gray;
    color: $medium-gray;
    opacity: 1;
  }

  &:before {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
  }

  &.search {
    &:before {
      content: '';
      display: inline-block;
      width: 16px;
      height: 16px;
      background-image: url(../images/icon-magnifier/white.svg);
      margin-right: 8px;
      vertical-align: middle;
    }
    &:hover {
      &:before {
        background-image: url(../images/icon-magnifier/lumos-purple.svg);
      }
    }
    &:focus {
      &:before {
        background-image: url(../images/icon-magnifier/vivid-violet.svg);
      }
    }
    &:disabled, &:disabled:hover {
      &:before {
        background-image: url(../images/icon-magnifier/medium-gray.svg);
      }
    }
  }

  &.arrow-right {
    &:after {
      content: '';
      display: inline-block;
      width: 16px;
      height: 16px;
      background-image: url(../images/icon-arrow-right/white.svg);
      margin-left: 8px;
      vertical-align: middle;
    }
    &:hover {
      &:after {
        background-image: url(../images/icon-arrow-right/lumos-purple.svg);
      }
    }
    &:focus {
      &:after {
        background-image: url(../images/icon-arrow-right/vivid-violet.svg);
      }
    }
    &:disabled, &:disabled:hover {
      &:after {
        background-image: url(../images/icon-arrow-right/medium-gray.svg);
      }
    }
  }

  &.arrow-left {
    &:before {
      content: '';
      display: inline-block;
      width: 16px;
      height: 16px;
      background-image: url(../images/icon-arrow-right/white.svg);
      margin-right: 8px;
      vertical-align: middle;
      transform: rotate(-180deg);
    }
    &:hover {
      &:before {
        background-image: url(../images/icon-arrow-right/lumos-purple.svg);
      }
    }
    &:focus {
      &:before {
        background-image: url(../images/icon-arrow-right/vivid-violet.svg);
      }
    }
    &:disabled, &:disabled:hover {
      &:before {
        background-image: url(../images/icon-arrow-right/medium-gray.svg);
      }
    }
  }

  &.location {
    padding: 8px;
    &:before {
      content: '';
      display: inline-block;
      width: 24px;
      height: 24px;
      background-image: url(../images/icon-location-pin/white.svg);
      margin-right: 8px;
      vertical-align: middle;
    }
    &:hover {
      &:before {
        background-image: url(../images/icon-location-pin/lumos-purple.svg);
      }
    }
    &:focus {
      &:before {
        background-image: url(../images/icon-location-pin/vivid-violet.svg);
      }
    }
    &:disabled, &:disabled:hover {
      &:before {
        background-image: url(../images/icon-location-pin/medium-gray.svg);
      }
    }
  }

  &.chat {
    padding: 8px;
    &:before {
      content: '';
      display: inline-block;
      width: 16px;
      height: 16px;
      background-image: url(../images/icon-chat/lumos-purple.svg);
      margin-right: 8px;
      vertical-align: middle;
    }
    &:hover {
      &:before {
        background-image: url(../images/icon-chat/lumos-purple.svg);
      }
    }
    &:focus {
      &:before {
        background-image: url(../images/icon-chat/lumos-purple.svg);
      }
    }
    &:disabled, &:disabled:hover {
      &:before {
        background-image: url(../images/icon-chat/lumos-purple.svg);
      }
    }
  }

  &.help {
    &:before {
      content: '?';
      display: inline-block;
      width: 16px;
      height: 16px;
      margin-right: 8px;
      vertical-align: middle;
    }
  }

  &.sr-text {
    font-size: 0 !important;
    padding: 12px 12px;

    &.location {
      padding: 8px;
      &.small {
        padding: 6px;
      }
      &.large {
        padding: 11px;
      }
    }

    &.chat {

    }

    &.help {
      padding: 2px;
      &:before {
        font-size: 16px;
        line-height: 18px;
      }
      &.small {
        padding: 2px;
        &:before {
          font-size: 14px;
          line-height: 18px;
        }
      }
      &.large {
        padding: 2px;
        &:before {
          font-size: 18px;
          line-height: 18px;
        }
      }
    }

    &:before, &:after {
      margin: 0;
    }

    &.small {
      padding: 10px 10px;
    }

    &.large {
      padding: 15px 15px;
    }
  }

  &.hollow {
    border-color: $vivid-violet;
    background-color: transparent;
    color: $lumos-purple;

    &:hover {
      border-color: $purple;
      background-color: transparent;
      color: $lumos-purple;
    }

    &:focus {
      border-color: $lumos-purple;
      background-color: $lumos-purple;
      color: $vivid-violet;
    }

    &:disabled, &:disabled:hover {
      border-color: $medium-gray-3;
      background-color: transparent;
      color: $medium-gray;
    }

    &.search {
      &:before {
        background-image: url(../images/icon-magnifier/lumos-purple.svg);
      }
      &:hover {
        &:before {
          background-image: url(../images/icon-magnifier/lumos-purple.svg);
        }
      }
      &:focus {
        &:before {
          background-image: url(../images/icon-magnifier/vivid-violet.svg);
        }
      }
      &:disabled, &:disabled:hover {
        &:before {
          background-image: url(../images/icon-magnifier/medium-gray-2.svg);
        }
      }
    }

    &.arrow-right {
      &:after {
        background-image: url(../images/icon-arrow-right/lumos-purple.svg);
      }
      &:hover {
        &:after {
          background-image: url(../images/icon-arrow-right/lumos-purple.svg);
        }
      }
      &:focus {
        &:after {
          background-image: url(../images/icon-arrow-right/vivid-violet.svg);
        }
      }
      &:disabled, &:disabled:hover {
        &:after {
          background-image: url(../images/icon-arrow-right/medium-gray-2.svg);
        }
      }
    }

    &.arrow-left {
      &:before {
        background-image: url(../images/icon-arrow-right/lumos-purple.svg);
      }
      &:hover {
        &:before {
          background-image: url(../images/icon-arrow-right/lumos-purple.svg);
        }
      }
      &:focus {
        &:before {
          background-image: url(../images/icon-arrow-right/vivid-violet.svg);
        }
      }
      &:disabled, &:disabled:hover {
        &:before {
          background-image: url(../images/icon-arrow-right/medium-gray-2.svg);
        }
      }
    }

    &.location {
      &:before {
        background-image: url(../images/icon-location-pin/lumos-purple.svg);
      }
      &:hover {
        &:before {
          background-image: url(../images/icon-location-pin/lumos-purple.svg);
        }
      }
      &:focus {
        &:before {
          background-image: url(../images/icon-location-pin/vivid-violet.svg);
        }
      }
      &:disabled, &:disabled:hover {
        &:before {
          background-image: url(../images/icon-location-pin/medium-gray-2.svg);
        }
      }
    }
  }

  &.noborder {
    border-color: transparent !important;
  }

  &.secondary {
    border-color: $secondary-color;
    background-color: $secondary-color;
    color: $lumos-purple;

    &:hover {
      border-color: $purple;
      background-color: $purple;
      color: $white;
    }

    &:focus {
      border-color: $lumos-purple;
      background-color: $lumos-purple;
      color: $white;
    }

    &:disabled, &:disabled:hover {
      border-color: transparent;
      background-color: rgba($vivid-violet, 0.3);
      color: $medium-gray-2;
    }

    &.search {
      &:before {
        background-image: url(../images/icon-magnifier/lumos-purple.svg);
      }
      &:hover {
        &:before {
          background-image: url(../images/icon-magnifier/white.svg);
        }
      }
      &:focus {
        &:before {
          background-image: url(../images/icon-magnifier/white.svg);
        }
      }
      &:disabled, &:disabled:hover {
        &:before {
          background-image: url(../images/icon-magnifier/medium-gray-2.svg);
        }
      }
    }

    &.arrow-right {
      &:after {
        background-image: url(../images/icon-arrow-right/lumos-purple.svg);
      }
      &:hover {
        &:after {
          background-image: url(../images/icon-arrow-right/white.svg);
        }
      }
      &:focus {
        &:after {
          background-image: url(../images/icon-arrow-right/white.svg);
        }
      }
      &:disabled, &:disabled:hover {
        &:after {
          background-image: url(../images/icon-arrow-right/medium-gray-2.svg);
        }
      }
    }

    &.arrow-left {
      &:before {
        background-image: url(../images/icon-arrow-right/lumos-purple.svg);
      }
      &:hover {
        &:before {
          background-image: url(../images/icon-arrow-right/white.svg);
        }
      }
      &:focus {
        &:before {
          background-image: url(../images/icon-arrow-right/white.svg);
        }
      }
      &:disabled, &:disabled:hover {
        &:before {
          background-image: url(../images/icon-arrow-right/medium-gray-2.svg);
        }
      }
    }

    &.location {
      &:before {
        background-image: url(../images/icon-location-pin/lumos-purple.svg);
      }
      &:hover {
        &:before {
          background-image: url(../images/icon-location-pin/white.svg);
        }
      }
      &:focus {
        &:before {
          background-image: url(../images/icon-location-pin/white.svg);
        }
      }
      &:disabled, &:disabled:hover {
        &:before {
          background-image: url(../images/icon-location-pin/medium-gray-2.svg);
        }
      }
    }

    &.chat {
      &:before {
        background-image: url(../images/icon-chat/lumos-purple.svg);
      }
      &:hover {
        &:before {
          background-image: url(../images/icon-chat/white.svg);
        }
      }
      &:focus {
        &:before {
          background-image: url(../images/icon-chat/white.svg);
        }
      }
      &:disabled, &:disabled:hover {
        &:before {
          background-image: url(../images/icon-chat/medium-gray-2.svg);
        }
      }
    }

    &.hollow {
      border-color: $purple-pastel-2;
      background-color: transparent;
      color: $purple-pastel-2;

      &:hover {
        border-color: $vivid-violet;
        background-color: transparent;
        color: $purple-pastel-2;
      }

      &:focus {
        border-color: $lumos-purple;
        background-color: $lumos-purple;
        color: $white;
      }

      &:disabled, &:disabled:hover {
        border-color: $medium-gray-2;
        background-color: transparent;
        color: $medium-gray-2;
      }

      &.search {
        &:before {
          background-image: url(../images/icon-magnifier/purple-pastel-2.svg);
        }
        &:hover {
          &:before {
            background-image: url(../images/icon-magnifier/purple-pastel-2.svg);
          }
        }
        &:focus {
          &:before {
            background-image: url(../images/icon-magnifier/white.svg);
          }
        }
        &:disabled, &:disabled:hover {
          &:before {
            background-image: url(../images/icon-magnifier/medium-gray-2.svg);
          }
        }
      }

      &.arrow-right {
        &:after {
          background-image: url(../images/icon-arrow-right/purple-pastel-2.svg);
        }
        &:hover {
          &:after {
            background-image: url(../images/icon-arrow-right/purple-pastel-2.svg);
          }
        }
        &:focus {
          &:after {
            background-image: url(../images/icon-arrow-right/white.svg);
          }
        }
        &:disabled, &:disabled:hover {
          &:after {
            background-image: url(../images/icon-arrow-right/medium-gray-2.svg);
          }
        }
      }

      &.arrow-left {
        &:before {
          background-image: url(../images/icon-arrow-right/purple-pastel-2.svg);
        }
        &:hover {
          &:before {
            background-image: url(../images/icon-arrow-right/purple-pastel-2.svg);
          }
        }
        &:focus {
          &:before {
            background-image: url(../images/icon-arrow-right/white.svg);
          }
        }
        &:disabled, &:disabled:hover {
          &:before {
            background-image: url(../images/icon-arrow-right/medium-gray-2.svg);
          }
        }
      }

      &.location {
        &:before {
          background-image: url(../images/icon-location-pin/purple-pastel-2.svg);
        }
        &:hover {
          &:before {
            background-image: url(../images/icon-location-pin/purple-pastel-2.svg);
          }
        }
        &:focus {
          &:before {
            background-image: url(../images/icon-location-pin/white.svg);
          }
        }
        &:disabled, &:disabled:hover {
          &:before {
            background-image: url(../images/icon-location-pin/medium-gray-2.svg);
          }
        }
      }

    }
  }
}
