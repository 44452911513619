.address-checker-overlay {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($black, 0.6);
  z-index: 0;
}

.apt-col {
  display: none;
  padding-left: 8px;
  max-width: 150px;
}

.check-fields {
  display: none;
  &.active {
    display: block;
  }
}

.address-checker-container {
  padding: rem-calc(50) 0;
  //max-height: 75vh;

  .grid-x {
    justify-content: center;
  }

  h2 {
    color: $black;
    font-size: rem-calc(34);
    line-height: rem-calc(48);
  }

  .input-container {
    margin: 0;
  }

  input {
    margin: 0;
    // border: 0;
  }

  .fiber-list-blocks {
    list-style: none;
    padding: 0;
    margin: 0 0 rem-calc(10);
    a {
      color: $text-gray;
    }
    li {
      padding: rem-calc(15);
      margin-bottom: rem-calc(15);
      background-color: $bubble-blue;
      border: rem-calc(1) solid $bubble-border;
      border-radius: $global-radius;

      h3 {
        font-size: rem-calc(13);
        text-transform: uppercase;
        font-weight: 600;
        min-height: rem-calc(40);
      }

      .price-box {
        display: block;
        margin: 13px 0 0;
        font-weight: 400;
        span {
          font-size: rem-calc(37);
        }
      }
    }
  }

  .bottom-text {
    p {
      span {
        font-size: rem-calc(25);
        font-weight: 400;
      }
    }
  }
}

#address-checker {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;

  transition: opacity 0.2s ease;

  &.open {
    opacity: 1;
    pointer-events:all;
    z-index: 100;
    visibility: visible;
  }
}

.address-checker-wrap {
  -ms-overflow-style: none;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: rem-calc(30);

  &::-webkit-scrollbar {
    width: 0.5em;
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }
}

.address-form {
  position: relative;
  width: 100%;
  height: auto;
  background-color: $white;
  z-index: 2;

  // -ms-overflow-style: none;
  // overflow-y: scroll;
  // overflow-x: hidden;
  // max-height: 50vh;
  // padding-bottom: rem-calc(30);
  //
  // &::-webkit-scrollbar {
  //   width: 0.5em;
  // }
  //
  // &::-webkit-scrollbar-thumb {
  //   background-color: darkgrey;
  //   outline: 1px solid slategrey;
  // }


  .cell {
    position: relative;
  }
}

.promo-space {
  position: relative;
  background-color: $lumos-text-blue;
  color: $white;
  padding: 0;
  // border-bottom: rem-calc(2) solid $nav-lower-blue;
  z-index: 1;

  > .grid-container > .grid-x {
    max-width: 1200px;
    margin: 0 auto;
  }

  h5, .header-color {
    font-size: 30px;
    line-height: 39px;
    color: $white;
    border-bottom: 2px solid #4FB3DD;
    padding-bottom: 5px;
    margin-bottom: 10px;
    font-weight: 400;
  }

  .promo-divider {
    // border-left: rem-calc(3) solid $ns-yellow;
    margin-left: 0;
    padding: 27px 3% 27px 1%;

    p:not(.header-color):not(h5) {
      font-size: 15px;
      line-height: 20px;

      em {
        font-style: normal;
        color: #EEE52F;
      }
    }

    .button {
      font-size: 24px;
      line-height: 24px;
      margin: 0;
      padding: 11px 22px;
      background-color: #00B5E2;
      text-transform: uppercase;
      &:hover {
        background-color: lighten(#00B5E2, 10%);
      }
    }
  }

  .image-container {
    position: relative;
  }

  // .banner-image {
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   display: block;
  //   width: calc(100% + ((100vw - 421%) / 2));
  //   height: 100%;
  //
  //   background-size: cover;
  //   background-position: 100% 25%;
  //   background-repeat: no-repeat;
  //
  //   &:after {
  //     content: '';
  //     position: absolute;
  //     bottom: 13px;
  //     left: -75px;
  //     display: block;
  //     width: 513px;
  //     height: 65px;
  //     background-image: url(../images/decor-landing-page-devices.svg);
  //     background-position: center;
  //     background-size: 100% 100%;
  //     background-repeat: no-repeat;
  //   }
  // }

  .button {
    background-color: $ns-light-blue;
    color: #004976;
  }
}

#address-checker-close {
  position: absolute;
  top: rem-calc(15);
  right: 4%;
  color: $purple;
  font-size: rem-calc(20);
  line-height: rem-calc(40);
  display: block;
  width: rem-calc(40);
  height: rem-calc(40);
  border: rem-calc(1) solid $purple;
  border-radius: 50%;
  text-align: center;
  transition: all 0.2s ease;
  background-color: $white;
  z-index: 3;

  &:hover {
    color: $vivid-violet;
    border-color: $vivid-violet;
  }
}

.address-panel{
	margin: 0 auto;

  .grid-x {
    justify-content: center;
  }

	.button {
		display: inline-block;
    vertical-align: top;
		margin: 0 0px 8px;

    @include breakpoint(large) {
      margin: 0 8px 8px;
    }

    &.check-address {
      width: 100%;
    }
	}

  .small {
    font-size: rem-calc(13);
    line-height: rem-calc(20);
    color: $dark-gray;
  }

  .input-container {
    width: rem-calc(350);
    max-width: 100%;
    margin: 0 rem-calc(10);
  }

  input {
    border: 0;
    box-shadow: none;
    padding-left: 12px;
    background: transparent;
    // border: rem-calc(1) solid $text-gray;
    &:focus {
      border: 0;
      box-shadow: none;
    }
  }

  .input-group>:first-child {
    border-radius: $global-radius;
  }

  #loading-icon {
    display: none;
  }
}

#submit-full-address, #check-full-address, .check-full-address, .products-form {
  .cell {
    margin-bottom: rem-calc(16);
  }
  .input-container {
    width: 100%;
  }
  input {
    margin: 0;
  }
  button {
    margin: 0;
    width: 100%;
  }

  .form-error {
    margin-top:0;
  }

  input {
    // border: 0;
    box-shadow: none;
    padding-left: 12px;
    background: transparent;
    // border: rem-calc(1) solid $text-gray;
    &:focus {
      // border: 0;
      box-shadow: none;
    }
  }
}

.google-street {
  .twitter-typeahead {
    flex: 1 1 auto;
  	width: 85%;
  }
}

.twitter-typeahead {
	width: 100%;
}

.typeahead,
.tt-query,
.tt-hint {
  width:  100%;
  height: 40px;
  padding: 8px 12px;
  margin: 0;
  font-size: rem-calc(16);
  line-height: 30px;
  border: 0; //border: 2px solid #ccc;
  // border-radius: 8px;
  outline: none;
}

.typeahead {
  background-color: $white;
}

.typeahead:focus {
  border: 0; //2px solid #0097cf;
  outline: none;
  outline-width: 0;
  box-shadow: none;
}
.typeahead.empty {
  border: 2px solid red;
}
.tt-query {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.tt-hint {
  color: #999;
}

.tt-dropdown-menu {
  // width: 100%;
  margin-top: -8px;
  padding: 8px 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: 118.5%!important;
  left: -8px !important;
  // border-radius: 8px;
  // box-shadow: 0 5px 10px rgba(0,0,0,.2);
}

.tt-suggestion {
  padding: 3px 20px;
  font-size: 18px;
  line-height: 24px;
}

.tt-suggestion.tt-cursor {
  color: #fff;
  background-color: $purple;

}

.tt-suggestion p {
  margin: 0;
  font-size: rem-calc(14);
}

.tt-input {
  display: inline-block;
  border: 0;
  outline: none;
  outline-width: 0;
  box-shadow: none;
}

#address-found,
#address-not-found-reenter,
#address-not-found-final,
#address-not-found-final-message,
#address-account-exists {
	display: none;
}

@include breakpoint(medium down) {
  .address-checker-container {
    h2 {
      color: $black;
      font-size: rem-calc(26);
      line-height: rem-calc(32);
    }
  }

  #address-checker-close {
    font-size: rem-calc(13);
    line-height: rem-calc(24);
    width: rem-calc(24);
    height: rem-calc(24);
  }

  .address-panel{
    .input-container {
      width: 100%;
      margin: 0;
    }

    .small {
      margin-bottom: rem-calc(16);
    }
  }
}

@include breakpoint(small down) {
  // .address-form {
  //   max-height: 55vh;
  // }
  .promo-space {
    // padding: rem-calc(10) 0 rem-calc(15);
    .promo-divider {
      border-right: 0;
      img {
        max-height: rem-calc(40);
      }
    }
  }

  .address-checker-container {
    .fiber-list-blocks {
      li {
        padding: rem-calc(10) rem-calc(14);

        h3 {
          min-height: unset;
          br {
            display: none;
          }
        }

        p {
          margin: 0;
        }

        .price-box {
          margin: 0;
          span {
            font-size: rem-calc(25);
          }
        }
      }
    }
  }

}
