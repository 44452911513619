footer.footer {
  margin: 0;
}

.footer-discliamer {
  background-color: $slate;
  font-size: 14px;
  line-height: 140%;
  padding: 60px 0;
}

.footer {
  background-color: $slate;
  // position: absolute;
  // bottom: 0;
  width: 100%;
  color: $lumos-purple;
  padding: rem-calc(35) rem-calc(25) rem-calc(53);

  .grid-container {
    position: relative;
  }

  .to-top-section {
    position: absolute;
    top: rem-calc(40);
    right: rem-calc(5);
    border: rem-calc(1) solid $purple;
    display: block;
    width: rem-calc(40);
    height: rem-calc(40);
    border-radius: 50%;
    text-align: center;
    color: $purple;
    cursor: pointer;
    transition: all 0.2s ease;

    &:before {
      content: '\f062';
      font-family: 'Font Awesome 5 Free';
      font-size: rem-calc(21);
      line-height: rem-calc(40);
      font-weight: bold;
    }

    &:hover {
      color: $vivid-violet;
      border-color: $vivid-violet;
    }
  }

  &:after {
    content: '';
    position: absolute;
    display: block;
    left: 0;
    bottom: 0;
    width: 100%;
    height: rem-calc(18);
    background-color: $lumos-purple;
    background-size: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .footer-contact-info, .copyright {
    font-size: rem-calc(13);
    color: $lumos-purple;
    padding: rem-calc(50) 0 0;
    margin: 0;
    font-weight: 700;
  }

  .footer-disclaimer {
    font-size: rem-calc(13);
    color: $bubble-border;
    margin: 0;

    a {
      color: $bubble-border;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &.offers {
    position: relative;
    bottom: auto;
    padding: rem-calc(15) 0;
    margin: 0;
    p {
      margin: 0;
    }
    &:after {
      display: none;
    }
  }
}

.offer-disclaimer {
  padding: rem-calc(40) 0 rem-calc(90);
  font-size: rem-calc(10);
}

.business-disclaimer {
  font-size: 14px;
  padding: rem-calc(40) 0 rem-calc(90);
}

.footer-nav {
  flex-flow: row;
  display: flex;
  nav {
    float: left;
    width: 100%;
    .menu .active>a, .menu .is-active>a {
      background-color: transparent;
      color: $purple;
    }
    ul.menu > li {
      border-top: rem-calc(1) solid $vivid-violet;
      font-size: rem-calc(13);
      margin-right: rem-calc(30);
      width: 100%;
      max-width: rem-calc(160);
      // min-width: 160px;
      & > a {
        padding: rem-calc(12) 0;
        text-transform: uppercase;
        color: $lumos-purple;
        font-weight: 700;
      }
    }
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      flex-flow: row;
    }

    li {
      a {
        color: $lumos-purple;
        padding: rem-calc(9) 0;
        font-weight: 400;
        &:hover {
          color: $purple;
        }
      }
    }
  }
  .disclaimer-text-footer {
    float: left;
    color: $bubble-border;
    font-size: rem-calc(13);
    width: 100%;
    max-width: rem-calc(450);
    padding-right: 2%;
    p {
      line-height: rem-calc(18);
    }
  }
}

.footer-spacer {
  display: block;
  width: 100%;
  // margin-top: rem-calc(60);
}


@include breakpoint(medium down) {
  .footer {
    padding: rem-calc(75) 0 rem-calc(40);

    .footer-contact-info, .copyright {
      text-align: center;
      padding: 0;
      line-height: rem-calc(28);
    }

    .to-top-section {
      top: rem-calc(-55);
      right: 50%;
      transform: translateX(50%);
    }
  }
  .footer-nav {
    // flex-flow: row;
    flex-wrap: wrap;

    nav {
      width: 100%;
      ul {
        flex-wrap: wrap;
      }
      ul.menu > li {
        margin-right: auto;
        margin-left: auto;
        margin-bottom: rem-calc(30);
        text-align: center;
      }
    }
    .disclaimer-text-footer {
      text-align: center;
      margin: 0 auto;
      padding-right: 2%;
      padding-left: 2%;
    }
  }


}
