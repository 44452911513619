.solutions-offerings {
  position: relative;
  background-color: $super-light-gray;
  padding: rem-calc(70);
  text-align: center;

  h2 {
    margin-bottom: rem-calc(30);
    color: $dark-blue;
  }
  p {
    width: 100%;
    max-width: rem-calc(640);
    margin: 0 auto;
    color: $medium-gray-2;
  }
  .so-list {
    width: 100%;
    margin-top: rem-calc(40);
  }
  .so-item {
    display: inline-block;
    vertical-align: top;
    width: 30%;
    border: rem-calc(1) solid $form-gray;
    border-radius: rem-calc(10);
    padding: rem-calc(40) rem-calc(32) rem-calc(30);
    margin: rem-calc(7) rem-calc(7) rem-calc(30);
    background-color: $white;

    img {
      display: inline-block;
      width: 100%;
      max-width: rem-calc(100);
      margin: 0 0 rem-calc(30);
      transition: all 0.2s ease;
    }
    p {
      font-size: rem-calc(13);
      color: $black;
    }
    .button {
      width: 100%;
      margin: rem-calc(20) 0 0;
      &:after {
        content: '\f061';
        font-family: 'Font Awesome 5 Free';
        float: right;

      }
    }

    a {
      display: block;
      color: $middle-blue;
      &:hover {
        img {
          transform: scale(1.05);
        }
      }
    }
  }
}

@include breakpoint(medium down) {
  .solutions-offerings {
    .so-item {
      width: 45%;
    }
  }
}

@include breakpoint(small down) {
  .solutions-offerings {
    .so-item {
      width: 100%;
    }
  }
}
