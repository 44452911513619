.wp-block-group {

  // Homepage Element Wrappers

  &.what-is-lumos-group {
    margin: 0;

    h2 {
      color: $purple;
      font-size: 24px;
      font-weight: 120%;
      margin-bottom: 24px;

      @include breakpoint(medium) {
        font-size: 32px;
      }

      strong {
        font-weight: 700;
        @include breakpoint(medium) {
          font-weight: 600;
        }
      }
    }

    h3 {
      color: $purple;
      font-size: 20px;
      line-height: 120%;
      font-weight: 500;
      margin-bottom: 4px;

      @include breakpoint(medium) {
        font-size: 24px;
      }
    }

    .subhead {
      font-size: 20px;
      line-height: 130%;
      font-weight: 500;
      max-width: 706px;
      margin: 0 auto 16px;

      @include breakpoint(medium) {
        font-size: 24px;
        margin: 0 auto 24px;
      }
    }

    p {
      font-size: 18px;
      line-height: 155%;
    }

    .wp-block-columns {
      .wp-block-column {
        border: 2px solid $purple-pastel-2;
        border-radius: 16px;
        padding: 32px 16px;
      }
    }

    .wp-block-button.large {
      .wp-block-button__link {
        font-size: 18px;
        padding: 15px 32px 15px;
      }
    }
  }

  &.builtforthefuture {
    h2 {
      font-size: 28px;
      line-height: 120%;

      @include breakpoint(medium) {
        font-size: 42px;
      }
    }
  }

  &.narrow .wp-block-group__inner-container {
    max-width: 896px;
    margin: 0 auto;
  }

  &.slate-bg {
    position: relative;
    margin: 0;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      display: block;
      width: 100vw;
      height: 100%;
      background-color: $slate;
      z-index: -1;
    }

    h2 {
      color: $purple;
      font-size: 28px;
      font-weight: 120%;
      margin-bottom: 24px;
      font-weight: bold;

      @include breakpoint(medium) {
        font-size: 42px;
      }

      strong {
        font-weight: 700;
        @include breakpoint(medium) {
          font-weight: 600;
        }
      }
    }

    h3 {
      font-size: 24px;
      line-height: 120%;

      @include breakpoint(medium) {
        font-size: 32px;
      }
    }

    .service-group {
      > .wp-block-group__inner-container {
        display: flex;
        flex-direction: column;

        @include breakpoint(medium) {
          flex-direction: row;
        }

        > .wp-block-group {
          flex: 1;
        }

        .wp-block-image {
          text-align: center;
          @include breakpoint(medium) {
            text-align: left;
            margin: 0 48px 0 0;
            width: 74px;
          }

          img {
            margin-bottom: 16px;
          }
        }
      }
    }
  }

  &.business-outline {
    position: relative;

    h2 {
      font-size: 28px;
      font-weight: 120%;
      margin-bottom: 24px;

      @include breakpoint(medium) {
        font-size: 42px;
      }

      strong {
        font-weight: 700;
        @include breakpoint(medium) {
          font-weight: 600;
        }
      }
    }

    h3 {
      font-size: 24px;
      line-height: 120%;
      font-weight: 500px;
      margin-bottom: 8px;

      @include breakpoint(medium) {
        font-size: 32px;
      }
    }

    .subhead {
      font-weight: 500;
      max-width: 894px;
      margin: 0 auto 16px;

      @include breakpoint(medium) {
        margin: 0 auto 24px;
      }
    }

    p {
      font-size: 18px;
      line-height: 155%;
    }

    .wp-block-image {
      text-align: center;

      img {
        margin-bottom: 16px;
      }
    }

    .wp-block-columns {
      .wp-block-column {
        border-radius: 16px;
      }
    }

    .business-card-columns {
      .business-card {
        position: relative;
        padding-bottom: 50px;
        margin-bottom: 32px;
        .wp-block-button {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          text-align: center;
          .wp-block-button__link {
            margin: 0;
          }
        }
      }
    }

    .wp-block-button {
      .wp-block-button__link {
        background-color: $lumos-purple;
        border-color: $lumos-purple;
        font-size: 18px;
        padding: 15px 32px 15px;

        &:hover {
          border-color: $vivid-violet;
          background-color: $vivid-violet;
        }
      }
    }
  }



  // Busiess Page element wrappers

  &.business-banner {
    position: relative;
    padding: 0 14px;
    @include breakpoint(large) {
      padding: 0;
    }
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      display: block;
      width: 100vw;
      height: 100%;
      background-color: $lumos-purple;
      z-index: -1;
    }

    h2 {
      font-size: 24px;
      line-height: 120%;
      margin-bottom: 18px;

      @include breakpoint(medium) {
        font-size: 42px;
      }
    }

    p {
      font-size: 18px;
      line-height: 130%;
      margin-bottom: 8px;

      @include breakpoint(medium) {
        font-size: 20px;
      }
    }

    .wp-block-button {
      .wp-block-button__link {
        margin: 0;
        color: $pastel-blue;
        background-color: $lumos-purple;
        border-color: $pastel-blue;
        font-size: 18px;
        padding: 15px 32px 15px;

        &:hover {
          border-color: $vivid-violet;
        }
      }
    }


    .gform_wrapper {

      .gform_validation_errors {
        background-color: transparent;
        border-color: transparent;
        padding-left:0;
        padding-right:0;
        &>h2 {
          font-size: 24px;
          line-height: 155%;
          color: $purple-pastel-2;
          text-align: center;
          .gform-icon {
            display: none;
          }
        }
      }

      &.gravity-theme .description,
      &.gravity-theme .gfield_description,
      &.gravity-theme .gsection_description,
      &.gravity-theme .instruction {
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        background-color: transparent;
        border-color: transparent;
        color: $purple-pastel-2;
      }

      &.gravity-theme .gform_fields {
        grid-row-gap: 24px;
      }

      &.gravity-theme .ginput_complex label, &.gravity-theme .ginput_complex legend,
      &.gravity-theme .gfield_label {
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        color: $white;
        margin-bottom: 8px;
      }

      .gfield_required .gfield_required_custom, .gfield_required .gfield_required_text {
        display: none;
      }

      &.gravity-theme .gfield-choice-input+label {
        color: $white;
        font-weight: 400;
        font-size: 18px;
        line-height: 155%;
      }

      &.gravity-theme fieldset.preferred-contact {
        // display: flex;
        // justify-content: center;

        .validation_message {
          text-align: center;
        }

        legend {
          text-align: center;
        }

        .gfield_radio {
          display: flex;
          justify-content: center;
          align-items: center;


          input[type=radio] {
            appearance: none;
            background-color: $lumos-purple;
            margin: 0;
            font: inherit;
            color: $purple;
            width: 14px;
            height: 14px;
            border: 1px solid $white;
            border-radius: 50%;

            display: inline-block;
            place-content: center;

            &::before {
              content: "";
              display: block;
              width: 8px;
              height: 8px;
              border-radius: 50%;
              transform: scale(0);
              transition: 120ms transform ease-in-out;
              box-shadow: inset 1em 1em $white;
              margin: 2px;
            }

            &:checked {
              border-color: $white;
              &:before {
                transform: scale(1);
              }
            }
          }
        }
      }

      &.gravity-theme .ginput_complex input:not([type="radio"]), &.gravity-theme .ginput_complex select,
      &.gravity-theme .gfield input:not([type="radio"]), &.gravity-theme .gfield select,
      &.gravity-theme .gfield textarea {
        border: 1.5px solid #FFFFFF;
        border-radius: 48px;
        height: 54px;
        background-color: $lumos-purple;
        padding-left: 24px;
        padding-right: 24px;
        font-size: 18px;
        line-height: 155%;
        color: $white;

        &::placeholder {
          color: $white;
          opacity: 0.6;
        }

        &:-ms-input-placeholder {
          color: $white;
          opacity: 0.6;
        }

        &::-ms-input-placeholder {
          color: $white;
          opacity: 0.6;
        }
      }

      &.gravity-theme .gfield textarea {
        height: 160px;
        border-radius: 24px;
      }

      .comment-field {
        max-width: 525px;
        margin-left: auto;
        margin-right: auto;
      }
      .ginput_container.ginput_container_textarea {

      }

      .gform_footer {
        text-align: center;
        input[type="submit"] {
          margin-left: auto;
          margin-right: auto;
        }
      }

      .button {
        background-color: $pastel-blue;
        border-color: $pastel-blue;
        color: $lumos-purple;
        padding: 15px 32px 17px;
        font-size: 18px;
        line-height: 90%;

        &:hover {
          background-color: lighten($pastel-blue, 10%);
          border-color: lighten($pastel-blue, 10%);
          color: $lumos-purple;
        }
      }
    }



  }

  &.business-service-group {

    font-size: 16px;
    line-height: 160%;

    h2 {
      font-size: 28px;
      line-height: 120%;
      font-weight: 600;

      @include breakpoint(medium) {
        font-size: 42px;
      }
    }
    h3 {
      font-size: 24px;
      line-height: 120%;
      font-weight: 600;
      margin-bottom: 8px;

      @include breakpoint(medium) {
        font-size: 32px;
      }
    }

    ul {
      li {
        font-weight: 500;
        margin-bottom: 16px;
      }
    }


    .wp-block-columns {
      gap: 0;
      @include breakpoint(782px) {
        gap: 2em;
      }

      .wp-block-column {
        position: relative;
        background-color: $slate;
        padding: 32px 24px 100px 24px;
        border-radius: 16px;
        margin-bottom: 2em;

        @include breakpoint(782px) {
          margin-bottom: 0;
        }

        @include breakpoint(large) {
          padding: 32px 32px 100px 32px;
        }



        img {
          margin-bottom: 16px;
        }
      }
    }

    .wp-block-button {
      position: absolute;
      bottom: 32px;
      left: 24px;

      @include breakpoint(large) {
        left: 32px;
      }
      .wp-block-button__link {
        margin: 0;
        background-color: $lumos-purple;
        border-color: $lumos-purple;
        font-size: 18px;
        padding: 15px 32px 15px;

        &:hover {
          border-color: $vivid-violet;
          background-color: $vivid-violet;
        }

        &:focus {
          color: $vivid-violet;
          border-color: $lumos-purple;
          background-color: $lumos-purple;
        }
      }
    }
  }

  &.business-product-group {
    position: relative;
    font-size: 18px;
    line-height: 155%;

    padding: 0 14px;
    @include breakpoint(large) {
      padding: 0;
    }

    &.slate {
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        display: block;
        width: 100vw;
        height: 100%;
        z-index: -1;
        background-color: $slate;
      }
    }

    h2 {
      font-size: 28px;
      line-height: 120%;
      margin-bottom: 24px;

      @include breakpoint(medium) {
        font-size: 42px;
      }

      strong {
        font-weight: 700;
        @include breakpoint(medium) {
          font-weight: 600;
        }
      }
    }

    h3 {
      font-size: 24px;
      line-height: 120%;

      @include breakpoint(medium) {
        font-size: 32px;
      }
    }

    &.narrow .wp-block-group__inner-container {
      max-width: 896px;
      margin: 0 auto;
    }

    .service-group {
      > .wp-block-group__inner-container {
        display: flex;
        // flex-direction: column;
        //
        // @include breakpoint(medium) {
          flex-direction: row;
        // }

        > .wp-block-group {
          flex: 1;
        }

        .wp-block-image {
          text-align: left;
          margin: 0 24px 0 0;
          width: 74px;

          @include breakpoint(medium) {
            margin: 0 48px 0 0;
          }

          img {
            margin-bottom: 16px;
          }
        }
      }
    }

    .wp-block-button {
      .wp-block-button__link {
        margin: 0;
        background-color: $lumos-purple;
        border-color: $lumos-purple;
        font-size: 18px;
        padding: 15px 32px 15px;

        &:hover {
          border-color: $vivid-violet;
          background-color: $vivid-violet;
        }

        &:focus {
          color: $vivid-violet;
          border-color: $lumos-purple;
          background-color: $lumos-purple;
        }
      }
    }

    .button {
      background-color: $lumos-purple;
      border-color: $lumos-purple;

      &:hover {
        border-color: $vivid-violet;
        background-color: $vivid-violet;
      }
    }

    .to-order {
      font-size: 20px;
      line-height: 130%;
      display: flex;
      align-items: center;
      justify-content: center;

      @include breakpoint(large) {
        justify-content: flex-start;
      }
      .button {
        margin: 0 16px;
      }
    }
  }



  &.lumos-va-help-module {
    padding: 0 16px;
    &.purple {
      a {
        color: $white;
      }
    }
  }



  // Residential Internet Groups

  &.internet-pick-plan {

    h2 {
      font-size: 28px;
      line-height: 120%;

      @include breakpoint(large) {
        font-size: 42px;
      }
    }

    .plan-slider {
      margin: 0 -10px;
      gap: 0;

      @include breakpoint(782px) {
        margin: 0;
      }

      .plan-column {
        @include breakpoint(782px) {
          margin-right: 2em;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .slick-slider {
      .slick-arrow {
        width: 35px;
        height: 70px;
        background-color: rgba($purple, 0.75);
        border-radius: 0;
        z-index: 1;

        &.slick-prev, &.slick-next {
          &:before {
            display: none;
          }
          &:after {
            content:'';
            position: absolute;
            left: 0;
            top: 0;
            display: block;
            width: 100%;
            height: 100%;
            background-image: url(../images/icon-chevron-down-white.svg);
            background-size: 95% auto;
            background-repeat: no-repeat;
            background-position: center;
          }
        }

        &.slick-prev {
          left: 0;
          border-top-right-radius: 16px;
          border-bottom-right-radius: 16px;
          &:after {
            transform: rotate(90deg);
          }
        }

        &.slick-next {
          right: 0;
          border-top-left-radius: 16px;
          border-bottom-left-radius: 16px;
          &:after {
            transform: rotate(-90deg);
          }
        }

        &.slick-disabled {
          opacity: 0;
        }
      }
    }

    .slick-track {
      display: flex !important;
      padding-top: 36px;
      @include breakpoint(782px) {
        padding-top: 0px;
      }
    }

    .slick-slide {
      height: inherit !important;
    }

    .wp-block-image {
      .aligncenter {
        display: flex;
        justify-content: center;
      }
    }

    .plan-column {
      position: relative;
      border-radius: 16px;
      padding-bottom: 140px;
      margin: 0 12px;

      .recommended {
        position: absolute;
        bottom: calc(100% - 16px);
        left: 0;
        display: block;
        width: 100%;
        padding: 8px 24px 24px;
        margin: 0;
        font-size: 14px;
        line-height: 140%;
        font-weight: 500;
        background-color: $vivid-violet;
        color: $lumos-purple;
        z-index: -1;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;

        // @include breakpoint(782px) {
        //   display: block;
        // }
      }

      @include breakpoint(782px) {
        margin: 0;
      }


    }

    .speed-subtitle {
      font-weight: 500;
      font-size: 20px;
      line-height: 130%;
    }

    .check-availability {
      position: absolute;
      left: 16px;
      bottom: 32px;
      width: calc(100% - 32px);

      p {
        font-size: 14px;
        line-height: 140%;
      }

      .wp-block-button {
        width: 100%;
      }

      .wp-block-button__link {
        margin: 0;
        width: 100%;
      }
    }

    .price-block {
      border-radius: 16px;
      padding: 24px;
      font-size: 16px;
      line-height: 160%;
      strong {
        font-weight: normal;
        font-size: 32px;
        line-height: 120%;
      }
      p {
        margin: 0;
      }

      .wp-block-image {
        margin-bottom: 8px;
      }

      img {
        max-height: 34px;

        @include breakpoint(782px) {
          max-height: 46px;
        }
      }
    }

    .plan-details {
      position: relative;
      padding: 16px;
      font-size: 14px;
      line-height: 140%;

      strong {
        font-weight: 700;
        font-size: 14px;
        line-height: 120%;
        text-transform: uppercase;
      }

      ul {
        font-size: 16px;
        line-height: 160%;
        font-weight: 500;
        margin-bottom: 0px;

        @include breakpoint(550px) {
          columns: 2;
          column-gap: 20px;
        }

        @include breakpoint(782px) {
          columns: 1;
        }

        li {
          margin-bottom: 8px;
        }
      }
    }
  }


  &.internet-feature-group {
    position: relative;
    font-size: 18px;
    line-height: 155%;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      display: block;
      width: 100vw;
      height: 100%;
      background-color: $slate;
      z-index: -1;
    }

    h2 {
      font-size: 28px;
      line-height: 120%;
      color: $purple;

      @include breakpoint(large) {
        font-size: 42px;
      }

      strong {
        font-weight: 600;
      }
    }

    h3 {
      color: $purple;
      font-size: 24px;
      line-height: 120%;
      margin-bottom: 16px;
      @include breakpoint(large) {
        font-size: 32px;
      }
    }

    .feature-staggered-blocks {

    }

    .feature-row {
      margin-bottom: 42px;

      &:nth-child(2) {
        .image-cell {
          img {
            object-position: 75% 50%;
            @include breakpoint(large) {
              object-position: 50% 50%;
            }
          }
        }
      }

      .cell {

      }

      .image-cell {
        img {
          margin-bottom: 24px;
          height: 254px;
          object-fit: cover;
          object-position: 25% 50%;

          @include breakpoint(medium) {
            height: auto;
          }

          @include breakpoint(large) {
            margin-bottom: 0;
            height: 100%;
            object-position: 50% 50%;
          }
        }
      }

      .copy-cell {
        @include breakpoint(large) {
          padding-right: 55px;
          padding-left: 35px;
        }

        p {
          &:last-child {
            margin: 0;
          }
        }
      }

    }
  }

  &.internet-feature-block {
    position: relative;
    font-size: 18px;
    line-height: 155%;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      display: block;
      width: 100vw;
      height: 100%;
      background-color: $lumos-purple;
      z-index: -1;
    }

    .wp-block-columns {
      margin: 0;
    }

    h2 {
      font-size: 24px;
      line-height: 120%;

      @include breakpoint(large) {
        font-size: 32px;
      }
    }
  }




  &.map-group {
    position: relative;
    color: $white;
    padding: 0;
    // padding: 30px 0 0px;

    // @include breakpoint(large) {
    //   padding: 55px 0 0px;
    // }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      display: block;
      width: 100vw;
      height: 100%;
      z-index: -1;
      background-color: $lumos-purple;
      // background-image: linear-gradient(to right, #123060 0%, #14325C 10%, #163359 20%, #183355 30%, #193351 40%, #1A324C 50%, #1A3148 60%, #1A2F44 70%, #192D3F 80%, #182B3B 90%, #172937 100%);
    }

    h2 {
      font-size: 32px;
      line-height: 44px;
      margin-bottom: 4px;

      @include breakpoint(medium) {
        font-size: 46px;
        line-height: 58px;
      }
    }

    .subheader {
      font-size: 22px;
      line-height: 28px;
      color: $white;

      @include breakpoint(medium) {
        font-size: 30px;
        line-height: 33px;
      }

      em {
        font-style: normal;
        color: $vivid-violet;
      }
    }
  }

  &.notification-section {
    position: relative;
    border: 1px solid #a2a2a2;
    border-radius: 6px;
    padding: 20px 20px 20px 40px;
    box-shadow: 0px 5px 5px 0px rgba($black, 0.15);
    font-size: 16px;
    background-color: $white;

    @include breakpoint(medium) {
      font-size: 18px;
      padding: 20px 20px 20px 60px;
    }

    &:before {
      content: "\f05a";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      -webkit-font-smoothing: antialiased;
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      line-height: 1;

      position: absolute;
      left: 13px;
      top: 25px;

      color: $purple;

      @include breakpoint(medium) {
        left: 20px;
      }

    }

    .wp-block-group__inner-container {
      p:last-child {
        margin-bottom: 0;
      }
    }
  }

  // &.price-group {
  //   position: relative;
  //   color: $white;
  //   padding: 30px 0 0px;
  //
  //   @include breakpoint(large) {
  //     padding: 55px 0 0px;
  //   }
  //
  //   &:before {
  //     content: '';
  //     position: absolute;
  //     top: 0;
  //     left: 50%;
  //     transform: translateX(-50%);
  //     display: block;
  //     width: 100vw;
  //     height: 100%;
  //     z-index: -1;
  //     background-color: #172937;
  //     background-image: linear-gradient(to right, #123060 0%, #14325C 10%, #163359 20%, #183355 30%, #193351 40%, #1A324C 50%, #1A3148 60%, #1A2F44 70%, #192D3F 80%, #182B3B 90%, #172937 100%);
  //   }
  //
  //   h2 {
  //     font-size: 32px;
  //     line-height: 44px;
  //     margin-bottom: 4px;
  //
  //     @include breakpoint(medium) {
  //       font-size: 46px;
  //       line-height: 58px;
  //     }
  //   }
  //
  //   .subheader {
  //     font-size: 22px;
  //     line-height: 28px;
  //     color: $white;
  //
  //     @include breakpoint(medium) {
  //       font-size: 30px;
  //       line-height: 33px;
  //     }
  //
  //     em {
  //       font-style: normal;
  //       color: #EEE52F;
  //     }
  //   }
  //
  //   .wp-block-columns.price-row {
  //     max-width: 1115px;
  //     margin: 40px auto 0;
  //     padding: 0;
  //   }
  //
  //   .wp-block-column {
  //     position: relative;
  //     padding: 20px 2% 20px;
  //
  //     @include breakpoint(782px) {
  //       padding: 0 2% 135px;
  //     }
  //
  //     &.featured {
  //       &:before {
  //         content: '';
  //         position: absolute;
  //         top: 0;
  //         left: -10px;
  //
  //         display: block;
  //         width: 100%;
  //         height: 100%;
  //
  //         background-image: linear-gradient(to left, #808080 0%, #FFFFFF 100%);
  //         opacity: 0.57;
  //         mix-blend-mode: overlay;
  //
  //         z-index: -1;
  //
  //         @include breakpoint(600) {
  //           left: auto;
  //           right: -16px;
  //           background-image: linear-gradient(to right, #808080 0%, #FFFFFF 100%);
  //         }
  //
  //         @include breakpoint(782px) {
  //           left: 0;
  //           right: auto;
  //           background-image: linear-gradient(to bottom, #808080 0%, #FFFFFF 100%);
  //         }
  //       }
  //     }
  //   }
  //
  //   .pricing {
  //     position: relative;
  //
  //     .offer {
  //       background-color: #00B5E2;
  //       max-width: 175px;
  //       border-radius: 5px;
  //       padding: 10px 20px;
  //       margin: 0 auto;
  //
  //       p {
  //         font-size: 11px;
  //         line-height: 12px;
  //         text-align: center;
  //         color: #004976;
  //         font-weight: 600;
  //         margin: 0;
  //
  //         &.heading {
  //           font-size: 15px;
  //           line-height: 20px;
  //           color: $white;
  //           font-weight: 800;
  //           text-transform: uppercase;
  //         }
  //       }
  //     }
  //
  //     h3 {
  //       position: relative;
  //       font-size: 15px;
  //       line-height: 1em;
  //       overflow: hidden;
  //       text-align: center;
  //       text-transform: uppercase;
  //       color: #EEE52F;
  //       margin: 0;
  //       white-space: nowrap;
  //
  //       &:before,
  //       &:after {
  //         background-color: #4FB3DD;
  //         content: "";
  //         display: inline-block;
  //         height: 2px;
  //         position: relative;
  //         vertical-align: middle;
  //         width: 50%;
  //         margin-bottom: 4px;
  //       }
  //
  //       &:before {
  //         right: 0.5em;
  //         margin-left: -50%;
  //       }
  //
  //       &:after {
  //         left: 0.5em;
  //         margin-right: -50%;
  //       }
  //     }
  //
  //     .tier {
  //       font-size: 40px;
  //       line-height: 40px;
  //       text-align: center;
  //       margin: 10px 0;
  //       font-weight: 100;
  //       white-space: nowrap;
  //       text-transform: uppercase;
  //     }
  //
  //     .feature {
  //       position: relative;
  //       font-size: 10px;
  //       line-height: 1em;
  //       overflow: hidden;
  //       text-align: center;
  //       text-transform: uppercase;
  //       color: #EEE52F;
  //       margin: 0;
  //
  //       &:before,
  //       &:after {
  //         background-color: #4FB3DD;
  //         content: "";
  //         display: inline-block;
  //         height: 2px;
  //         position: relative;
  //         vertical-align: middle;
  //         width: 50%;
  //         margin-bottom: 2px;
  //       }
  //
  //       &:before {
  //         right: 0.5em;
  //         margin-left: -50%;
  //       }
  //
  //       &:after {
  //         left: 0.5em;
  //         margin-right: -50%;
  //       }
  //     }
  //
  //     .tier-price {
  //       display: flex;
  //       justify-content: center;
  //       align-items: center;
  //       align-items: flex-start;
  //       color: #EEE52F;
  //       margin-top: 15px;
  //
  //       .price-dollar {
  //         font-size: 35px;
  //         line-height: 45px;
  //
  //         @include breakpoint(large) {
  //           font-size: 45px;
  //           line-height: 60px;
  //         }
  //       }
  //
  //       .price-main {
  //         font-size: 75px;
  //         line-height: 65px;
  //         white-space: nowrap;
  //         letter-spacing: -4px;
  //
  //         >span {
  //           margin-right: -5px;
  //         }
  //
  //         @include breakpoint(large) {
  //           font-size: 90px;
  //           line-height: 80px;
  //         }
  //       }
  //
  //       .price-super {
  //         display: flex;
  //         flex-direction: column;
  //
  //         span {
  //           &:first-child {
  //             font-size: 40px;
  //             line-height: 40px;
  //             white-space: nowrap;
  //
  //             @include breakpoint(large) {
  //               font-size: 50px;
  //               line-height: 50px;
  //             }
  //           }
  //
  //           &:last-child {
  //             font-size: 11px;
  //             line-height: 12px;
  //             font-weight: 600;
  //             margin-left: 4px;
  //             max-width: 60px;
  //
  //             @include breakpoint(medium down) {
  //               br {
  //                 display: none;
  //               }
  //             }
  //           }
  //         }
  //       }
  //
  //
  //     }
  //
  //     .disclaimer {
  //       font-size: 11px;
  //       line-height: 17px;
  //       color: #EEE52F;
  //       text-align: center;
  //       max-width: 146px;
  //       margin: 0 auto;
  //     }
  //   }
  //
  //   .wp-block-buttons {
  //     padding: 10px 0 30px;
  //
  //     @include breakpoint(782px) {
  //       position: absolute;
  //       bottom: 0;
  //       left: 50%;
  //       transform: translateX(-50%);
  //       padding: 44px 0;
  //     }
  //
  //     .wp-block-button {
  //       margin: 0;
  //
  //       .wp-block-button__link {
  //         position: relative;
  //         margin: 0;
  //         padding: 12px 20px;
  //         background-color: #00B5E2;
  //         color: #004976;
  //
  //         &:hover {
  //           background-color: lighten(#00B5E2, 10%);
  //         }
  //
  //         &:before,
  //         &:after {
  //           content: "";
  //           position: absolute;
  //           top: 50%;
  //           transform: translateY(-50%);
  //
  //           display: block;
  //           width: 513px;
  //           height: 65px;
  //           background-image: url(../images/decor-landing-page-devices.svg);
  //           background-position: center;
  //           background-size: 100% 100%;
  //           background-repeat: no-repeat;
  //         }
  //
  //         &:before {
  //           right: calc(100% + 20px);
  //
  //           @include breakpoint(600px) {
  //             right: calc(100% + 60px);
  //           }
  //         }
  //
  //         &:after {
  //           left: calc(100% + 20px);
  //
  //           @include breakpoint(600px) {
  //             left: calc(100% + 60px);
  //           }
  //         }
  //       }
  //     }
  //   }
  // }

  &.landing-page-gradient {
    position: relative;
    max-width: 1000px;
    margin: 0 auto;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      display: block;
      width: 100vw;
      height: 100%;
      background: $white;
      background: linear-gradient(to bottom, rgba(#57b8e7, 0.7) 0%, rgba(#57b8e7, 0) 50%);
      z-index: -1;
    }

    h3 {
      font-size: 36px;
      line-height: 45px;
      color: $dark-blue-4;
    }
  }


  &.ebb-yellow-group {
    padding: 20px;
    padding-top: 30px;
    padding-bottom: 30px;

    h2 {
      line-height: 1.6;
      margin-bottom: 0;

      @include breakpoint(small only) {
        // font-size: 26px !important;
      }
    }

    p {
      margin-bottom: 0;
    }

    .wp-block-group__inner-container {
      margin: 0 auto;

      @include breakpoint(medium) {
        max-width: 1086px;
      }
    }
  }

  &.ebb-gray-group {
    padding: 20px;
    padding-top: 46px;
    padding-bottom: 24px;

    .wp-block-group__inner-container {
      margin: 0 auto;

      @include breakpoint(medium) {
        max-width: 1086px;
      }

      p {
        @include breakpoint(medium) {
          letter-spacing: -0.6px;
        }
      }

      .wp-block-separator {
        border-top: 0px solid #707070;
        border-bottom: 1px solid #707070;
        margin-top: 46px;
        margin-bottom: 46px;
      }

      .wf-ebb-steps {
        max-width: 998px;

        .wp-block-group__inner-container {
          @include breakpoint(medium) {
            margin-left: 40px;
          }

          h2 {
            font-size: 38px;
            font-weight: 100;
            margin-bottom: 34px;
            font-family: Overpass, Arial, sans-serif;
          }

          ol {
            margin-bottom: 34px;
            margin-left: 34px;

            li {
              margin-bottom: 14px;
              font-size: 19px;
            }
          }

          ol li::marker {
            font-size: 30px;
            color: #004976;
          }
        }

      }

      .wf-ebb-enroll_wrapper {
        background: $white;

        .wf-ebb-enroll {
          background-color: #eef1f4;
          border: 1px solid $slate;
          border-radius: 6px;
          padding: 46px 40px;
          margin-bottom: 65px;
          margin-left: auto;
          margin-right: auto;

          .wf-ebb-form-intro {
            strong {
              color: $lumos-purple;
              font-size: 18px;
              // margin-bottom: 34px;
              display: block;
            }
          }

          .gfield_label {
            font-size: 14px;
          }

          .gfield_radio {
            .gchoice {
              label {
                font-size: 18px;
                padding-top: 3px;
              }

            }

            .gfield-choice-input {
              margin-bottom: 0 !important;
            }
          }

          .gfield.gfield--width-third {
            @include breakpoint(small only) {
              -ms-grid-column-span: 12;
              grid-column: span 12;
            }
          }



          .wf-ebb-question {
            .gfield_label {
              font-size: 19px !important;
              font-weight: 400;
              color: #000000;
              margin-bottom: 20px
            }
          }

          .gfield_required {
            color: #000000;
          }

          [type=date],
          [type=email],
          [type=number],
          [type=tel],
          [type=text],
          textarea {
            border-radius: 7px;
            padding-left: 12px;
            padding-right: 12px;
            padding-bottom: 3px;
          }

          ::-webkit-input-placeholder {
            color: #6A6969;
            font-size: 15px;
            letter-spacing: -0.1px;
          }

          :-ms-input-placeholder {
            color: #6A6969;
            font-size: 15px;
            letter-spacing: -0.1px;
          }

          ::placeholder {
            color: #6A6969;
            font-size: 15px;
            letter-spacing: -0.1px;
          }



          .gform_fields {
            grid-row-gap: 36px;

            @include breakpoint(small only) {
              grid-row-gap: 20px;
            }
          }

          .gform_footer {
            padding-top: 36px;
          }


          .gform_footer .button {
            max-width: 200px;
            width: 100% !important;
            display: block !important;
            margin: 0 auto !important;
            border-radius: 24px;
            background-color: $purple;
            color: #fff;
            letter-spacing: 1px;
            padding: 12px 35px;
            // padding-top: 12px;
            // padding-bottom: 8px;
            font-size: 18px !important;
            font-weight: 700;
          }
        }
      }

    }


  }

  &.wf-ebb-legal {
    padding: 20px;
    padding-top: 46px;
    padding-bottom: 24px;


    .wp-block-group__inner-container {
      margin: 0 auto;

      @include breakpoint(medium) {
        max-width: 968px;
      }
    }
  }

  &.wf-sub-header-fibertown {
    .wp-block-group__inner-container {
      max-width: 1110px;
      margin: 0 auto;
      padding: 30px;
    }
  }

  &.wf-shop-fiber-fibertown {
    #shop-fiber {
      @include breakpoint(medium) {
        padding-left: 3.625rem;
      }

      @include breakpoint(small only) {
        margin-left: rem-calc(20);
      }

    }

  }
}
