.residential-order-reivew {
  .residential-order-review-content {
    padding: rem-calc(70);
    background-color: $light-gray;
    .product-wrap {
      padding-top: rem-calc(30);
      padding-bottom: rem-calc(30);
    }
  }

  h1 {
    color: $dark-blue;
    font-size: rem-calc(42);
    line-height: rem-calc(53);
  }

  .order-items {
    td {
      vertical-align: top;
    }
    img {
      max-width: 75%;
    }
    .price {
      white-space: nowrap;
      text-align: right;
    }
  }
}
