.basic-posts-block {
  // margin-bottom: rem-calc(-30);


  .press-releases {
    @include breakpoint(large) {
      padding: 0 32px;
    }
  }

  &.fullwidth {
    background-color: $white;
    .post-listings {
      margin-top: rem-calc(-30);
      // &:after {
      //   content: '';
      //   display: block;
      //   position: absolute;
      //   top: rem-calc(30);
      //   left: 100%;
      //   opacity: 0.07;
      //   width: rem-calc(90);
      //   height: rem-calc(90);
      //   background: linear-gradient(-45deg, rgba($black, 0.0) 0%,rgba($black, 0) 50%, rgba($black, 1) 100%);
      //   pointer-events: none;
      // }
      // &:before {
      //   content: '';
      //   display: block;
      //   position: absolute;
      //   top: rem-calc(30);
      //   right: 100%;
      //   opacity: 0.07;
      //   width: rem-calc(90);
      //   height: rem-calc(90);
      //   background: linear-gradient(45deg, rgba($black, 0.0) 0%,rgba($black, 0) 50%, rgba($black, 1) 100%);
      //   pointer-events: none;
      // }
    }
  }

  &.showsocial {
    background-color: $white;

    .post-sorting {
      padding: rem-calc(60) 0 rem-calc(200);
      margin: 0;
      &:before {
        display: none;
      }
    }
  }

  h2 {
    color: $lumos-purple;
    // font-size: rem-calc(34);
    // line-height: rem-calc(42);
    margin-bottom: rem-calc(20);
  }

  .social-row {
    padding: rem-calc(30) 0 rem-calc(20);
    border-bottom: rem-calc(1) solid $lumos-purple;

    @include breakpoint(large) {
      padding: rem-calc(60) 0 0;
    }
  }

  .social-row-title {
    h2 {
      font-size: 32px;
      line-height: 120%;
      color: $lumos-purple;
      font-weight: bold;
    }
  }

  .blog-social {
    height: rem-calc(30);
    display: inline-block;
    font-size: rem-calc(16);
    line-height: rem-calc(30);
    color: $dark-gray;
    padding-right: rem-calc(20);
    border-right: rem-calc(1) solid $dark-gray;
    font-weight: bold;
    text-transform: uppercase;
  }
  .blog-links {
    color: $dark-gray;
    a {
      color: $dark-gray;
      display: inline-block;
      margin-left: rem-calc(20);
      opacity: 1;
      transition: all 0.2s ease;

      &:hover {
        opacity: 0.5;
      }
    }
    img {
      width: auto;
      height: rem-calc(18);
      margin: rem-calc(6) 0;
    }
  }

  .post-sorting {
    background-color: $white;
    margin: rem-calc(30) 0 0;
    padding: rem-calc(100) 0 rem-calc(200);
    position: relative;
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      opacity: 0.07;
      width: rem-calc(90);
      height: rem-calc(90);
      background: linear-gradient(45deg, rgba($black, 0.0) 0%,rgba($black, 0) 50%, rgba($black, 1) 100%);
      pointer-events: none;
    }
    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 100%;
      background-color: $white;
      width: 50Vw;
      height: 100%;
      pointer-events: none;
    }

    .post-form-wrap {
      // padding-right: 17%;
    }

    h3 {
      // font-size: rem-calc(13);
      // line-height: rem-calc(19);
      // text-transform: uppercase;
      font-weight: bold;
      color: $lumos-purple;
    }

    .email-callout {
      position: relative;
      margin-top: rem-calc(60);
    }

    .email-callout-container {
      position: absolute;
      top: 0;
      right: 0;
      width: calc(100% + 30px);

      background-color: $lumos-purple;
      color: $white;
      padding: rem-calc(20) rem-calc(30) rem-calc(30) rem-calc(10);
      border-top-right-radius: rem-calc(10);
      border-bottom-right-radius: rem-calc(10);
      z-index: 1;

      p {
        font-size: rem-calc(13);
        line-height: rem-calc(20);
      }

      .button {
        width: 100%;
        margin: 0;
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 100%;
        display: block;
        background-color: $lumos-purple;
        width: 50vw;
        height: 100%;
      }
    }
  }





  .post-listings {
    background-color: $white;
    position: relative;
    padding: rem-calc(60) rem-calc(60) rem-calc(90);

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 100%;
      background-color: $white;
      width: 50Vw;
      height: 100%;
    }

    h3 {
      font-size: rem-calc(24);
      line-height: 120%;
      font-weight: 500;
      color: $lumos-purple;
      margin-bottom: rem-calc(10);

      &.notfound {
        text-align: center;
        margin-bottom: rem-calc(100);
      }

      a {
        color: $lumos-purple;
        text-decoration: none !important;
        &:hover {
          text-decoration: underline !important;
        }
      }
    }

    .date, .download-pdf {
      display: inline-block;
      vertical-align: middle;
    }

    .date {
      font-size: rem-calc(16);
      line-height: rem-calc(20);
      font-weight: bold;
      color: $lumos-purple;
      padding-right: rem-calc(20);
    }

    .download-pdf {
      font-size: rem-calc(16);
      line-height: rem-calc(20);
      // font-weight: bold;
      border-left: rem-calc(1) solid $dark-gray;
      padding-left: rem-calc(20);
      padding-right: rem-calc(20);

      a {
        color: $purple;
        &:after {
          content: "\f019";
          font-family: 'Font Awesome 5 Free';
          font-weight: bold;
          margin-left: rem-calc(8);
        }

        &:hover {
          color: $lumos-purple;
          text-decoration: underline !important;
        }
      }
    }

    .excerpt {
      padding: rem-calc(18) 0 rem-calc(30);
      margin-bottom: rem-calc(30);
      border-bottom: rem-calc(2) dotted $purple-pastel-2;
    }
  }

  .pagination {
    a {
      display: inline-block;
      vertical-align: middle;
      width: rem-calc(40);
      height: rem-calc(40);
      text-align: center;
      font-size: rem-calc(16);
      font-weight: bold;
      line-height: 38px;
      border: rem-calc(1) solid $vivid-violet;
      color: $lumos-purple;
      border-radius: 50%;
      padding: 0;
      margin: 0 rem-calc(7);
      cursor: pointer;
      transition: all 0.2s ease;
      text-decoration: none !important;

      &.skip-button {
        border-color: $purple-pastel-2;
        line-height: 36px;
      }

      &.current, &:focus, &:focus-within {
        line-height: 38px;
        border-color: $lumos-purple;
        background-color: $lumos-purple;
        color: $vivid-violet;
      }
      &:hover {
        border-color: $purple;
        background-color: $white;
        color: $lumos-purple;
      }
    }

    .current {
      display: inline-block;
      vertical-align: middle;
      width: rem-calc(40);
      height: rem-calc(40);
      text-align: center;
      font-size: rem-calc(16);
      font-weight: bold;
      border-radius: 50%;
      padding: 0;
      margin: 0 rem-calc(7);
    }
  }
}

@include breakpoint(medium down) {
  .basic-posts-block {

    .mobile-post-nav-wrap {
      font-size: rem-calc(0);
      margin-bottom: rem-calc(20);

      .button {
        display: inline-block;
        vertical-align: middle;
        border-color: $purple-pastel-2;
        color: $purple;
      }

      .select-wrap {
        display: inline-block;
        vertical-align: middle;
        // border-color: $purple-pastel-2;

        select {
          font-weight: bold;
          // color: $purple;
          // text-align: center;
        }
      }
    }

    .select-wrap {
      width: 45%;
      margin: rem-calc(10) 2%;
      max-width: rem-calc(200);
    }

    .button {
      width: 45%;
      margin: rem-calc(10) 2%;
      max-width: rem-calc(200);
    }

    .post-listings {
      padding: rem-calc(35) rem-calc(20) rem-calc(60);

      h2 {
        font-size: rem-calc(22);
        line-height: rem-calc(28);
      }

      .date {
        padding-right: rem-calc(10);
      }

      .download-pdf {
        padding-left: rem-calc(10);
        padding-right: rem-calc(0);
      }
    }

    .pagination {
      a {
        width: rem-calc(32);
        height: rem-calc(32);
        line-height: 30px;
        margin: rem-calc(2);

        &.skip-button {
          width: rem-calc(32);
          height: rem-calc(32);
          line-height: 26px;
        }

        &.current, &:focus, &:focus-within {
          width: rem-calc(32);
          height: rem-calc(32);
          line-height: 30px;
        }
        &:hover {

        }
      }
    }
  }
}
