$responsive-card-table-bottom-gap: 1.5rem;
$responsive-card-table-border: 0px;
$responsive-card-table-stack-point: 640px;
$responsive-card-table-label-bg: $light-gray;

.responsive-card-table {
  border-collapse: collapse;

  &.unstriped tbody {
    background-color: transparent;
  }

  th {
    background-color: $responsive-card-table-label-bg;
  }

  tr,
  th,
  td {
    border: $responsive-card-table-border;

    button:not(.button) {
      font-size: rem-calc(13);
      line-height: rem-calc(20);
      color: $purple;
      font-weight: bold;
    }
  }

  @include breakpoint(small down) {
    thead tr {
      position: absolute;
      top: -9999em;
      left: -9999em;
    }

    tr {
      border: $responsive-card-table-border;
      display: block;
    }

    tr + tr {
      margin-top: $responsive-card-table-bottom-gap;
    }
    /* Get table cells to act like rows */

    td {
      border: none;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      border-bottom: $responsive-card-table-border;
      padding-left: 45%;
      padding-right: 0;
      position: relative;
      text-align: left;
      font-size: rem-calc(13);

      &:before {
        content: attr(data-label);
        display: inline-block;
        font-weight: bold;
        line-height: 1.1;
        font-size: rem-calc(13);
        color: $purple;
        // margin-left: -100%;
        width: 45%;
        text-align: right;
        padding-right: rem-calc(16);
        position: absolute;
        top: 11px;
        left: 0;
        z-index: 1;
      }

      // &:after {
      //   content: '';
      //   position: absolute;
      //   background: $responsive-card-table-label-bg;
      //   width: 45%;
      //   height: 95%;
      //   left: 1px;
      //   top: 1px;
      //   z-index: 0;
      // }
    }
  }
}
