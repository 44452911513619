// @font-face{
//   font-family:"Sailec W05 Medium";
//   src:url("fonts/Sailec-webfont/c96b20a7-72a1-40ba-a441-563129bb9cd6.eot?#iefix");
//   src:url("fonts/Sailec-webfont/c96b20a7-72a1-40ba-a441-563129bb9cd6.eot?#iefix") format("eot"),url("fonts/Sailec-webfont/a1b501c6-02c3-4270-abed-64bb4b8ce257.woff2") format("woff2"),url("fonts/Sailec-webfont/a3f39c3c-0827-44ea-b173-27ac5ba80d83.woff") format("woff"),url("fonts/Sailec-webfont/2a213e32-9ed4-4dd5-887b-4f0fe81b511a.ttf") format("truetype");
//   font-display: swap;
// }

.wp-block-kioken-tabs .kioken-tabs-buttons>.kioken-tabs-buttons-item {
  text-decoration: none !important;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}


.dropdown-pane {
  padding: rem-calc(20);
  .close-button {
    position: absolute;
    top: 5px;
    right: 5px;
  }
}


.ribbon {
  position: relative;
  // bottom:0px;
  // left: 0px;
  display: block;
  max-width: 720px;
  width: 100%;
  height: 35px;
  background-image: url(../images/ribbons/short.svg);
  background-size: 720px 35px;
  background-repeat: no-repeat;
  background-position: left;
  z-index: 2;

  &.white-bg {
    background-image: url(../images/ribbons/short-white-bg.svg);
    &:before {
      background-image: url(../images/ribbons/repeater-left-white-bg.svg);
    }
    &:after {
      background-image: url(../images/ribbons/repeater-right-white-bg.svg);
    }
  }

  &.slate-bg {
    background-image: url(../images/ribbons/short-slate-bg.svg);
    &:before {
      background-image: url(../images/ribbons/repeater-left-slate-bg.svg);
    }
    &:after {
      background-image: url(../images/ribbons/repeater-right-slate-bg.svg);
    }
  }

  &.lumos-purple-bg {
    background-image: url(../images/ribbons/short-lumos-purple-bg.svg);
    &:before {
      background-image: url(../images/ribbons/repeater-left-lumos-purple-bg.svg);
    }
    &:after {
      background-image: url(../images/ribbons/repeater-right-lumos-purple-bg.svg);
    }
  }

  &.lumos-blue-white-bg {
    background-image: url(../images/ribbons/short-blue-white-bg.svg);
    &:before {
      background-image: url(../images/ribbons/repeater-left-blue-white-bg.svg);
    }
    &:after {
      background-image: url(../images/ribbons/repeater-right-blue-white-bg.svg);
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 100%;
    display: block;
    width: 100vw;
    height: 35px;

    background-image: url(../images/ribbons/repeater-left.svg);
    background-size: 35px 35px;
    background-repeat: repeat-x;
    background-position: right;
    z-index: 1;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 100%;
    display: block;
    width: 100vw;
    height: 35px;

    background-image: url(../images/ribbons/repeater-right.svg);
    background-size: 35px 35px;
    background-repeat: repeat-x;
    background-position: right;
    z-index: 1;
  }
}


.search-wrap {
  display: flex;
  border: 1.5px solid $purple-pastel-2;
  border-radius: 48px;
  padding: 10px 16px 10px;
  margin-right: 8px;

  .button {
    color: $purple-pastel-2;
  }
}

.search-form {
  display: flex;
  justify-content: center;
  align-items: center;

  input {
    appearance: none;
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
    margin: 0;
    padding: 0;
    background-color: transparent;
    border: 0;
    color: $purple-pastel-2;
    height: 16px;
    border-radius: 0;

    &::-webkit-search-cancel-button {
      position:relative;
      right:0px;
      -webkit-appearance: none;
      height: 15px;
      width: 15px;
      border-radius:10px;
      background-image: url(../images/icon-x.svg);
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: center;
    }

    &, &:focus {
      appearance: none;
      border: 0;
      background-color: transparent;
      box-shadow: none;
    }

    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: $purple-pastel-2;
    }
    &::-moz-placeholder { /* Firefox 19+ */
      color: $purple-pastel-2;
    }
    &:-ms-input-placeholder { /* IE 10+ */
      color: $purple-pastel-2;
    }
    &:-moz-placeholder { /* Firefox 18- */
      color: $purple-pastel-2;
    }
  }

  button[type=submit] {
    cursor: pointer;
    border: 0;
    background-color: transparent;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;

    &.search {
      &:before {
        background-image: url(../images/icon-magnifier/purple-pastel-2.svg);
      }

      &:hover, &:focus {
        &:before {
          background-image: url(../images/icon-magnifier/vivid-violet.svg);
        }
      }
    }

  }
}

.input-container-location {
  width: 100%;
  display: flex;
  margin-right: 12px;
  height: 42px;
  background-color: transparent;
  border: 1.5px solid $purple;
  border-radius: 48px;
  input {
    margin: 0;
    height: 42px;
    background-color: transparent !important;
    color: $purple;
    border: 0;

    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: $purple;
    }
    &::-moz-placeholder { /* Firefox 19+ */
      color: $purple;
    }
    &:-ms-input-placeholder { /* IE 10+ */
      color: $purple;
    }
    &:-moz-placeholder { /* Firefox 18- */
      color: $purple;
    }

    &:focus {
      border: 0;
      box-shadow: none;
    }
  }
  .button {
    margin: 0;
    &:before {
      background-image: url(../images/icon-location-pin/purple.svg) !important;
    }
    &:hover {
      border-color: transparent !important;
      &:before {
        background-image: url(../images/icon-location-pin/lumos-purple.svg) !important;
      }
    }
    &:focus {
      background-color: transparent !important;
      &:before {
        background-image: url(../images/icon-location-pin/lumos-purple.svg) !important;
      }
    }
  }

  &.secondary {
    border-color: $purple-pastel-2;

    input {
      color: $purple-pastel-2;

      &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: $purple-pastel-2;
      }
      &::-moz-placeholder { /* Firefox 19+ */
        color: $purple-pastel;
      }
      &:-ms-input-placeholder { /* IE 10+ */
        color: $purple-pastel-2;
      }
      &:-moz-placeholder { /* Firefox 18- */
        color: $purple-pastel-2;
      }
    }

    .button {
      &:before {
        background-image: url(../images/icon-location-pin/purple-pastel-2.svg) !important;
      }
      &:hover {
        &:before {
          background-image: url(../images/icon-location-pin/purple.svg) !important;
        }
      }
      &:focus {
        background-color: transparent !important;
        &:before {
          background-image: url(../images/icon-location-pin/purple.svg) !important;
        }
      }
    }
  }

}

.page-template-template-shop-fiber {
  .datepicker {
    &.dropdown-menu {
      position: fixed !important;
    }
  }
}

.datepicker {
  &.dropdown-menu {
    // position: fixed !important;
    padding: rem-calc(10);
    border-color: $dark-gray;
    td, th {
      padding: rem-calc(2) rem-calc(8);
    }
  }
  table {
    border: 0;
    border-spacing: 1px;
    border-collapse: unset;
    tbody, tfoot, thead {
      border: 0;
    }
    thead {
      .prev, .next {
        font-size: rem-calc(16);
        font-weight: bold;
        color: $lumos-purple;
      }
    }
  }
  td, th {
    font-size: 13px;
  }

  td {
    margin: rem-calc(1);

    &.day {
      position: relative;
      // color: $text-gray;
      background-color: $middle-blue;
      color: $white;
      // background-color: $medium-gray-6;
      border: rem-calc(1) solid $white;
      font-weight: bold;

      &.disabled {
        color: $form-gray;
        background-color: $medium-gray-6;
      }

      &.active:not(.disabled) {
        color: $white;
        //border-color: $middle-blue;
        background-color: $ns-green;
      }

      &:not(.disabled) {
        &:hover {
          //border-color: $middle-blue;
          background-color: $ns-green;
        }
      }
    }

    &.old.disabled, &.new.disabled, &.old, &.new {
      border-color: $calendar-gray-1;
      color: $calendar-gray-1;
      background-color: $white;
    }

    &.old:not(.disabled), &.new:not(.disabled) {
      color: $white;
      background-color: lighten($middle-blue, 50%);

      &:hover {
        color: $white;
        background-color: lighten($ns-green, 50%);
      }
    }
  }
}

.general-content {
  position: relative;
  .cell {
    position: relative;
    padding: rem-calc(60) rem-calc(60) rem-calc(30);
    background-color: $body-background;

    &:before, &:after {
      content: '';
      display: block;
      position: absolute;
      top: rem-calc(30);
      opacity: 0.07;
      width: rem-calc(90);
      height: rem-calc(90);
    }

    &:before {
      background: linear-gradient(45deg, rgba($black, 0.0) 0%,rgba($black, 0) 50%, rgba($black, 1) 100%);
      right: calc(100%);
    }

    &:after {
      background: linear-gradient(-45deg, rgba($black, 0.0) 0%,rgba($black, 0) 50%, rgba($black, 1) 100%);
      left: calc(100%);
    }
  }

}

.four-oh-four {
  padding: rem-calc(70) 12.5%;
}

.grid-container {
  padding-left: rem-calc(58);
  padding-right: rem-calc(58);
}

.off-canvas-wrapper,
.off-canvas-content {
  min-height: 100vh;
}

.input-container {
  display: inline-block;
  // border: rem-calc(1) solid $dark-gray;
  // border-radius: $global-radius;
}

.select-wrap {
  position: relative;
  border: rem-calc(1) solid $purple;
  border-radius: 48px;
  margin-bottom: rem-calc(15);

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    background-image: url(../images/icon-chevron-down.svg);
    background-size: 16px 16px;
    background-repeat: no-repeat;
    background-color: transparent;
    pointer-events: none;
    z-index: 1;
    height: 16px;
    width: 16px;
  }

  select {
    appearance: none;
    border: none;
    font-size: rem-calc(16);
    padding: 0 0 0 rem-calc(16);
    line-height: rem-calc(38);
    margin: 0;
    border-radius: 48px;
    font-weight: 500;
    color: $lumos-purple;

    &:focus {
      box-shadow: none;
    }
  }

  &.loading {
    &:before {
      content: 'Loading...';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      line-height: 40px;
      padding-left: 16px;
      border-radius: $global-radius;
      background-color: $white;
      color: rgba($text-gray, 0.8);
    }
    &:after {
      content: '\f1ce';
      font-size: rem-calc(25);
      background-color: transparent;
      height: 33px;
      line-height: 33px;
      animation: selectLoad 1s linear infinite;
    }
  }
}

@keyframes selectLoad {
  0% {
    transform: translateY(-50%) rotate(0deg);
  }

  100% {
    transform: translateY(-50%) rotate(360deg);
  }
}

#five9-maximize-button {
  opacity: 0;
  pointer-events: none;
  // background: $lumos-purple;
  // min-width: 150px;
  //
}

#five9-minimize-button {
  background: $lumos-purple;
  width: 100%;
  display: flex;
  padding: 10px;

  .five9-icon {
    display: none;
  }
  .five9-text {
    flex: 1 1 auto;
    text-align: left;
  }
}

a {
  color: $purple;
  &:hover {
    color: $lumos-purple;
  }
}

.tooltip p {
  margin: 0;
}

.reveal.email-callout {
  padding: rem-calc(35);
}

.reveal[role=dialog] {
  // padding: rem-calc(48);
}

.screen-reader-text {
  display: none;
}

.entry-content {
  // & > h1,
  // & > h2,
  // & > h3,
  // & > h4,
  // & > h5,
  // & > h6,
  // & > p,
  // & > ul,
  // & > ol,
  // .wp-block-columns {
  //   max-width: 1200px;
  //   margin-left: auto;
  //   margin-right: auto;
  //   padding-left: rem-calc(58);
  //   padding-right: rem-calc(58);
  // }
}

.blog-entry {
  // & > h1,
  // & > h2,
  // & > h3,
  // & > h4,
  // & > h5,
  // & > h6,
  // & > p,
  // & > ul,
  // & > ol,
  // .wp-block-columns,
  // .wp-block-button {
  //   max-width: none;
  //   margin-left: auto;
  //   margin-right: auto;
  //   padding-left: 0;
  //   padding-right: 0;
  // }

  // & > h1,
  // & > h2,
  // & > h3,
  // & > h4,
  // & > h5,
  // & > h6,
  // & > p {
  //   margin-bottom: 1em;
  // }
  //
  // & > ul {
  //   margin: 1em;
  // }
  //
  // h1 {
  //
  // }
}

h1, h2, h3, h4, h5 {
  font-family: $body-font-faimly-header;
}

h1 {
  font-size: rem-calc(44);
  line-height: rem-calc(53);
}

h2 {
  font-size: rem-calc(34);
  line-height: rem-calc(42);
}

h1.search-result {
  margin-bottom: rem-calc(30);
}

.reveal {
  padding: rem-calc(5);
  iframe {
    border: 0;
  }
  .button {
    margin-bottom: 0;
  }

  &.header_video {
    padding: rem-calc(50) 5%;
  }

  &.payment-help {
    padding: rem-calc(30) rem-calc(50);
    p {
      color: $lumos-purple;
      font-size: rem-calc(24);
    }
  }
}

.clear-fix {
  &:before {
    content: '';
    display: block;
    clear: both;
  }
}

.blog-breadcrumbs {
  padding: rem-calc(20) 0;
  p {
    margin: 0;
    font-size: rem-calc(13);
  }

  a {
    color: $text-gray;
    font-weight: bold;

    &:hover {
      text-decoration: underline;
    }
  }
}

h1.article-title {
  font-size: rem-calc(44);
  line-height: rem-calc(53);
  color: $lumos-purple;
  padding: rem-calc(35) 0;
}

.article-featured {
  position: relative;
  width: 100%;
  height: rem-calc(300);

  background-color: $lumos-purple;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  background-size: cover;

  margin: 0 0 rem-calc(-30) 0;
  z-index: 0;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 50%;
    width: 100%;
    background: linear-gradient(to bottom, rgba($black, 0) 0%, rgba($black, 0.5) 100%);
  }

  div {
    height: 100%;
  }

  .shadow {
    position: relative;
    margin-left: 14.5%;
    margin-right: 14.5%;

    &:before, &:after {
      content: '';
      display: block;
      position: absolute;
      top: 100%;
      opacity: 0.07;
      width: rem-calc(90);
      height: rem-calc(90);
    }

    &:before {
      background: linear-gradient(45deg, rgba($black, 0.0) 0%,rgba($black, 0) 50%, rgba($black, 1) 100%);
      right: 100%;
    }

    &:after {
      background: linear-gradient(-45deg, rgba($black, 0.0) 0%,rgba($black, 0) 50%, rgba($black, 1) 100%);
      left: 100%;
    }
  }
}

.article-date {
  border-bottom: rem-calc(1) solid $purple;
  // margin-left: 12.5%;
  // margin-right: 12.5%;
  h2 {
    background-color: $purple;
    font-size: rem-calc(16);
    line-height: rem-calc(16);
    padding: rem-calc(8) rem-calc(10);
    display: inline-block;
    margin: 0;
    font-weight: bold;
    color: $white;
  }
}

.article-body {
  position: relative;
  // margin-left: 12.5%;
  // margin-right: 12.5%;
  background-color: $slate;
  padding: rem-calc(60) rem-calc(70);
  border-bottom: rem-calc(1) solid $purple;
}

.article-control {
  position: relative;
  // margin-left: 12.5%;
  // margin-right: 12.5%;
  background-color: transparent;
  padding: rem-calc(50) rem-calc(70);
}

.article-control-container {
  padding-left: rem-calc(60);
  padding-right: rem-calc(60);
}

.article-social {
  color: $dark-gray;
  font-weight: bold;
  line-height: 30px;
  a {
    color: $dark-gray;
    margin-right: rem-calc(15);
    transition: all 0.2s ease;
    &:hover {
      color: $middle-blue;
    }
  }
  span {
    display: inline-block;
    height: rem-calc(30);
    padding-right: rem-calc(15);
    margin-right: rem-calc(15);
    border-right: rem-calc(1) solid $dark-gray;
  }
}

.article-navigation {
  .button {
    margin-bottom: 0;
  }
}

.article-tags {
  padding: rem-calc(30) 0;
  i {
    color: $dark-gray;
    display:inline-block;
    vertical-align: middle;
    font-size: rem-calc(22);
    margin-right: rem-calc(15);
  }
  a {
    display:inline-block;
    vertical-align: middle;
    font-size: rem-calc(16);
    line-height: rem-calc(40);
    padding: rem-calc(0) rem-calc(15);
    margin-bottom: 0;
    margin-right: rem-calc(10);
    font-weight: normal;
  }
}

@include breakpoint(large down) {
  .four-oh-four {
    padding: rem-calc(40) 0%;
  }

  .grid-container {
    padding-left: rem-calc(30);
    padding-right: rem-calc(30);
  }

  .blog-breadcrumbs {
    padding-bottom: 0;
  }

  h1.article-title {
    font-size: rem-calc(26);
    line-height: rem-calc(32);
    padding: rem-calc(30) 0;
  }

  .article-featured {
    height: rem-calc(150);
  }

  .article-date {
    margin-left: 0;
    margin-right: 0;
  }

  .article-body {
    position: relative;
    margin-left: 0;
    margin-right: 0;
    background-color: $light-gray;
    padding: rem-calc(25) rem-calc(20);
    border-bottom: rem-calc(1) solid $ns-light-blue;
  }

  .article-control {
    margin-left: 0;
    margin-right: 0;
    padding: rem-calc(50) rem-calc(20);
  }

  .article-control-container {
    padding-left: 0;
    padding-right: 0;
  }

  .article-social {
    margin-bottom: rem-calc(30);
  }

}

.gallery {
  display: flex;
  flex-wrap: wrap;
  .gallery-item {

  }
  .gallery-icon {
    position: relative;
    padding-bottom: 100%;
    img {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .gallery-caption {
    font-size: rem-calc(14);
    text-align: center;
  }
}

@include breakpoint(medium down) {
  .general-content {
    .cell {
      padding: rem-calc(40) 5%;
    }
  }
  h1 {
    font-size: rem-calc(26);
    line-height: rem-calc(32);
  }

  h2 {
    font-size: rem-calc(22);
    line-height: rem-calc(28);
  }

  .grid-container {
    padding-left: rem-calc(10);
    padding-right: rem-calc(10);
  }
}

@include breakpoint(small down) {
  .article-navigation {
    .button {
      width: 100%;
      margin-bottom: rem-calc(15);
    }
  }

  .gallery {
    display: flex;
    flex-wrap: wrap;

    &.gallery-columns-4,
    &.gallery-columns-5,
    &.gallery-columns-6,
    &.gallery-columns-7,
    &.gallery-columns-8,
    &.gallery-columns-9 {
      &>dl.gallery-item {
        width: 33.3%;
      }
    }

  }
}


table.payment-table {
  border: none;
  thead {
    border: none;
    background-color: $lumos-purple;
    color: $white;
  }
  tbody {
    border: none;
    tr {
      border: none;
      background-color: transparent;

      &[data-edit-container] {
        td {
          padding: 0;
        }
      }

      &.payment-form-container:nth-of-type(2n) {
        border-bottom: 1px solid #bfbfbf;
      }
    }
  }

  .payment-row button {
    color: $link-blue;
    font-size: rem-calc(13);
    margin: 0;
    font-weight: 700;
    cursor: pointer;
    margin-left: 15px;
    display: inline-block;
    vertical-align: middle;

    @include breakpoint(medium) {
      margin-left: 20px;
    }
  }

  .action-buttons {
    white-space: nowrap;
    .text {
      display: none;
      @include breakpoint(medium) {
        display: inline-block;
      }
    }
    .icon {
      display: inline-block;
      line-height: 30px;
      @include breakpoint(medium) {
        display: none;
      }
      &.remove {
        font-size: 30px;
        font-weight: 400;
      }
    }
  }
}

.landing-page {
  padding-top: rem-calc(40);
  padding-bottom: rem-calc(40);

  background-image: url(../images/landingpage-bg.png);
  background-size: 550px auto;
  background-position: -100px -100px;
  background-repeat: no-repeat;

  @include breakpoint(large) {
    padding-top: rem-calc(60);
    padding-bottom: rem-calc(60);
  }

  h1 {
    font-size: rem-calc(42);
    line-height: rem-calc(52);
    font-weight: normal;
  }

  h2 {
    font-size: rem-calc(24);
    line-height: rem-calc(34);
    font-weight: normal;
  }

  h1, h2 {
    margin-bottom: rem-calc(30);
    padding-left: rem-calc(20);
    padding-right: rem-calc(20);
    color: $lumos-purple;
  }

  p {
    margin-bottom: rem-calc(24);
  }

  .wp-block-table, p {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding-left: rem-calc(20);
    padding-right: rem-calc(20);
    font-size: rem-calc(16);
  }
}

.account-maintenance-message {
  min-height: rem-calc(300);

  &.menu-style {
    background-color: rgba($middle-blue, 0.98);
    color: $white;
    width: 100%;
    border-top: rem-calc(2) solid #fff;

    .maintenance-message {
      font-size: rem-calc(22);
      line-height: rem-calc(26);
      @include breakpoint(medium) {
        font-size: rem-calc(26);
        line-height: rem-calc(30);
      }
    }
  }
}

.maintenance-message {
  margin: rem-calc(30) 0;
  font-size: rem-calc(26);
  line-height: rem-calc(30);
  @include breakpoint(medium) {
    font-size: rem-calc(32);
    line-height: rem-calc(36);
  }
}

.ecommerce-maintenance-alert {
  border-radius: rem-calc(24);
  background-color: $ns-green;
  color: $white;
  p {
    font-weight: bold;
    padding: rem-calc(20);
    margin: 0;
  }
}

@keyframes spinnerSpin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}





.customscrolltable {
  width: 100%;
  max-height: calc(100vh - 110px);
  overflow: auto;

  @include breakpoint(large) {
    max-height: unset;
  }
}

table.voip-table {

  margin: 0;
  border: none;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;

  font-size: 13px;

  @include breakpoint(medium) {
    font-size: 16px;
  }

  caption {
    width: 100vw;
    position: sticky;
    left: 0;
    @include breakpoint(large) {
      width: 100%;
    }
  }



  thead {
    background-color: $white;
    th {
      background-color: $white;
      height: 150px;
      width: 33vw;
      position: sticky;
      top: 0;
      z-index: 1;

      @include breakpoint(medium) {
        width: 25vw;
      }

      @include breakpoint(large) {
        width: 12.5%;
      }

      &:first-child {
        position: sticky;
        left: 0;
        z-index: 2;
      }
    }
  }

  tbody {
    tr:first-child {
      th {
        position: sticky;
        top: 0;
        z-index: 1;
      }
    }
    th {
      position: sticky;
      left: 0;
      z-index: 1;
      background-color: $white;
    }
    tr:nth-child(2n) {
      th {
        background-color: #f2f2f2;
      }
    }
  }

  th, td {
    text-align: center;
  }

  th {

  }

  img {
    display: block;
    width: 100%;
  }
}

.wp-block-button__link {
  @extend .button;
}

.wp-block-button {
  &.secondary {
    .wp-block-button__link {
      @extend .secondary;
    }
  }
  &.large {
    .wp-block-button__link {
      @extend .large;
    }
  }
}

iframe[title="reCAPTCHA"] {
  opacity: 1;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}


.landing-page-buttons.wp-block-buttons {
  position: relative;
  top: -24px;
  margin: 0 0 -1em;
  .wp-block-button {
    margin: 0;
    .wp-block-button__link {
      margin: 0;
    }
  }
}

.landing-prehead {
  padding: 0 15px;
  margin-bottom: -30px;

  h2 {
    line-height: 1.2em;
  }

  p {
    line-height: 1.2em;
  }
}

article {
  a:not(.button):not(.accordion-title) {
    text-decoration: underline;

    &:hover {
      // text-decoration: none;
    }
  }
}

.nowrap {
  white-space: nowrap;
}


.show-for-feac {
  display: none;
  &.show {
    display: inline-block;
  }
}

.full-loader {
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($white, 0.6);
  z-index: 9500;

  &:after {
    // display: none;
    // content: '\f1ce';
    // font-family: Font Awesome\ 5 Free;
    // font-weight: 700;
    // font-size: rem-calc(150);
    // color: $vivid-violet;
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    // animation: loadingAccountSection 4s linear infinite;
    // z-index: 1;

    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    width: 150px;
    height: 150px;
    border: 20px solid rgba($purple, 0.25);
    border-top-color: $purple;
    border-radius: 50%;
    animation: spin 1s ease-in-out infinite;
  }



  &.ecomm {
    position: absolute;
    z-index: 90;
  }
}

.loading-icon-lumos {
  display: inline-block;
  width: 100px;
  height: 100px;
  border: 15px solid rgba($purple, 0.25);
  border-top-color: $purple;
  border-radius: 50%;
  animation: spin-icon 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes spin-icon {
  to {
    transform: rotate(360deg);
  }
}


.leadform {

  .gform_wrapper {

    .gform_validation_errors {
      background-color: transparent;
      border-color: transparent;
      padding-left:0;
      padding-right:0;
      box-shadow: none;
      border: 2px solid $purple;
      border-radius: 16px;
      margin-bottom: 16px;
      background-color: $white;
      &>h2 {
        font-size: 24px;
        line-height: 155%;
        color: $purple;
        text-align: center;
        .gform-icon {
          display: none;
        }
      }
    }

    &.gravity-theme .description,
    &.gravity-theme .gfield_description,
    &.gravity-theme .gsection_description,
    &.gravity-theme .instruction {
      padding-left: 0;
      padding-right: 0;
      padding-top: 0;
      background-color: transparent;
      border-color: transparent;
      color: $purple;
    }

    &.gravity-theme .gform_fields {
      grid-row-gap: 24px;
    }

    &.gravity-theme .ginput_complex label, &.gravity-theme .ginput_complex legend,
    &.gravity-theme .gfield_label {
      font-weight: 500;
      font-size: 14px;
      line-height: 140%;
      color: $lumos-purple;
      margin-bottom: 8px;
    }

    .gfield_required .gfield_required_custom, .gfield_required .gfield_required_text {
      display: none;
    }

    &.gravity-theme .gfield-choice-input+label {
      color:  $lumos-purple;
      font-weight: 400;
      font-size: 18px;
      line-height: 155%;
    }

    &.gravity-theme fieldset.preferred-contact {
      // display: flex;
      // justify-content: center;

      .validation_message {
        text-align: center;
      }

      legend {
        text-align: center;
      }

      .gfield_radio {
        display: flex;
        justify-content: center;
        align-items: center;


        input[type=radio] {
          appearance: none;
          background-color: $lumos-purple;
          margin: 0;
          font: inherit;
          color: $lumos-purple;
          width: 14px;
          height: 14px;
          border: 1px solid  $lumos-purple;
          border-radius: 50%;

          display: inline-block;
          place-content: center;

          &::before {
            content: "";
            display: block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            transform: scale(0);
            transition: 120ms transform ease-in-out;
            box-shadow: inset 1em 1em $lumos-purple;
            margin: 2px;
          }

          &:checked {
            border-color:  $lumos-purple;
            &:before {
              transform: scale(1);
            }
          }
        }
      }
    }

    &.gravity-theme .ginput_complex input:not([type="radio"]), &.gravity-theme .ginput_complex select,
    &.gravity-theme .gfield input:not([type="radio"]), &.gravity-theme .gfield select,
    &.gravity-theme .gfield textarea {
      border: 1.5px solid $lumos-purple;
      border-radius: 48px;
      height: 54px;
      background-color: $slate;
      padding-left: 24px;
      padding-right: 24px;
      font-size: 18px;
      line-height: 155%;
      color: $lumos-purple;

      &::placeholder {
        color:  $lumos-purple;
        opacity: 0.6;
      }

      &:-ms-input-placeholder {
        color:  $lumos-purple;
        opacity: 0.6;
      }

      &::-ms-input-placeholder {
        color:  $lumos-purple;
        opacity: 0.6;
      }
    }

    &.gravity-theme .gfield textarea {
      height: 160px;
      border-radius: 24px;
    }

    .comment-field {
      max-width: 525px;
      margin-left: auto;
      margin-right: auto;
    }
    .ginput_container.ginput_container_textarea {

    }

    .gform_footer {
      text-align: left;
      // input[type="submit"] {
      //   margin-left: auto;
      //   margin-right: auto;
      // }
    }

    .button {
      font-size: 18px;
      padding: 15px 32px 15px;
    }
  }


}


.hero-card {
  text-align: center;

  .card-title {
    position: relative;
    bottom: -19px;
    font-size: 14px;
    line-height: 120%;
    padding: 16px 15px;
    background-color: #F7DEFD;
    color: #25054D !important;
    border-radius: 40px;
    font-weight: 600;
    display: inline-block;
    margin: 0;
    z-index: 1;
    min-width: 87%;
  }
  .card-body {
    display: inline-block;
    width: 100%;
    text-align: center;
    background-color: #F9AAFF;
    border-radius: 18px;
    padding: 35px 21px 17px;
    color: #25054D !important;
    font-size: 14px;
    line-height: 120%;

    @include breakpoint(782px) {
      text-align: left;
    }

    p {
      margin: 0;
    }
  }
  .card-price {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    font-size: 36px;
    line-height: 36px;
    font-weight: 500;

    @include breakpoint(782px) {
      justify-content: flex-start;
    }
  }
  .currency {

  }
  .dollar {
    font-size: 64px;
    line-height: 56px;
  }
  .cents {

  }
  .term {
    font-size: 12px;
    line-height: 12px;
    font-weight: 400;
  }
  .cent-term-col {
    display: flex;
    flex-direction: column;
  }
}
