.simple-header {
  background-color: $footer-blue;
  border-top: rem-calc(2) solid $link-blue;
  color: $white;
  padding: rem-calc(40) 0 rem-calc(70);
  margin: 0 0 rem-calc(-30);

  h1 {
    font-size: rem-calc(40);
    line-height: 1.3em;
    font-weight: bold;

    @include breakpoint(small down) {
      font-size: rem-calc(28);
    }
  }

  h2 {
    color: $light-blue;
    font-size: rem-calc(22);
    line-height: 1.4em;
    width: 50%;
    margin: 0 auto;

    &:before {
      content: '';
      width: 80%;
      display: block;
      border-bottom: rem-calc(2) solid $white;
      margin: rem-calc(20) auto;
    }

    @include breakpoint(small down) {
      width: 100%;
      font-size: rem-calc(18);
    }
  }
}
