.techsol-video-conatiner {
  background-color: $dark-blue;
  background-image: linear-gradient(to bottom, rgba($black, 1) 0%, rgba($black, 0) 85%, rgba($black, 0) 100%);
  color: $light-blue;
  padding: rem-calc(60) 0 rem-calc(60);
  margin: rem-calc(40) 0 0;

  h2 {
    font-size: rem-calc(34);
    line-height: rem-calc(48);
    margin-bottom: rem-calc(30);
  }
}

@include breakpoint(small down) {
  .techsol-video-conatiner {
    padding: rem-calc(40) 0 rem-calc(40);

    h2 {
      font-size: rem-calc(24);
      line-height: rem-calc(34);
      margin-bottom: rem-calc(20);
    }
  }
}
