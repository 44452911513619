.leadership-block {
  position: relative;
  background-color: $white;
  padding: rem-calc(70) rem-calc(25);
  text-align: center;

  h2 {
    color: $lumos-purple;
    font-size: rem-calc(34);
    line-height: rem-calc(46);
    margin-bottom: rem-calc(40);
  }

  h3 {
    color: $vivid-violet;
    font-weight: bold;
    font-size: rem-calc(16);
    text-transform: uppercase;
    margin-bottom: rem-calc(30);
  }

  ul.officers {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
    padding-bottom: 0;
    margin: 0 0;
    // border-bottom: rem-calc(2) dotted $light-blue;

    li {
      width: 30%;
      margin: 0;
      flex: 0 1 30%;
      padding: 0 0 rem-calc(25);

      button {
        padding: rem-calc(10);
        cursor: pointer;

        &:hover {
          img {
            transform: scale(1.05);
          }
        }
      }

      img {
        width: rem-calc(160);
        height: rem-calc(160);
        border-radius: 50%;
        transition: all 0.2s ease;
        margin-bottom: rem-calc(25);
      }

      h4, .officer-name {
        display: block;
        font-size: rem-calc(27);
        line-height: rem-calc(37);
        color: $lumos-purple;
        margin-bottom: rem-calc(10);
        font-weight: bold;
      }

      p, span {
        display: block;
        font-size: rem-calc(16);
        margin-bottom: 0;
      }

    }
  }

  ul.board-of-directors {
    // display: flex;
    // flex-wrap: wrap;
    // justify-content: center;
    list-style: none;
    margin: 0;
    column-count: 3;
    column-gap: 0;
    li {
      // width: 33%;
      margin: 0;
      // flex: 0 1 33%;
      padding: 0 5% rem-calc(10);
      text-align: center;
      p {
        margin: 0;
      }
    }
  }

}

.officer-reveal.reveal {
  text-align: center;
  padding: rem-calc(50) rem-calc(35) rem-calc(35);
  img {
    width: rem-calc(160);
    height: rem-calc(160);
    border-radius: 50%;
    transition: all 0.2s ease;
    margin-bottom: rem-calc(25);
  }

  h4 {
    font-size: rem-calc(27);
    line-height: rem-calc(37);
    color: $text-gray;
    margin-bottom: rem-calc(10);
    font-weight: bold;
  }

  .title {
    text-align: center;
  }
  p {
    text-align: left;
    font-size: rem-calc(16);
  }
}

@include breakpoint(medium down) {
  .leadership-block {
    ul.officers {
      li {
        width: 50%;
        flex: 0 1 50%;
      }
    }
    ul.board-of-directors {
      column-count: 2;
    }
  }

}

@include breakpoint(small down) {
  .leadership-block {
    ul.officers {
      li {
        width: 100%;
        flex: 0 1 100%;
      }
    }
    ul.board-of-directors {
      column-count: 1;
    }
  }

}
