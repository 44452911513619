.business-products-block-conent {
  position: relative;
  background-color: $slate;
  padding: rem-calc(70) rem-calc(35) 0;
  text-align: center;

  &:before, &:after {
    content: '';
    display: block;
    position: absolute;
    top: rem-calc(30);
    opacity: 0.07;
    width: rem-calc(90);
    height: rem-calc(90);
  }

  &:before {
    background: linear-gradient(45deg, rgba($black, 0.0) 0%,rgba($black, 0) 50%, rgba($black, 1) 100%);
    right: calc(100%);
  }

  &:after {
    background: linear-gradient(-45deg, rgba($black, 0.0) 0%,rgba($black, 0) 50%, rgba($black, 1) 100%);
    left: calc(100%);
  }

  ul.product-listing {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    li {
      position: relative;
      width: 100%;
      flex: 1 0 25%;
      padding: 0 rem-calc(15);
      &.has_price {
        padding: 0 rem-calc(15) rem-calc(130);
      }

    }
  }

  img {
    width: rem-calc(100);
    height: rem-calc(100);
    margin-bottom: rem-calc(20);
  }

  h3 {
    font-size: rem-calc(24);
    line-height: rem-calc(34);
    color: $dark-blue-4;
  }

  p {
    font-size: rem-calc(13);
    line-height: rem-calc(18);

    &.starting {
      font-size: rem-calc(18);
      line-height: rem-calc(20);
      margin: 0;
    }
  }

  .price {
    position: absolute;
    left: 0;
    bottom: rem-calc(30);
    width: 100%;
    font-size: rem-calc(36);
    line-height: rem-calc(42);
    span {
      font-size: rem-calc(72);
      line-height: rem-calc(72);
    }
    sup {
      top: -1.0em;
    }
  }
}

@include breakpoint(medium down) {
  .business-products-block-conent {
    ul.product-listing {
      li {
        flex: 1 0 50%;
      }
    }
  }
}

@include breakpoint(small down) {
  .business-products-block-conent {
    padding: rem-calc(30) rem-calc(15);
    ul.product-listing {
      li {
        flex: 1 0 100%;
      }
    }
  }
}
