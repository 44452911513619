.nav-promo-banner {
  position: fixed;
  width: 100%;
  color: $white;
  height: rem-calc(60);
  background-color: $ns-promo-green;
  z-index: 100;
  transform: translateY(rem-calc(-60));
  transition: transform 0.5s ease;

  .grid-container {
    @include breakpoint(medium down) {
      padding: 0;
    }
  }

  &.black-gray {
    transform: translateY(rem-calc(-90));
    height: rem-calc(90);

    .mobile-section {
      height: rem-calc(90);
      background: linear-gradient(90deg, #00baee 0%, #319b42 100%);

      div {
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .promo-close {
        transform: none;
        top: 10px;
        right: 10px;
        background-color: $ns-promo-green;
      }
    }

    .left-section, .center-section, .right-section {
      height: rem-calc(90);
    }

    .left-section {
      &:after {
        border-width: 0;
        border-color: transparent;
        background: #00baee;
      }
    }

    .center-section {
      background: #e5e5e5;
      background: linear-gradient(90deg, #00baee 0%, #319b42 100%);
    }

    .right-section {
      &:before {
        border-width: 0;
        border-color: transparent;
        background: #319b42;
      }
    }
  }

  &.open {
    transform: translateY(rem-calc(0));
    .promo-open {
      top: 0%;
    }
  }

  .promo-open {
    position: absolute;
    top: 100%;
    right: 5%;
    display: block;
    background-color: $ns-promo-green;
    padding: 0 rem-calc(10);
    border-radius: 0 0 rem-calc(10) rem-calc(10);
    color: $white;
    font-size: rem-calc(13);
    line-height: rem-calc(20);
    white-space: nowrap;
    z-index: -1;
    transition: all 0.5s ease;
    i {
      font-size: rem-calc(20);
      line-height: rem-calc(20);
    }

    &:hover {
      padding: rem-calc(5) rem-calc(10) 0;
    }
  }

  .left-section, .center-section, .right-section {
    height: rem-calc(60);
    p {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin: 0;
      width: 100%;
      text-align: center;
    }
    .button {
      margin: 0;
    }
  }

  .left-section {
    position: relative;
    // background-color: $ns-promo-blue-1;
    background-color: $ns-yellow-2;

    font-size: rem-calc(13);
    line-height: rem-calc(20);
    font-weight: bold;
    text-transform: uppercase;

    color: $text-gray;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 100%;
      display: block;
      width: 50vw;
      height: 100%;
      // background-color: $ns-promo-blue-1;
      background-color: $ns-yellow-2;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 100%;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 60px 60px 0 0;
      // border-color: $ns-promo-blue-1 transparent transparent transparent;
      border-color: $ns-yellow-2 transparent transparent transparent;
      z-index: 1;
      pointer-events: none;
    }
  }

  .center-section {
    position: relative;
    background-color: $ns-promo-blue-2;
    font-size: rem-calc(18);
    line-height: rem-calc(30);
    font-weight: bold;

    p {
      z-index: 1;
    }

    .button {
      margin-left: 2%;
      &:hover.hollow.white {
        border-color: $white;
        background-color: $white;
        color: $ns-promo-blue-2;
      }
    }
  }

  .right-section {
    position: relative;
    background-color: $ns-promo-green;

    .promo-close {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      color: $white;
      font-size: rem-calc(20);
      line-height: rem-calc(40);
      display: block;
      width: rem-calc(40);
      height: rem-calc(40);
      border: rem-calc(1) solid $white;
      border-radius: 50%;
      text-align: center;
      transition: all 0.2s ease;

      &:hover {
        color: $ns-promo-green;
        background-color: $white;
        border-color: $white;
      }
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 100%;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0 60px 60px;
      border-color: transparent transparent $ns-promo-green transparent;
      pointer-events: none;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 100%;
      display: block;
      width: 50vw;
      height: 100%;
      background-color: $ns-promo-green;
      z-index: -1;
    }
  }

  .mobile-section {
    position: relative;
    color: $white;
    height: rem-calc(40);
    background-color: $ns-promo-green;

    a {
      color: $white;
    }

    div {
      position: absolute;
      top: 50%;
      left: 5%;
      transform: translateY(-50%);
      display: block;
      margin: 0;
      width: 100%;
      color: $white;
    }

    .promo-close {
      position: absolute;
      top: 50%;
      right: 5%;
      transform: translateY(-50%);
      color: $white;
      font-size: rem-calc(15);
      line-height: rem-calc(27);
      display: block;
      width: rem-calc(27);
      height: rem-calc(27);
      border: rem-calc(1) solid $white;
      border-radius: 50%;
      text-align: center;
      transition: all 0.2s ease;

      &:hover {
        color: $ns-promo-green;
        background-color: $white;
        border-color: $white;
      }
    }
  }
}

@include breakpoint(medium down) {
  .nav-promo-banner {
    height: rem-calc(40);
    transform: translateY(rem-calc(-40));
    &.open {
      transform: translateY(rem-calc(0));
    }
  }
}
