.business-contact-block {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  background-color: $slate;
  .business-contact-block-content {
    padding: rem-calc(70) 5%;
    text-align: center;
  }

  .gform_confirmation_message {
    color: $ns-green;
    font-size: rem-calc(28);
    font-weight: bold;
  }

  #field_1_7, #field_1_9 {

  }

  h2 {
    color: $lumos-purple;
    font-size: rem-calc(34);
    line-height: rem-calc(46);
    margin: 0 0 rem-calc(20);
  }

  p {
    margin: 0 0 rem-calc(30);
  }

  .gform_legacy_markup_wrapper li.gfield.gfield_error.gfield_contains_required div.ginput_container,
  .gform_legacy_markup_wrapper li.gfield.gfield_error.gfield_contains_required label.gfield_label {
    margin-top: 0;
  }

  .gform_wrapper  {

    .ginput_container {
      &.ginput_container_consent {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;

        label {
          text-align: left;
          line-height: 120%;
        }
      }
    }

    &.gform_legacy_markup_wrapper ul.gform_fields li.gfield {
      padding-right: 0;
      &.gf_left_half {
        @include breakpoint(large) {
          padding-right: 10px;
        }
      }
    }

    &.gform_legacy_markup_wrapper .top_label div.ginput_complex.ginput_container.gf_name_has_2 {
      width: 100%;
    }

    &.gform_legacy_markup_wrapper.gf_browser_chrome ul.gform_fields li.gfield span {
      padding: 0;
      margin-right: -2px;
      .name_middle, .name_suffix  {
        width: 50%;
      }
      &:first-child {
        padding-right: 8px;
        @include breakpoint(large) {
          padding-right: 10px;
        }
      }
    }

    .ginput_recaptcha > div {
      margin: 0 auto;
      @include breakpoint(large) {
        padding-left: 15px;
      }
    }

    div.validation_error {
      color: $alert-red;
      border-color: $alert-red;
    }

    .gfield {
      padding: 0;
    }

    .gfield_error .gfield_label {
      color: $alert-red;
    }

    li.gfield_error input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]),
    li.gfield_error textarea,
    li.gfield_error select {
      border-color: $alert-red;
    }

    li.gfield.gfield_error.gfield_contains_required {
      padding-right: rem-calc(16);
    }

    &.gf_browser_chrome .gfield_radio li input[type=radio] {
      margin-top: 9px;
      margin-bottom: 7px;
    }

    .ginput_complex .ginput_left input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=image]):not([type=file]),
    .ginput_complex .ginput_right input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=image]):not([type=file]),
    .ginput_complex .ginput_right select, .gform_wrapper .ginput_complex input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=image]):not([type=file]),
    .ginput_complex select {
      margin: 0 0 rem-calc(10);
    }

    .top_label div.ginput_container {
      display: flex;
      flex-wrap: wrap;
      margin-top: 0 !important;

      &.ginput_container_consent {
        flex-wrap: nowrap;
      }

      .ginput_full {
        width: 100%;
        padding: 0 !important;
      }

      .ginput_left {
        padding-right: 8px !important;
      }

      .ginput_left, .ginput_right {
        width: 50%;
      }

      &.ginput_complex:not(.has_street) {
        flex-wrap: nowrap;
        span:first-child {
          padding-right: 8px;
        }
      }

      span.name_first {
        margin-left: -6px !important;
        @include breakpoint(small down) {
          margin-left: 0 !important;
        }
      }
    }

    div.ginput_counter {
      text-align: right;
      color: $medium-gray-2;
      font-size: rem-calc(12);
      line-height: rem-calc(12);
      margin: 0;
      width: 100% !important;
    }

    ul li.gfield {
      clear:none !important;
    }

    textarea.large {
      height: rem-calc(210);
    }

    .gform_footer input.button,
    .gform_footer input[type=submit],
    .gform_page_footer input.button,
    .gform_page_footer input[type=submit] {
      display: block;
      padding: rem-calc(10) 15%;
      margin: 0 auto;
    }

    li.gfield.gfield_error.gfield_contains_required div.gfield_description {
      display: none;
    }

    li.gfield.gfield_error,
    li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning {
      background-color: transparent;
      border-color: transparent;
      border-width: 0px;
      padding-top: 0;
      padding-bottom: 0;

      &.form-float-left {
        border-color: $ns-light-blue;
        border-width: rem-calc(1);
        padding-top: rem-calc(40);
        padding-bottom: rem-calc(40);
      }
    }
  }

  form.two-column {
    .gform_body > .gform_fields {
      columns: 2;
      column-gap: 10px;

      & > li {
        width: 100% !important;
      }
    }

    textarea.large {
      height: rem-calc(178);
    }

  }

  .gform_fields {
    text-align: center;

    .gfield_checkbox {
      text-align: left;
    }

    .form-float-left {
      display: block;
      float: left;
      text-align: left;
    }

    .gf_list_inline {
      float: left;
      width: 100% !important;

      background: linear-gradient(0deg, rgba(176, 3, 211, 0.1), rgba(176, 3, 211, 0.1)), #FFFFFF;
      border: rem-calc(1) solid $purple;
      border-radius: rem-calc(6);
      padding: 10px !important;
      width: 100% !important;
      margin-bottom: rem-calc(16) !important;
      font-size: rem-calc(18);
      font-weight: bold;
      text-transform: uppercase;
      padding: 0 rem-calc(10);

      &.help {
        float: none;
        // width: 67% !important;
        // margin-left: 16px !important;
        // @include breakpoint(medium down) {
        //   width: 100%  !important;
        //   margin-left: 0px !important;
        //   margin-bottom: 16px !important;
        // }
      }

      @include breakpoint(medium down) {
        width: 100%  !important;
      }

      & > label {
        margin: 12px 0;
      }

      & > label, & > div {
        display: inline-block;
        vertical-align: top;

        @include breakpoint(medium down) {
          display: block;
        }
      }

      .gfield_radio {
        text-align: center;
        width: 100%;
      }

      .gfield_radio li {
        display: inline-block;
        margin: 0 rem-calc(10);

        label {
          width: auto !important;
        }
      }
    }

    & > li {
      display: inline-block;
      // float: left;
      width: 100% !important;
      margin: 0 !important;
      vertical-align: middle;
      &.no-label {
        &.gf_left_half, &.gf_right_half {
          width: 50% !important;
          padding: 0;
        }
        &.gf_left_half {
          @include breakpoint(large) {
            padding-right: 10px;
          }
        }
      }
    }

    li {
      &.no-label {
        // padding-left: rem-calc(30);
        label {
          display: none !important;
        }
      }

      &.inline-field {
        text-align: left;
        // padding-right: rem-calc(45);
        // padding-left: rem-calc(45);
        // margin-bottom: rem-calc(30) !important;

        label {
          display: inline-block;
          vertical-align: middle;
          font-size: rem-calc(16);
          line-height: rem-calc(22);
          font-weight: normal;
          margin-right: 3%;
        }
        .ginput_container_radio {
          display: inline-block;
          vertical-align: middle;
        }
        ul.gfield_radio {
          display: inline-block;
          vertical-align: middle;

          & > li {
            display: inline-block;
            vertical-align: middle;
            white-space: nowrap;
            margin: 0 16px;
            -ms-overflow-style: none;
            overflow: visible;
            label {
              margin-left: 5%;
            }
          }
        }
      }
    }

    .ginput_container_select {
      position: relative;
      &:after {
        content: "\f0dd";
        font-family: Font Awesome\ 5 Free;
        font-weight: 700;
        position: absolute;
        top: 4px;
        right: rem-calc(15);
        pointer-events: none;
      }
    }

    input {
      margin: 0 0 10px;
    }

    input, select {
      padding: 0 0 0 rem-calc(15) !important;
    }

    textarea {
      padding: rem-calc(10) rem-calc(15) !important;
    }

    .form-float-left {
      position: relative;
      width: 100% !important;
      padding: rem-calc(16) rem-calc(18);
      margin-bottom: 16px !important;
      border: rem-calc(1) solid $ns-light-blue;
      border-radius: $global-radius;
      background-color: $white;

      @include breakpoint(medium down) {
        width: 100% !important;
      }

      label:first-child {
        margin-bottom: rem-calc(10);
      }

      .gfield_checkbox {
        display: flex;
        flex-direction: column;

        @include breakpoint(medium) {
          flex-direction: row;
        }
        li {
          flex: 0 1 auto;
          margin-right: 10px;
        }
      }

      // &:after {
      //   content: '* required';
      //   position: absolute;
      //   top: 105%;
      //   left: 0;
      //   color: $medium-gray-2;
      //   font-size: rem-calc(16);
      //   line-height: rem-calc(22);
      //
      //   @include breakpoint(medium down) {
      //     display: none;
      //   }
      // }
    }
  }
}

@include breakpoint(medium down) {
  .business-contact-block {
    .gform_fields {
      & > li {
        width: 100% !important;
        margin-bottom: rem-calc(10) !important;
        &.no-label {

          &.gf_left_half, &.gf_right_half {
            width: 100% !important;
          }
        }
      }
    }
    form.two-column {
      .gform_body > .gform_fields {
        columns: 1;
        column-gap: 0;
      }
    }
  }
}

@include breakpoint(small down) {
  .business-contact-block {

    .business-contact-block-content {
      padding: rem-calc(30) 5%;
    }

    p {
      margin: 0 5% rem-calc(30);
    }

    .gform_wrapper {
      ul.gform_fields li.gfield {
        padding-left: rem-calc(0);
        padding-right: rem-calc(0);
        margin-bottom: 0 !important;

        &.gf_list_inline, &.help {
          margin-bottom: 16px !important;
        }

        &.form-float-left {
          padding-left: rem-calc(16) !important;
          padding-right: rem-calc(16) !important;
          margin-bottom: rem-calc(16) !important;
        }
      }
    }

    .gform_fields {
      & > li {
        width: 100% !important;
        margin-bottom: rem-calc(10) !important;
      }
      .form-float-left {
        padding-top: rem-calc(15);
        padding-bottom: rem-calc(15);
        width: 100% !important;

        &:after {
          display: none;
        }
      }
    }

  }
}
