

.blog-block {
  background-color: $white;
  position: relative;
  padding: rem-calc(70) 0;

  &:before {
    content: '';
    position: absolute;
    top: rem-calc(-30);
    left: 0;
    display: block;
    background-color: $white;
    width: 50vw;
    height: rem-calc(30);

  }
  h2 {
    color: $lumos-purple;
    // font-weight: medium;
    margin: 0;
    font-size: rem-calc(34);
    line-height: rem-calc(36);
  }

  .dotted-bottom {
    &:after {
      content: '';
      display: block;
      width: 100%;
      border-bottom: rem-calc(2) dotted $purple;
      margin: rem-calc(30) rem-calc(15);
    }

  }

  .blog-social {
    height: rem-calc(30);
    display: inline-block;
    font-size: rem-calc(16);
    line-height: rem-calc(30);
    color: $dark-gray;
    padding-right: rem-calc(20);
    border-right: rem-calc(1) solid $dark-gray;
    font-weight: bold;
    text-transform: uppercase;
  }
  .blog-links {
    color: $dark-gray;
    a {
      color: $dark-gray;
      display: inline-block;
      margin-left: rem-calc(20);
      opacity: 1;
      transition: all 0.2s ease;

      &:hover {
        opacity: 0.5;
      }
    }
    img {
      width: auto;
      height: rem-calc(18);
      margin: rem-calc(6) 0;
    }
  }

  .block-container {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  // &.archive {
  //   .block {
  //     width: 47%;
  //   }
  // }

  .block {
    width: 48.5%;
    float: left;
    background-color: $white;
    padding: rem-calc(11);
    margin: 0 0 30px 0;
    border: rem-calc(1) solid $form-gray;
    box-shadow: rem-calc(0) rem-calc(2) rem-calc(4) 0px rgba($black,0.75);
    transition: all 0.2s ease;

    &:hover {
      transform: scale(1.01);
      box-shadow: rem-calc(0) rem-calc(0) rem-calc(0) 0px rgba($black,0);
    }

    a {
      &:hover {
        .featured-photo {
          // transform: scale(1.01);
        }
      }
    }

    .featured-photo {
      position: relative;
      width: 100%;
      padding-bottom: calc(50% - 26px);
      background-color: $dark-blue;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      transition: all 0.2s ease;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        height: 35%;
        width: 100%;
        background: linear-gradient(to bottom, rgba($black, 0) 0%, rgba($black, 0.5) 100%);
      }
    }

    h3, .article-cat {
      font-family: Sailec W05 Medium,serif;
      position: absolute;
      top: rem-calc(-2);
      right: rem-calc(-2);
      display: block;
      font-size: rem-calc(13);
      line-height: rem-calc(20);
      font-weight: bold;
      background-color: $white;
      color: $lumos-purple;
      padding: rem-calc(2) rem-calc(9) rem-calc(15) rem-calc(19);
    }

    p {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      font-size: rem-calc(24);
      line-height: rem-calc(33);
      color: $white;
      text-shadow: 0px 0px 3px rgba($black, 0.8);
      padding: rem-calc(25);
      margin: 0;
    }

    &.tall {
      width: 48.5%;
      margin-left: 0;
      margin-right: 3%;
      margin-bottom: 30px;
      .featured-photo {
        width: 100%;
        padding-bottom: 100%;
      }
    }

    &.no-margin-bottom {
      // margin-top: 15px;
      margin-bottom: 30px;

      .featured-photo {

      }
    }

    &.pad-right {
      margin-right: 3%;
    }
  }
}

@include breakpoint(medium down) {
  .blog-block {
    .block {
      p {
        font-size: rem-calc(15);
        line-height: rem-calc(18);
        padding: rem-calc(10)
      }
    }
  }
}

@include breakpoint(small down) {
  .blog-block {
    padding: rem-calc(70) 0;

    h2 {
      font-size: rem-calc(22);
      line-height: rem-calc(28);
      margin-bottom: rem-calc(20);
    }

    .block {
      width: 100%;
      float: left;
      background-color: $white;
      padding: rem-calc(11);
      margin: 0 0 15px 0;
      border: rem-calc(1) solid $form-gray;
      box-shadow: rem-calc(0) rem-calc(2) rem-calc(4) 0px rgba($black,0.75);

      .featured-photo {
        padding-bottom: 100%;
      }

      h3 {
        font-size: rem-calc(13);
        line-height: rem-calc(20);
        padding: 0 rem-calc(7) rem-calc(15) rem-calc(19);
      }

      &.tall {
        width: 100%;
        margin-bottom: 15px;
      }

      &.no-margin-bottom {
        margin-top: 0;
        margin-bottom: 15px;

        .featured-photo {

        }
      }
    }
  }
}
