label[for=address_service_geocoded] {
  position: relative;
}

.ecomm-address-block-message {
  border: 1px solid $purple;
  border-radius: 16px;
  background: linear-gradient(0deg, rgba(176, 3, 211, 0.1), rgba(176, 3, 211, 0.1)), $white;
  padding: 30px 5%;
  margin: 30px 0 80px;

  @include breakpoint(large) {
    margin: 60px 0 100px;
  }
}

.address-ecomm-container {
  position: relative;
  .label-mask {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}

.ordering-container {
  position: relative;
}

.fiber-sort {
  margin-top: rem-calc(60);
  margin-bottom: rem-calc(60);
  h1 {
    font-size: rem-calc(42);
    color: $purple;
  }
  h5 {
    font-size: rem-calc(16);
    font-weight: bold;
    margin: 0 0 rem-calc(20) 0;
  }
  fieldset {
    position: relative;
    border: rem-calc(1) solid $form-gray;
    padding: rem-calc(24) rem-calc(20);
    background-color: $white;

    &.middle-fieldset {
      margin: 0 9%;

      label {
        width: 100%;
        margin: 0;
        background-color: $super-light-gray;
      }
    }
  }

  label {
    display: inline-block;
    vertical-align: middle;
    font-size: rem-calc(16);
    line-height: rem-calc(16);
    padding: rem-calc(10) rem-calc(14);
    margin: 0 rem-calc(5) 0;
    background-color: $white;
    border: rem-calc(1) solid $form-gray;
    border-radius: $global-radius;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      background-color: $bubble-blue;
    }

    &.highlight {
      background-color: $bubble-blue;
      border-color: $light-blue;
    }

    input {
      margin: 0 rem-calc(8) 0 0;
      display: inline-block;
      vertical-align: top;
      line-height: rem-calc(16);
    }
  }

  .select-wrap {
    border-color: $form-gray;
    margin-bottom: 0;
  }

  select {
    position: relative;
    appearance: none;
    background: none;
    margin-bottom: 0;
    display: inline-block;
    vertical-align: middle;
    padding: 0 12px;
    font-size: rem-calc(16);
    line-height: rem-calc(16);
    height: rem-calc(35);
    border: none;
    border-radius: $global-radius;

    &:hover {
      background-color: $bubble-blue;
    }

    &:after {
      font-family: 'Font Awesome 5 Free';
      content: '\f063';
      font-weight: 900;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}

.speed-guide {
  background-color: $super-light-gray;
  padding: 0;
  border-color: $light-blue;

  .close-button {
    span {
      font-size: rem-calc(36);
      color: $super-light-gray;
    }
  }

  div {
    float: left;
    width: 33.33%;
    padding: rem-calc(60) 7%;

    h2 {
      text-align: center;
      font-size: rem-calc(42);
      color: $purple;
    }
    p {
      text-align: center;
      font-size: rem-calc(16);
      line-height: rem-calc(24);
      margin: 0 0 rem-calc(5);
    }

    &:nth-child(1) {

    }

    &:nth-child(2) {
      background-color: rgba($middle-blue, 0.2);
    }

    &:nth-child(3) {
      background-color: rgba($middle-blue, 0.4);
    }
  }
}

// .promo-outline {
//   box-shadow: 0px 2px 4px 0px rgba($black, 0.5);
//   background-color: $ns-yellow;
//   padding: 10px;
//   border-radius: rem-calc(8);
//   margin: rem-calc(32) 0 0;
//
//   h4 {
//     margin: 0;
//     font-size: rem-calc(18);
//     text-transform: uppercase;
//     color: $purple;
//     margin-bottom: rem-calc(10);
//     font-weight: bold;
//     letter-spacing: 1px;
//   }
//   .product-row {
//     box-shadow: none;
//     margin: 0;
//   }
// }

.product-row {
  // width: 100%;
  // background-color: $white;
  // border: rem-calc(1) solid $form-gray;
  // padding: rem-calc(20) rem-calc(30);
  // border-radius: rem-calc(8);
  // margin: rem-calc(32) 0 0;
  // box-shadow: 0px 2px 4px 0px rgba($black, 0.5);

  // &.promotion {
  //   background-color: $white;
  //   border: 0;
  //   border-color: $white;
  //   color: $purple-4;
  //   padding: 0;
  //   // padding-top: rem-calc(35);
  //   // padding-bottom: rem-calc(35);
  //
  //   .button {
  //     margin: rem-calc(0) 0;
  //     &.green {
  //       border-color: $ns-green;
  //       color: $white;
  //       margin-bottom: rem-calc(26);
  //     }
  //   }
  //
  //   strong {
  //     color: $ns-promo-features-blue;
  //     text-transform: uppercase;
  //     font-size: 22px;
  //     line-height: 22px;
  //     &.most_popular {
  //       text-transform: none;
  //       color: $purple-4;
  //     }
  //   }
  //
  //   p {
  //     margin-bottom: 10px;
  //     @include breakpoint(large) {
  //       margin-bottom: 16px;
  //     }
  //   }
  //
  //   p:last-child {
  //     margin: 0;
  //   }
  //
  //   .copy-container {
  //     padding: rem-calc(35) 5.25% rem-calc(35) ;
  //   }
  //
  //   .image-container {
  //     overflow: hidden;
  //     .banner-image {
  //       display: block;
  //       width: 100%;
  //       height: 100%;
  //
  //       background-position: 100% 50%;
  //       background-size: cover;
  //       background-repeat: no-repeat;
  //
  //       border-top-left-radius: 8px;
  //       border-bottom-left-radius: 8px;
  //
  //     }
  //   }
  // }
}

.product_level_promotion {
  font-size: rem-calc(18);
  line-height: rem-calc(18);
  font-weight: bold;
  background-color: $ns-promo-green;
  text-align: center;
  color: $white;
  padding: rem-calc(10) rem-calc(15);
  margin: rem-calc(10) rem-calc(20) 0 0;

  p {
    margin: 0;
  }

}

.product-info {
  border-right: rem-calc(1) solid $middle-blue;

  h4 {
    font-size: rem-calc(16);
    span {
      color: $middle-blue;
      text-transform: uppercase;
    }
  }

  .features {
    // font-size: rem-calc(36);
    // line-height: rem-calc(48);

    font-size: rem-calc(28);
    line-height: rem-calc(40);

    font-weight: bold;
    padding: rem-calc(20) rem-calc(16);
  }

  .accordion {
    margin: 0 0 rem-calc(10) 0;

    .accordion-title {
      font-size: rem-calc(16);
      padding: rem-calc(20) rem-calc(16) rem-calc(20) rem-calc(16);
      border-bottom: rem-calc(1) solid $dark-gray;
      margin-right: rem-calc(16);
      border-radius: 0;
      border-top: 0px;
      border-left: 0px;
      border-right: 0px;

      &:focus,&:hover {
        background-color: transparent;
      }

      &:before {
        font-size: rem-calc(32);
        line-height: rem-calc(32);
        top: 50%;
        right: rem-calc(16);
        transform: translateY(-35%);
      }
    }
  }
  .additional-features {
    font-size: rem-calc(16);
  }
}

.product-select {
  font-size: rem-calc(18);
  color: $dark-gray;
  padding-left: rem-calc(30);

  p {
    margin: rem-calc(15) 0 0;
  }

  .product-price {
    font-size: rem-calc(36);
    color: $purple;

    sup {
      top: -1.25em;
    }

    .number {
      font-size: rem-calc(72);
      line-height: rem-calc(72);

      sup {
        font-size: rem-calc(26);
      }
    }
  }

  form {
    margin-top: rem-calc(20);

    .button {
      width: 100%;
    }
  }
}

.order-selection {
  margin-top: rem-calc(30);
  h1 {
    font-size: rem-calc(42);
    color: $purple;
  }

  h2 {
    font-size: rem-calc(24);
    color: $purple;
  }

  .shop-promo-image {
    margin-bottom: rem-calc(20);
    max-height: rem-calc(100);
  }
}


#voice_comments {
  label {
    border: 0;
  }
  input {
    margin-right: rem-calc(3);
  }

  input[type=text],
  input[type=number]
   {
      float: left;
      width: 40%;
  }

  textarea {
    font-size: rem-calc(14);
    min-height: rem-calc(140);
  }
}

.multigig-question {
  border: rem-calc(1) solid $slate;
  border-radius: $global-radius;
  padding: 10px 15px;
  margin-bottom: 11px;
  background-color: $light-gray;
}

.different-billing-address {
  border: rem-calc(1) solid $slate;
  border-radius: $global-radius;
  padding: 10px;
  margin-bottom: 11px;
  background-color: $light-gray;

  .no-margin {
    @include breakpoint(large) {
      margin-bottom: 0;
    }
  }
}

.product-wrap {
  background-color: $white;
  border: rem-calc(1) solid $slate;
  padding: rem-calc(20) rem-calc(30);
  margin-bottom: rem-calc(20);

  h3 {
    font-weight: bold;
    color: $lumos-purple;
    font-size: rem-calc(16);
    text-transform: uppercase;
    margin: rem-calc(10) 0 rem-calc(15);
  }

  p {
    margin: 0 rem-calc(30) rem-calc(15);

    &.form-error {
      margin: 0 rem-calc(15) rem-calc(11);
    }
  }

  .help {
    float: none;
    margin-top: rem-calc(11);
  }

  input.tt-input {
    border: 1px solid $medium-gray;
    padding-left: 11px;
  }

  input[type=radio] {
    appearance: none;
    background-color: $white;
    margin: 0;
    font: inherit;
    color: $purple;
    width: 14px;
    height: 14px;
    border: 1px solid $purple-pastel-2;
    border-radius: 50%;

    display: inline-block;
    place-content: center;

    &::before {
      content: "";
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      box-shadow: inset 1em 1em $purple;
      margin: 2px;
    }
    &:checked {
      border-color: $purple;
      &:before {
        transform: scale(1);
      }
    }
  }

  input[type="checkbox"] {
    appearance: none;
    background-color: #fff;
    margin: 0;
    font: inherit;
    color: $purple;
    width: 14px;
    height: 14px;
    border: 1px solid $purple-pastel-2;
    border-radius: 0;

    display: inline-block;
    place-content: center;

    &::before {
      content: "";
      display: block;
      width: 8px;
      height: 8px;
      clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
      transform: scale(0);
      transform-origin: bottom left;
      transition: 120ms transform ease-in-out;
      box-shadow: inset 1em 1em $purple;
      margin: 2px;
    }
    &:checked {
      border-color: $purple;
      &:before {
        transform: scale(1);
      }
    }
  }

  select.qty{
    appearance: none;
    background-color: transparent;
    border: 1px solid $purple-pastel-2;
    padding: 0 16px 0 12px;
    margin: 0;
    width: 100%;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;

    z-index: 1;

    color: $purple;
    // border: 1px solid $purple-pastel-2;
    border-radius: 0;

    &::-ms-expand {
      display: none;
    }

    outline: none;

    background-color: $white;

    background-image: url(../images/icon-select-arrow.svg);
    background-size: 10px 5px;
    background-position: calc(100% + 12px) 50%;
    background-repeat: no-repeat;

    @include breakpoint(large) {
      padding: 0 30px 0 16px;
      background-position: calc(100% + 20px) 50%;
    }

    &:focus {
      box-shadow: none;
    }


  }

  &.product-select label {
    width: 100%;
    background-color: $white;
    border: 1px solid $purple-pastel-2;
    border-radius: 16px;
    margin-bottom: 24px;
    cursor: pointer;
    padding: rem-calc(10) rem-calc(15);
    transition: all 0.2s ease;

    &.perma-hide {
      display: none;
    }

    &:hover {
      // background-color: $bubble-blue;
      background: linear-gradient(0deg, rgba(176, 3, 211, 0.1), rgba(176, 3, 211, 0.1)), $white;
    }

    &.highlight {
      background: linear-gradient(0deg, rgba(176, 3, 211, 0.1), rgba(176, 3, 211, 0.1)), $white;
      border-color: $purple;

      select {
        border-color: $purple;
      }
    }

    input {
      display: inline-block;
      vertical-align: top;
      margin: 7px rem-calc(8) 0 0;
      padding: 0;
    }

    select {
      display: inline-block;
      vertical-align: initial;
      width: auto;
      height: auto;
      font-size: 12px;
      // padding: 0 28px 0 12px;
      margin: 0 8px 0 0;
    }

    textarea {
      font-size: rem-calc(14);
      min-height: rem-calc(140);
    }

    .feature-desc {
      display: inline-block;
      vertical-align: middle;
      // line-height: rem-calc(20);
      width: 70%;
      font-size: 14px;
      line-height: 18px;

      .blue {
        color: $middle-blue;
      }

      strong {
        font-size: 18px;
        line-height: 28px;
      }

      button {
        padding: 0;
        background-color: transparent;
        font-size: rem-calc(13);
        color: $link-blue;
        margin: 0;
        border-bottom: 0px;
        vertical-align: initial;
        border-color: transparent;
      }
    }

    .feature-price {
      display: inline-block;
      vertical-align: middle;
      float: right;
      // font-weight: bold;
      font-size: 18px;
      line-height: 28px;

      small {
        display: block;
        line-height: 1;
        text-align: right;
        font-size: 12px;
        line-height: 15px;
      }
    }

    .product-desc-promo {
      // margin-top: rem-calc(16);
      // margin-left: rem-calc(24);
      font-size: 14px;
      line-height: 20px;
      p {
        margin-left: 0;
        // margin-bottom: rem-calc(16);
      }

      hr {
        margin: 10px 0;
        border: 0;
        border-bottom: 1px solid $purple-pastel-2;
      }
    }
  }

  &.address-entry {
    .grid-container {
      padding: 0;

      .grid-x .cell {
        padding: 0 rem-calc(5) 0 0;
        &:last-child {
          padding: 0;
        }
      }
    }
    label {
      span {
        display: none;
      }
    }
    hr {
      margin-top: 0;
      border: 0;
      border-bottom: 1px solid $purple-pastel-2;
    }
  }

  .dropdown-pane, .reveal {
    padding-right: rem-calc(40);
    background-color: $white;
    border: rem-calc(1) solid $form-gray;
    p {
      font-size: rem-calc(14);
      margin: rem-calc(8) 0;

      &:last-child() {
        margin-bottom: 0;
      }
    }
  }

  .dropdown-pane {
    margin-top: rem-calc(16);

    &:after, &:before {
    	bottom: 100%;
    	left: 8%;
    	border: solid transparent;
    	content: " ";
    	height: 0;
    	width: 0;
    	position: absolute;
    	pointer-events: none;
    }

    &:after {
    	border-color: rgba($white, 0);
    	border-bottom-color: $white;
    	border-width: rem-calc(7);
    	margin-left: rem-calc(-7);
    }
    &:before {
    	border-color: rgba($form-gray, 0);
    	border-bottom-color: $form-gray;
    	border-width: rem-calc(9);
    	margin-left: rem-calc(-9);
    }
  }

  &.promo-product {
    padding: rem-calc(30);

    h3 {
      margin-top: 0;
      font-size: 16px;
      line-height: 22px;
      text-transform: none;

      @include breakpoint(medium) {
        font-size: 20px;
        line-height: 26px;
      }

      &.subhead {
        margin-top: rem-calc(50);
        margin-left: rem-calc(30);
        color: $lumos-purple;
      }
    }

    p {
      margin: 0 0 rem-calc(20);
    }

    label {
      width: 100%;
      margin: 0 0 rem-calc(15);
      @include breakpoint(medium) {
        width: 90%;
        margin: 0 5% rem-calc(15);
      }

      .feature-desc {
        width: auto;
      }
    }

    #voice_comments input[type=number] {
      margin-left: 5%;
    }

    #voice_comments input[type=text] {
      margin-right: 5%;
    }

    textarea {
      width: 90%;
      margin: 0 5% rem-calc(15);
    }

    .product-desc-promo {
      padding-top: 0;
      padding-left: rem-calc(24);
      padding-right: 0;
      display: block;

      .call-block {
        padding-top: 10px;
        margin-top: -30px;
      }

      > span {
        display: block;
      }

      button {
        font-weight: bold;
        color: $link-blue;
        cursor: pointer;
      }

      &.selectbox {
        padding-top: 0;
        padding-left: 66px;

        @include breakpoint(medium down) {
          padding-left: 0px;
          padding-right: 0px;

          .feature-desc {
            // width: auto;
          }
        }
      }
    }
  }
}

.questions-container {
  padding: rem-calc(30) 0 0;
  h2 {
    color: $lumos-purple;
    font-weight: normal;
  }

  h3 {
    font-size: rem-calc(18);
    margin: rem-calc(25) 0;
  }

  a {
    color: $lumos-purple;
    &.link-underline {
      text-decoration: underline;
    }
  }
}



#address_service_billing_different,
#address_service_billing_pobox_form {
  display: none;
}

.reveal.product-details {
  padding: rem-calc(40);
  background-color: $white;
  border: rem-calc(1) solid $form-gray;
  p {
    font-size: rem-calc(14);
    margin: rem-calc(8) 0 ;
  }
}

.order-disclaimer {
  font-size: rem-calc(13);
  margin: rem-calc(60) 0;
}

.order-step-column {
  position: relative;
  &.shadow {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;;
      display: block;
      width: 100%;
      height: 100%;
      background: $white;
    }
  }

  .product-alert {
    position: relative;
    font-size: 12px;
    line-height: 20px;
    padding: 10px 20px 10px 70px;
    color: $alert-red;
    min-height: 40px;
    background-color: $white;
    margin: 0 0 10px;
    border-radius: 5px;
    box-shadow: 0px 10px 10px 0px rgba($black,0.16);

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 20px;
      transform: translateY(-50%);
      display: block;
      width: 30px;
      height: 27px;

      background-image: url(../images/icon-product-alert.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% 100%;
    }
  }
}

#sticky-progress {
  margin-top: rem-calc(30);
  @include breakpoint(large) {
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

.featured-feature-callout {
  background-color: $lumos-purple;
  border-radius: 16px;
  font-size: 20px;
  line-height: 20px;
  margin: 0 0 5px;
  color: $vivid-violet;
  padding: 11px 15px;

  @include breakpoint(medium) {
    margin: 0 5% 5px;
  }
}

.promo-highlight {
  color: $lumos-purple;
  background-color: $vivid-violet;
  border-radius: 8px;
  font-size: 14px;
  line-height: 16px;
  padding: 10px 10px 7px;
  margin: 8px 0;
}

.order-step-indicator {
  display: flex;
  list-style: none;
  margin: 0 0 rem-calc(20) 0;

  &:after {
    content: '';
    display: block;
    clear: both;
  }

  li {
    position: relative;
    display: inline-block;
    // width: 25%;
    float: left;
    font-size: rem-calc(13);
    text-align: center;
    flex: 1 1 auto;
    width: 33.3%;

    transition: all 0.4s ease;

    a {
      display: inline-block;
      color: $lumos-purple;

      &:before {
        content: "\f00c";
        color: $medium-gray-3;
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        line-height: rem-calc(32);
        display: block;
        width: rem-calc(40);
        height: rem-calc(40);
        background-color: $light-gray;
        border: rem-calc(5) solid $white;
        border-radius: 50%;
        margin: 0 auto;

        transition: all 0.4s ease;
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        top: rem-calc(17);
        right: 50%;
        border-top: rem-calc(5) dotted $light-gray;
        z-index: -1;

        transition: all 0.4s ease;
      }

      &:hover {

      }
    }



    &:first-child {
      a {
        &:after {
          display: none;
        }
      }
    }

    &.current {
      a {
        &:before {
          color: $white;
          background-color: $purple;
        }
        &:after {
          border-color: $purple;
        }
      }

    }

    &.complete {
      a {
        &:before {
          color: $white;
          background-color: $purple;
        }
        &:after {
          border-color: $purple;
        }
        &:hover {
          &:before {
            background-color: $purple-pastel-2;
          }
        }
      }

    }
  }
}

.pricing-wrap {
  background-color: $slate;
  border-radius: 16px;
}

.price-bubble {
  font-size: rem-calc(36);
  // text-align: right;
  color: $white;
  vertical-align: top;
  position: relative;
  background-color: $lumos-purple;
  border-radius: 16px;
  padding: 12px 15px;

  margin-bottom: 0;

  hr {
    margin: 6px 0;
    border: 0;
    border-bottom: 1px solid #BFBFBF;
  }

  .big-price {
    color: $vivid-violet;
    font-size: 36px;
    line-height: 39px;
    .price {
      font-size: 36px;
      line-height: 39px;
    }
  }
  .pricing-term, .subtitle {
    font-size: 14px;
    line-height: 16px;
    text-align: left;
    margin: 0 0 8px;
  }
  .disclaimer {
    font-size: 11px;
    line-height: 14px;
    margin:0;
  }
}

.price-bubble-details {
  margin: 0 0 20px;
  padding: 15px;
  border-radius: 16px;

  .disclaimer {
    margin: 0;
  }

  hr {
    margin: 6px 0;
    border: 0;
    border-bottom: 1px solid #BFBFBF;
  }

  .serviceFeesButton {
    color: $purple;
    text-decoration: underline;
    cursor: pointer;
    font-weight: 500;
  }

  h2 {
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    color: $lumos-purple;
    margin: 0;
  }

  table.unstriped tbody tr {
    border: 0;
  }

  table {
    margin-bottom: 20px;
  }
  tbody {
    border: 0;
  }
  table.unstriped tbody,
  table.unstriped tbody tr {
    background-color: transparent;
  }
  table.product-selection-details tbody tr {
    display: flex;
    border: 0;
    border-top: 1px solid #BFBFBF;
    &:first-child {
      border-top: 0;
    }
  }
  table.pricing-table tbody tr {
    border-bottom: 1px solid #BFBFBF;
    &:last-child {
      border-bottom: 0;
    }
  }

  th, td {
    padding: 10px 0;
    flex: 1 1 auto;
  }

  td {
    text-align: right;
  }

  .product-name {
    font-size: 16px;
    line-height: 21px;
    margin: 0;
    font-weight: 500;
  }

  .product-subtitle {
    font-size: 12px;
    line-height: 16px;
    margin: 0;
    color: $purple;
    font-weight: normal;

    // &.strong {
    //   font-weight: bold;
    // }

    &.green {
      color: $purple;
    }
  }

  .pricing-name {
    font-size: 16px;
    line-height: 21px;
    margin: 0;
    font-weight: 500;
  }

  .old-price {
    color: $purple;
  }

  .strikethrough {
    text-decoration: line-through;
  }

  .product-row-price {
    font-size: 16px;
    line-height: 21px;
    font-weight: 500;
  }

  .pricing-row-price {
    font-size: 16px;
    line-height: 21px;
    font-weight: 500;
  }
}

.order-selection .order-step {
  display: none;
  &[data-order-step="1"] {
    display: block;
  }
}

.order-step-column {
  .multigig-notice {
    font-size:13px;
    line-height:16px;
    @include breakpoint(medium down) {
      font-size:15px;
      line-height:18px;
      margin-top: 30px;
    }
  }
}

.order-step-column .order-step {
  display: none;

  h3 {
    font-size: rem-calc(24);
    color: $purple;
  }

  select {
    display: inline-block;
    width: 40%;
    font-size: 14px;
  }

  button {
    width: 100%;
    text-align: center;
  }

  label[for="termsconditions"] {
    padding-left: 1.5em;
    text-indent: -1.5em;
    line-height: rem-calc(20);
    margin-bottom: rem-calc(10);
    input {
      margin-bottom: 0;
    }
  }

  label {
    position: relative;
    z-index: 1;
    select:disabled {
      position: relative;
      z-index:-1;
    }
  }

  .next-step {
    &:after {
      font-family: 'Font Awesome 5 Free';
      content: '\f061';
      float: right;
    }

    &.mobile {
      &:after {
        display: none;
      }
    }
  }

  .schedule-disclaimer {
    font-size: rem-calc(12);
    line-height: rem-calc(14);
  }

  &[data-order-step="1"] {
    display: block;
  }
}

.selected_feature_desc {
  font-size: rem-calc(14);
  line-height: rem-calc(18);
  width: 100%;
  margin: rem-calc(5) 0 rem-calc(5);

  // @include breakpoint(medium) {
  //   width: 80%;
  // }
}

#product_selected_features {
  list-style: none;
  margin: 0;
  li {
    border: 1px solid $purple-pastel-2;
    border-radius: 16px;
    padding: 16px;
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.product-review {
  padding: rem-calc(30) rem-calc(30);
  h3 {
    font-size: rem-calc(16);
    font-weight: bold;
    color: $text-gray;
    margin: 0 0 rem-calc(20);
    text-transform: none;
  }
  button.edit-info {
    float: right;
    color: $purple;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.2s ease;

    &:before {
      display: inline-block;
      font-family: 'Font Awesome 5 Free';
      content: '\f303';
      font-weight: 900;
      margin-right: rem-calc(8);
      transition: all 0.2s ease;
    }

    &:hover {
      color: $vivid-violet;
      &:before {
        transform: rotate(45deg);
      }
    }
  }

  .product_title {
    margin: 0 0 rem-calc(16);
    font-size: rem-calc(28);
    color: $purple;
  }

  table {
    tbody {
      border: 0;
      tr {
        @include breakpoint(small down) {
          display: flex;
          flex-direction: column;
          width: 100%;
        }
        &:nth-child(2n) {
          background-color: transparent;
        }
      }
      th {
        text-align: right;
        @include breakpoint(small down) {
          text-align: left;
        }
      }
      td {
        text-align: left;
        @include breakpoint(small down) {
          text-align: left;
        }
      }


    }
  }
}

.mobile-bottom-price {
  position: relative;
  background-color: $purple;
  color: $white;

  &.is-stuck {
    z-index: 9001 !important;
  }

  .order-step {
    padding: 0;
  }

  .product-alert {
    position: relative;
    font-size: 12px;
    line-height: 20px;
    padding: 10px 20px 10px 40px;
    color: $alert-red;
    min-height: 40px;
    background-color: $white;
    margin: 0 0 5px;
    border-radius: 5px;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 5px;
      transform: translateY(-50%);
      display: block;
      width: 30px;
      height: 27px;

      background-image: url(../images/icon-product-alert.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% 100%;
    }
  }

  .price-bubble {
    border: 0;
    background-color: transparent;
    text-align: center;
    font-size: rem-calc(32);
    line-height: rem-calc(32);
    padding: 8px;
    margin-bottom: 0;

    .currency-mark {
      float: none;
      vertical-align: unset;
      color: $white;
    }

    .price {
      font-size: rem-calc(32);
      line-height: rem-calc(32);
      color: $white;
    }

    .per-month {
      font-size: rem-calc(18);
      letter-spacing: rem-calc(1);
      color: $white;
    }

    .pricing-term {
      color: $white;
      margin: 0;
    }

    .disclaimer {
      margin: 0;
      color: $white;
    }
    &:before, &:after {
      display: none;
    }
  }
} // end .mobile-bottom-price

.reveal-overlay[data-mobile-bottom-price-overlay] {
  z-index: 9000;
}

.serviceFeesModal {
  h4 {
    color: #00355C;
    margin-bottom: 20px;
  }
  h5 {

  }
  > div {
    margin-bottom: 20px;
  }
  @include breakpoint(small down) {
    h4 {
      font-size: 18px;
    }
    h5 {
      font-size: 12px;
      line-height: 16px;
    }
  }
}

.mobile-bottom-price-new {
  position: relative;
  background-color: $lumos-purple;
  color: $white;

  hr {
    margin: 6px 0;
    border: 0;
    border-bottom: 1px solid #BFBFBF;
  }

  .serviceFeesButton {
    color: $purple;
    text-decoration: underline;
    cursor: pointer;
    font-weight: 500;
    // margin: 0 8px;
  }

  &.is-stuck {
    z-index: 9001 !important;
  }

  .order-step {
    padding: 0;
  }

  .disclaimer {
    color: $white;
    font-size: 12px;
    line-height: 13px;
    display: block;
    margin-top: 7px;
  }

  button.next-step.mobile {
    padding: 10px 8px;
    font-size: 16px;
    line-height: 16px;
    margin: 0;
    width: 100%;
  }

  label[for="termsconditions-mobile"] {
    color: $white;
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 16px;
    input {
      margin-bottom: 10px;
    }
    a {
      color: $white;
      text-decoration: underline;
      &:hover, &:focus {
        text-decoration: none;
      }
    }
  }

  table.unstriped tbody,
  table.unstriped tbody tr {
    background-color: transparent;
  }

  .accordion.price-detail {
    margin: 0;

    .accordion-content {
      background-color: $slate;
      padding: 36px 20px 20px;

      .disclaimer {
        margin: 0;
      }
    }

    h2 {
      font-size: 14px;
      line-height: 18px;
      font-weight: 500;
      color: #00355C;
      margin: 0;
    }

    table.unstriped tbody tr {
      border: 0;
    }

    table {
      margin-bottom: 10px;
    }
    tbody {
      border: 0;
    }
    table.product-selection-details tbody tr {
      display: flex;
      border: 0;
      border-top: 1px solid #BFBFBF;
      &:first-child {
        border-top: 0;
      }
    }
    table.pricing-table tbody tr {
      border-bottom: 1px solid #BFBFBF;
      &:last-child {
        border-bottom: 0;
      }
    }

    th, td {
      padding: 10px 0;
      flex: 1 1 auto;
    }

    td {
      text-align: right;
    }

    .product-name {
      font-size: 18px;
      line-height: 22px;
      margin: 0;
      font-weight: 500;
    }

    .product-subtitle {
      font-size: 12px;
      line-height: 16px;
      margin: 0;
      color:$purple;
      font-weight: normal;

      &.strong {
        font-weight: bold;
      }

      &.green {
        color: $purple;
      }
    }

    .pricing-name {
      font-size: 14px;
      line-height: 18px;
      margin: 0;
      font-weight: 500;
    }

    .old-price {
      color: $purple;
    }

    .strikethrough {
      text-decoration: line-through;
    }

    .product-row-price {
      font-size: 20px;
      line-height: 26px;
      font-weight: 500;
    }

    .pricing-row-price {
      font-size: 16px;
      line-height: 18px;
      font-weight: 500;
    }
  }

  .expand-prices {
    position: absolute;
    bottom: calc(100% - 1px);
    left: 0;
    background-color: $lumos-purple;
    display: block;
    width: 100%;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    text-align: center;
    border-top: 2px solid $white;

    .expand-button {
      display: inline-block;
      color: $white;
      font-size: 12px;
      line-height: 12px;
      padding: 9px 16px 9px 20px;

      @include breakpoint(medium) {
        font-size: 16px;
        line-height: 16px;
      }

      &.closed {
        &:after {
          transform: rotate(180deg);
        }
      }

      &:after {
        content: '';
        display: inline-block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 6px 4px 0 4px;
        border-color: $white transparent transparent transparent;
        margin-left: 10px;
        transform: rotate(0deg);
      }
    }
  }

  .product-alert {
    position: relative;
    font-size: 12px;
    line-height: 20px;
    padding: 10px 20px 10px 40px;
    color: $alert-red;
    min-height: 40px;
    background-color: $white;
    margin: 0 0 10px;
    border-radius: 5px;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 5px;
      transform: translateY(-50%);
      display: block;
      width: 30px;
      height: 27px;

      background-image: url(../images/icon-product-alert.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% 100%;
    }
  }

  .price-container {
    position: relative;
    border: 0;
    background-color: transparent;
    padding: 6px 20px 16px 20px;
    margin-bottom: 0;

    &:after {
      content: '';
      position: absolute;
      top: calc(100% - 1px);
      left: 0;
      display: block;
      width: 100%;
      height: 17px;
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
      background-color: $lumos-purple;
    }

    .big-price {
      font-size: 24px;
      line-height: 28px;
      color: $vivid-violet;
      @include breakpoint(medium) {
        font-size: 46px;
        line-height: 50px;
      }
      sup {
        font-size: 20px;
      }
    }

    .subtitle {
      font-size: 14px;
      line-height: 16px;
    }

  }
} // end .mobile-bottom-price-new

.credit-check-pay {
  margin: rem-calc(20) 0;
  h5 {
    font-size: rem-calc(16);
    color: $light-blue;
  }
  .tabs {
    display: flex;
    justify-content: center;
    border: 0;
  }
  .tabs-title {
    float: none;
    top: 1px;
    position: relative;
    a {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    &.is-active {
      border: rem-calc(1) solid $bubble-border;
      border-bottom: none;
      background-color: $bubble-blue;
      border-top-left-radius: rem-calc(5);
      border-top-right-radius: rem-calc(5);

      a {
        background-color: transparent;
        outline: none;
      }
    }
  }

  .tabs-content {
    border: rem-calc(1) solid $bubble-border;
    background-color: $bubble-blue;
    border-radius: rem-calc(5);
    padding-top: rem-calc(30);
    padding-bottom: rem-calc(30);
  }

  input {
    border-color: $dark-gray;
    &[type="checkbox"], &[type="radio"] {
      margin-bottom: 0;
    }
  }

  .helpButton {
    display: inline-block;
    font-size: .75rem;
    line-height: .875rem;
    font-weight: 700;
    width: 1rem;
    text-align: center;
    color: $middle-blue;
    border: .0625rem solid $middle-blue;
    border-radius: 50%;
    margin-bottom: rem-calc(16);
  }
}

.schedule-installation {
  border: rem-calc(1) solid $bubble-border;
  border-radius: rem-calc(20);
  background-color: $bubble-blue;
  padding: rem-calc(30) 5% rem-calc(30);

  @include breakpoint(small down) {
    border-radius: rem-calc(5);
  }


}

#orderSubmitModal, #orderSubmitModal-nonapi {
  padding-top: rem-calc(40);
  padding-bottom: rem-calc(40);
  h2 {
    font-family: $body-font-faimly-header;
    font-weight: normal;
    font-size: rem-calc(34);
    line-height: rem-calc(45);
    color: $lumos-purple;
    // text-transform: uppercase;
    margin: rem-calc(10) 0 rem-calc(20);
  }
  .button {
    margin-top: rem-calc(30);
    margin-bottom: 0;
    min-width: 25%;
  }

  .submit-error {
    border: rem-calc(1) solid $alert-red-2;
    border-radius: rem-calc(5);
    padding: rem-calc(20) 5%;
    color: $alert-red-2;
  }

  .submit-message {
    font-size: rem-calc(18);
  }
}

.progress-order {
  width: 100%;
  padding: 4px;
  margin: rem-calc(20) auto;

  .progress-bar {
    width: 0%;
    height: 16px;
    border-radius: 4px;
    background-color: $alert-yellow;
  	background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));
    transition: 1.4s linear 0.5s;
    transition-property: width, background-color;
    animation: colorFlashYellow 0.6s alternate infinite;
    // box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.25), inset 0 1px rgba(255, 255, 255, 0.1);
  }

  &[data-percent='0'] {
    .progress-bar {
      width: 0%;
    }
  }

  &[data-percent='25'] {
    .progress-bar {
      width: 25%;
    }
  }

  &[data-percent='50'] {
    .progress-bar {
      width: 50%;
    }
  }

  &[data-percent='75'] {
    .progress-bar {
      width: 75%;
    }
  }

  &[data-percent='100'] {
    .progress-bar {
      width: 100%;
      background-color: $alert-green;
      animation: none;
    }
  }

  &.error {
    .progress-bar {
      background-color: $alert-red;
      animation: none;
    }
  }

  &.success {
    animation: none;
    .progress-bar {
      background-color: $alert-green;
    }
  }

  .submission-indicator {
    list-style: none;
    margin: 0 0 rem-calc(20) 0;

    &:after {
      content: '';
      display: block;
      clear: both;
    }

    li {
      position: relative;
      display: inline-block;
      width: 25%;
      float: left;
      text-align: center;
      font-size: rem-calc(16);
      font-weight: bold;

      transition: all 0.4s ease;

      span {
        position: relative;
        display: inline-block;
        background-color: $white;
        font-weight: normal;
        width: rem-calc(72);
        height: rem-calc(72);
        text-align: center;
        color: $form-gray;
        font-size: rem-calc(36);
        line-height: rem-calc(72);
        border: rem-calc(2) solid $form-gray;
        border-radius: 50%;
        margin: 0 0 rem-calc(20) 0;

        @include breakpoint(small down) {
          width: rem-calc(50);
          height: rem-calc(50);
          font-size: rem-calc(24);
          line-height: rem-calc(50);
        }
      }

      p {
        font-size: rem-calc(16);
        line-height: rem-calc(24);
        color: $form-gray;

        @include breakpoint(small down) {
          font-size: rem-calc(13);
          line-height: rem-calc(18);
        }
      }

      &:after {
        content: '';
        position: absolute;
        top: rem-calc(36);
        left: 100%;
        transform: translateX(-50%);
        width: calc(100% - 85px);
        border-bottom: rem-calc(4) dotted $form-gray;

        @include breakpoint(small down) {
          top: rem-calc(25);
          width: calc(100% - 60px);
        }
      }

      &.current {
        span {
          animation: grayToBlue 0.65s infinite linear alternate;
          &:before {
            content: '';
            position: absolute;
            top: -2px;
            left: -2px;
            width: rem-calc(72);
            height: rem-calc(72);
            border-top: rem-calc(4) solid transparent;
            border-left: rem-calc(4) solid $purple;
            border-radius: 50%;
            animation: spinner 1.1s infinite linear;

            @include breakpoint(small down) {
              width: rem-calc(50);
              height: rem-calc(50);
            }
          }
        }

        p {
          animation: grayToBlue 0.65s infinite linear alternate;
        }
      }

      &.complete {
        span {
          border-color: $purple;
          color: $lumos-purple;
          &:after {
            content: '\f00c';
            font-family: 'Font Awesome 5 Free';
            font-weight: 900;
            font-size: rem-calc(13);
            line-height: rem-calc(24);
            color: $white;
            position: absolute;
            top: -5px;
            right: -5px;
            display: block;
            width: rem-calc(34);
            height: rem-calc(34);
            background-color: $ns-green;
            border-radius: rem-calc(50%);
            border: rem-calc(5) solid $white;

            @include breakpoint(small down) {
              width: rem-calc(23);
              height: rem-calc(23);
              font-size: rem-calc(7);
              line-height: rem-calc(14);
            }
          }
        }
        p {
          color: $lumos-purple;
        }

        &:after {
          border-color: $purple;
        }
      }

      &.alert {
        span {
          border-color: $purple;
          color: $lumos-purple;
          &:after {
            content: '\f12a';
            font-family: 'Font Awesome 5 Free';
            font-weight: 900;
            font-size: rem-calc(13);
            line-height: rem-calc(24);
            position: absolute;
            top: -5px;
            right: -5px;
            display: block;
            width: rem-calc(34);
            height: rem-calc(34);
            background-color: $ns-yellow;
            border-radius: rem-calc(50%);
            border: rem-calc(5) solid $white;

            @include breakpoint(small down) {
              width: rem-calc(23);
              height: rem-calc(23);
              font-size: rem-calc(7);
              line-height: rem-calc(14);
            }
          }
        }

        p {
          color: $lumos-purple;
        }
      }

      &.error {
        span {
          border-color: $purple;
          color: $lumos-purple;
          &:after {
            content: '\f00d';
            font-family: 'Font Awesome 5 Free';
            font-weight: 900;
            font-size: rem-calc(13);
            line-height: rem-calc(24);
            position: absolute;
            top: -5px;
            right: -5px;
            display: block;
            width: rem-calc(34);
            height: rem-calc(34);
            background-color: $alert-red-2;
            color: $white;
            border-radius: rem-calc(50%);
            border: rem-calc(5) solid $white;

            @include breakpoint(small down) {
              width: rem-calc(23);
              height: rem-calc(23);
              font-size: rem-calc(7);
              line-height: rem-calc(14);
            }
          }
        }

        p {
          color: $lumos-purple;
        }
      }

      &:last-child {
        &:after {
          display: none;
        }
      }

      // &:before {
        // content: '';
        // color: $white;
        // font-family: 'Font Awesome 5 Free';
        // font-weight: 900;
        // font-size: rem-calc(30);
        // line-height: rem-calc(51);
        // display: block;
        // width: rem-calc(60);
        // height: rem-calc(60);
        // background-color: $white;
        // border: rem-calc(2) solid $purple;
        // border-radius: 50%;
        // margin: 0 auto;
        //
        // transition: all 0.4s ease;
      // }

      // &:after {
        // content: '';
        // display: block;
        // position: absolute;
        // width: 100%;
        // top: rem-calc(28);
        // right: 50%;
        // border-top: rem-calc(5) dotted $purple;
        // z-index: -1;
        //
        // transition: all 0.4s ease;
      // }

      // &.current {
      //   &:before {
      //     // content: "\f00c";
      //     background-color: $white;
      //
      //     border-top: rem-calc(5) solid $ns-light-blue;
      //     border-right: rem-calc(5) solid $ns-dark-blue;
      //     border-bottom: rem-calc(5) solid $ns-yellow;
      //     border-left: rem-calc(5) solid $ns-green;
      //
      //     animation: spinner 1.1s infinite linear;
      //   }
      //   &:after {
      //     border-color: $text-gray;
      //   }
      // }
      //
      // &.complete {
      //   &:before {
      //     content: "\f00c";
      //     background-color: $alert-green;
      //     border-color: $white;
      //     animation: none;
      //   }
      //   &:after {
      //     border-color: $alert-green;
      //   }
      // }

      // &.error {
      //   &:before {
      //     content: "\f00d";
      //     background-color: $alert-red;
      //     border-color: $white;
      //     animation: none;
      //   }
      //   &:after {
      //     border-color: $text-gray;
      //   }
      // }

      // &:hover {
      //
      // }
      //
      // &:first-child {
      //   &:after {
      //     display: none;
      //   }
      // }
    }
  }

  &.current {
    &:before {
      content: "\f00c";
      background-color: $text-gray;
    }
    &:after {
      border-color: $text-gray;
    }
  }

  &.complete {
    &:before {
      background-color: $middle-blue;
    }
    &:after {
      border-color: $text-gray;
    }
    &:hover {
      &:before {
        background-color: $text-gray;
      }
    }
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes grayToBlue {
  0% {
    color: $form-gray;
    border-color: $form-gray;
  }
  100% {
    color: $purple;
    border-color: $purple;
  }
}

@keyframes colorFlashYellow {
  0% {
    background-color: $alert-yellow;
  }
  100% {
    background-color: lighten($alert-yellow, 15%);
  }
}

.submission_logo {
  img {
    display: inline-block;
    max-width: 400px;
  }
}

@include breakpoint(large down) {
  .product-row {
    &.promotion {
      .button {
        margin: rem-calc(16) 0;
        width: 100%;
      }
    }
  }
}

@include breakpoint(medium down) {
  .fiber-sort {
    h1 {
      font-size: rem-calc(32);
      color: $purple;
    }
    h5 {
      font-size: rem-calc(16);
      font-weight: bold;
      margin: 0 0 rem-calc(10) 0;
    }
    fieldset {
      padding: rem-calc(10);
      margin-bottom: rem-calc(10);
    }
    label {
      float: left;
      width: 31%;
      text-align: left;
      margin: 0 1%;
      padding: rem-calc(10) rem-calc(5);
      input {
        margin: 0 rem-calc(5) 0 0;
      }
    }
    select {
      text-align: center;
    }
    fieldset {
      &.middle-fieldset {
        margin: 0 0 rem-calc(10);
        label {
          text-align: center;
        }
      }
    }
  }

  .product-info {
    border-right: 0;

    .features {
      font-size: rem-calc(16);
      padding: rem-calc(20) rem-calc(0);
    }

    .accordion {
      .accordion-title {
        font-size: rem-calc(13);
        padding: rem-calc(10) 0 rem-calc(10);
        margin-right: 0;
        &:before {
          right: 0;
          font-size: rem-calc(16);
        }
      }
    }
  }

  .product-select {
    padding-left: 0;
  }

  .product-wrap {
    margin-bottom: 16px;
    @include breakpoint(medium) {
      margin-bottom: rem-calc(60);
    }
    p {
      margin: 0 0 rem-calc(15);
    }

    &.product-select {
      label {
        input,select {
        }
        select {
          // padding: 0 1.25rem 0 .25rem;
          margin: 0 .25rem 0 0;
        }
        .feature-desc {
          width: 45%;
          font-size: rem-calc(13);
          &.fullwidth {
            width: calc(100% - 50px);
          }
        }
        .feature-price {
          width: 40%;
          text-align: right;
        }
      }
    }

  }

  .speed-guide {
    div {
      width: 100%;
    }
    .close-button {
      span {
        color: $text-gray;
      }

    }
  }

  .order-step-indicator {
    margin-bottom: 0;
  }

  .order-step {
    .product-select {
      padding: rem-calc(15);
    }
  }

  .order-disclaimer {
    margin-top: 0;
    margin-bottom: rem-calc(60);
  }

  .order-step {
    padding-top: rem-calc(30);
    padding-bottom: rem-calc(30);
  }

  .order-selection {
    h1 {
      text-align: center;
      margin-bottom: rem-calc(30);
    }
  }

  label[for="address_service_billing_same"],
  label[for="address_service_billing_diff"] {
    text-indent: rem-calc(-25);
    margin-left: rem-calc(25);
    line-height: rem-calc(20);
    margin-bottom: rem-calc(10);
  }
}

@include breakpoint(small down) {
  .order-selection {
    h1 {
      font-size: rem-calc(32);
      line-height: rem-calc(38);
    }
  }

  .product-wrap.address-entry {
    .grid-container {
      .grid-x {
        .cell {
          padding: 0;
        }
      }
    }
  }
}
