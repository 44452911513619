.callout-block {
  background-color: $dark-blue;
  background-position: center center;
  background-size: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  padding: rem-calc(70) 0;
}

.grid-x > .cell.callout-body {
  padding-left: 15%;
  padding-right: 15%;
}

.callout-body {
  text-align: center;
  color: $white;
  text-shadow: 0px 0px 5px rgba($black, 0.8);

  h2 {
    font-size: rem-calc(34);
    font-weight: 500;
    margin-bottom: rem-calc(20);
  }

  p {
    text-align: left;
  }

  .button {
    position: relative;
    width: 100%;
    max-width: rem-calc(340);
    margin-top: rem-calc(20);
    margin-bottom: 0;
    padding-right: rem-calc(40);
    font-size: rem-calc(16);
    line-height: rem-calc(16);
    text-shadow: none;

    &:after {
      content: '\f061';
      font-family: 'Font Awesome 5 Free';
      font-weight: bold;
      position: absolute;
      top: 50%;
      right: rem-calc(20);
      transform: translateY(-50%);
    }

    &.hollow {
      color: $white;
      border-color: $light-blue;

      &:hover {
        background-color: rgba($white, 0.05);
      }
    }
  }
}

@include breakpoint(medium down) {
  .grid-x > .cell.callout-body {
    padding-left: 10%;
    padding-right: 10%;
  }
}

@include breakpoint(small down) {
  .grid-x > .cell.callout-body {
    padding-left: 5%;
    padding-right: 5%;
  }
}
