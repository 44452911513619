.faq-block {
  background-color: #f7f7f7;

  padding: rem-calc(50) rem-calc(20);
  @include breakpoint(large) {
    padding: rem-calc(100) 0;
  }

  h2 {
    font-family: $body-font-faimly-header;
    font-size: rem-calc(24);
    line-height: rem-calc(26);
    margin-bottom: rem-calc(16);
  }

  .accordion {
    background-color: transparent;
    @include breakpoint(medium) {
      margin: 0 25px;
    }
  }
  .accordion-title {
    background-color: transparent;
    border: 0;
    font-size: rem-calc(22);
    line-height: rem-calc(26);
    color: $text-gray;
    padding-left: rem-calc(30);

    &:hover {
      // background-color: $light-blue-2;
    }

    &:before {
      position: absolute;
      top: auto;
      left: 0;
      right: auto;
      margin-top: 0;
      font-weight: bold;
      padding-right: rem-calc(16);
      display: inline-block;
      text-indent: 0;
    }
  }
  .accordion-content {
    background-color: transparent;
    border: 0;
    padding: 0 rem-calc(30) rem-calc(30);
  }
}
