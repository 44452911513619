.customer-alert {
  position: fixed;
  width: 100%;
  background-color: $black;
  color: $white;
  font-size: rem-calc(16);
  line-height: rem-calc(20);
  font-weight: bold;
  z-index: 1000;
  padding: 0;
  text-align: center;

  a:not(.button) {
    color: $white;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }

  &.open {
    .customer-alert-content {
      height: auto;
      padding: rem-calc(8) 5%;
      -ms-overflow-style: none;
      overflow: auto;
    }
    .alert-open {
      top: 0%;
    }
  }

  .customer-alert-content {
    position: relative;
    background-color: $black;
    height: 0;
    padding: 0 5%;
    -ms-overflow-style: none;
    overflow: hidden;
    z-index: 1;
  }

  .button.hollow.white {
    margin: 0 2%;
    padding: rem-calc(5) rem-calc(15);

    &:hover {
      background-color: $white;
      color: $black;
      border-color: $white;
    }
  }

  .alert-close {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    color: $white;
    font-size: rem-calc(15);
    line-height: 28px;
    display: block;
    width: rem-calc(27);
    height: rem-calc(27);
    border: rem-calc(1) solid $white;
    border-radius: 50%;
    text-align: center;
    transition: all 0.2s ease;

    &:hover {
      color: $black;
      background-color: $white;
      border-color: $white;
    }
  }

  .alert-open {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: $black;
    color: $white;
    display: block;
    width: 25%;
    font-size: rem-calc(10);
    line-height: rem-calc(10);
    padding-top: rem-calc(10);
    margin-top: rem-calc(-10);
    border-radius: 0 0 rem-calc(10) rem-calc(10);
    transition: margin 0.2s ease;

    // &:before {
    //   content: '';
    //   position: absolute;
    //   left: 0;
    //   bottom: 100%;
    //   display: block;
    //   width: 100%;
    //   height: rem-calc(10);
    //   background-color: $alert-red;
    // }

    &:hover {
      margin-top: 0;
      padding-bottom: rem-calc(2);
      // transform: translateX(-50%) translateY(rem-calc(5));
    }
  }
}
