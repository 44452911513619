.services-block {
  position: relative;
  background-color: $super-light-gray;
  padding: rem-calc(70) 0;
  text-align: center;

  h2 {
    font-size: rem-calc(34);
    line-height: rem-calc(44);
    color: $dark-blue;
    margin-bottom: rem-calc(60);
    margin-right: rem-calc(16);
    margin-left: rem-calc(16);
  }

  .services-button {
    display: flex;
    justify-content: center;
    width: 105%;
    margin-left: -2.5%;

    .hidden-preload {
      display: none;
    }

    .button {
      width: 100%;
      position: relative;
      text-align: left;
      font-size: rem-calc(24);
      line-height: rem-calc(24);
      padding: rem-calc(28);
      margin: 0 rem-calc(15);
      background-color: $middle-blue;
      border: rem-calc(2) solid $middle-blue;

      transition: all 0.2s ease;

      span {
        position: absolute;
        top: calc(100% - 25px);
        left: -2px;
        display: block;
        width: calc(100% + 4px);
        text-align: left;
        font-size: rem-calc(16);
        line-height: rem-calc(24);
        font-weight: normal;
        color: $middle-blue;
        padding: 0 rem-calc(28);
        background-color: $middle-blue;

        border-bottom-left-radius: $global-radius;
        border-bottom-right-radius: $global-radius;

        height: auto;
        max-height: 0;
        -ms-overflow-style: none;
        overflow: hidden;
        border: rem-calc(0) solid $middle-blue;
        border-top: 0;

        z-index: 1;

        transition: all 0.2s ease;
      }

      &:after {
        position: absolute;
        top: 50%;
        right: rem-calc(20);
        transform: translateY(-50%);
        content: '\f061';
        font-family: 'Font Awesome 5 Free';
        font-weight: bold;
        line-height: rem-calc(38);
        width: rem-calc(38);
        text-align: center;
        border: rem-calc(1) solid $white;
        border-radius: 50%;
        z-index: 1;

        transition: all 0.2s ease;
      }

      &:hover {
        color: $dark-blue-4;

        background-color: $white;

        &:after {
          color: $middle-blue;
          border-color: $middle-blue;
        }

        span {
          padding: rem-calc(28);
          height: auto;
          max-height: 400px;
          border-width: rem-calc(2);
          -ms-overflow-style: none;
          overflow: hidden;
          background-color: $white;
          color: $text-gray;
        }
      }
    }
  }

  .services-photo {
    width: 97%;
    border-radius: $global-radius;
    padding-bottom: 38%;
    margin: rem-calc(30) auto;
    -ms-overflow-style: none;
    overflow: hidden;

    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    background-size: cover;

    transition: background-image 0.2s ease-in-out;
  }
}

@include breakpoint(medium down) {
  .services-block {
    .services-button {
      .button {
        font-size: rem-calc(20);
        line-height: rem-calc(24);

        &:hover {
          color: $white;
          background-color: $dark-blue-4;

          &:after {
            color: $white;
            border-color: $white;
          }

          span {
            display:none;
          }
        }
      }
    }
  }
}

@include breakpoint(small down) {
  .services-block {
    .services-button {
      display: block;
      width: 100%;
      margin-left: 0;
      .button {
        width: 100%;
        margin: 0 0 rem-calc(10);
      }
    }
    .services-photo {
      display: none;
    }
  }
}
