.submenublock {
  position: fixed;
  width: 100%;
  background-color: $middle-blue;
  z-index: 998;

  .menu {
    justify-content: center;
    li {
      padding: 0 rem-calc(4);
    }
    a {
      position: relative;
      color: $white;
      font-size: rem-calc(16);
      line-height: rem-calc(36);
      font-weight: bold;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        width: 100%;
        border-bottom: rem-calc(5) solid $white;
        opacity: 0;
        transition: all 0.2s ease;
      }

      &:hover {
        &:after {
          opacity: 1;
        }
      }
    }
  }
}
