.business-product-description {
  padding: rem-calc(70) 5% 0;
  background-size: 550px 550px;
  background-position: -100px -100px;
  background-repeat: no-repeat;

  .business-product-description-content {
    padding-right: rem-calc(70);
  }

  h2 {
    color: $lumos-purple;
    font-size: rem-calc(42);
    line-height: rem-calc(53);
    margin-bottom: rem-calc(60);
    font-weight: bold;
  }

  h3 {
    color: $lumos-purple;
    font-size: rem-calc(24);
    line-height: rem-calc(34);
    margin-bottom: rem-calc(30);
    margin-top: rem-calc(30);
  }

  ul {
    border-left: rem-calc(1) solid $lumos-purple;
    margin-left: rem-calc(40);
    padding-left: 12%;
    list-style: none;

    li {
      position: relative;
      &:before {
        position: absolute;
        top: 0;
        left: -6%;
        content: "\2022";
        color: $vivid-violet;
        font-weight: bold;
        display: block;
        width: 1em;
        margin-left: 0;
      }
    }
  }

  .testimonial {
    position: relative;
    margin-bottom: rem-calc(30);
  }

  .testimonial-bubble {
    position: relative;
    left: 0;
    top: 0;
    width: 140%;
    background-color: $lumos-purple;
    border-radius: rem-calc(10);
    color: $white;
    padding: rem-calc(30);
    padding-right: rem-calc(40);
    margin-bottom: rem-calc(55);

    &:after {
      content: '';
      position: absolute;
      left: rem-calc(30);
      top: 100%;
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 44px 44px 0;
      border-color: transparent $lumos-purple transparent transparent;
    }
  }

  .testimonial-customer {
    span {
      display: block;
      padding-left: rem-calc(30);
    }
    .name {
      font-size: rem-calc(16);
      line-height: rem-calc(24);
      font-weight: bold;
      text-transform: uppercase;
    }
    .jobtitle {
      font-size: rem-calc(13);
      line-height: rem-calc(20);
    }
  }

  .followup-image {
    margin-bottom: rem-calc(30);
  }
}

.business-product-banner {
  padding: rem-calc(35) 0;
  .light-blue {
    position: relative;
    background-color: $vivid-violet;
    min-height: rem-calc(220);
    padding: rem-calc(50) rem-calc(50);

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 100%;
      display: block;
      width: 50vw;
      height: 100%;
      background-color: $vivid-violet;
    }

    .price-container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      text-align: center;
      padding: rem-calc(50) rem-calc(50);
    }

    p {
      display: inline-block;
      vertical-align: middle;
      margin: 0 rem-calc(20) 0;
      color: $white;
      font-size: rem-calc(24);
      line-height: rem-calc(42);
    }
    .price {
      display: inline-block;
      vertical-align: middle;
      font-size: rem-calc(36);
      line-height: rem-calc(42);
      color: $lumos-purple;
      span {
        font-size: rem-calc(72);
        line-height: rem-calc(72);
      }
      sup {
        top: -1.0em;
      }
    }
  }

  .dark-blue {
    position:relative;
    background-color: $purple;
    padding: rem-calc(50) rem-calc(50);
    color: $white;
    p {
      font-size: rem-calc(24);
      line-height: rem-calc(34);
      margin-bottom: 0;
    }

    a {
      color: $white;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 100%;
      display: block;
      width: 50vw;
      height: 100%;
      background-color: $purple;
    }
  }
}

@include breakpoint(medium down) {
  .business-product-description {
    .business-product-description-content {
      padding-right: 0;
      padding-bottom: rem-calc(35);
    }

    .testimonial-bubble {
      width: 100%;
      padding-right: rem-calc(30);
    }

    .testimonial-customer {
      text-align: center;
    }
  }

  .business-product-banner {
    .dark-blue, .light-blue {
      &:before {
        display: none;
      }
    }

    .light-blue {
      p {
        display: block;
      }
    }

    .dark-blue {
      p {
        font-size: rem-calc(22);
        line-height: rem-calc(34);
      }
    }
  }
}
