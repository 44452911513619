.archive {
  .sortbyheader {
    text-transform: uppercase;
    font-size: rem-calc(16);
    line-height: rem-calc(22);
    font-weight: bold;
  }
  .select-wrap {
    width: 45%;
    display: inline-block;
    margin: 0 rem-calc(5) 0;
  }
}

@include breakpoint(small down) {
  .archive {
    .select-wrap {
      width: 100%;
      margin: rem-calc(5) 0 rem-calc(5);
    }

    .followus {
      margin: rem-calc(20) auto 0;
    }
  }
}
