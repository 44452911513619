.fiber-speed-feature {
  position: relative;
  background-color: $medium-light-gray;
  background-image: linear-gradient(to bottom, rgba($fade-to-blue, 0)  0%, rgba($fade-to-blue, 0) 63%, rgba($fade-to-blue, 0.5) 100%);
  padding: rem-calc(40) 0 0;

  h2 {
    color: $dark-blue;
    font-size: rem-calc(34);
    line-height: rem-calc(46);
    margin: 0;
  }

  p {
    font-size: rem-calc(16);
    line-height: rem-calc(24);
    margin-bottom: rem-calc(20);
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: rem-calc(-30);
    display: block;
    width: 50vw;
    height: rem-calc(30);
    background-color: $medium-light-gray;
  }

  .phonescreen {
    position: absolute;
    top: rem-calc(18);
    left: 50%;
    transform: translateX(-50%);
    -ms-overflow-style: none;
    overflow: hidden;

    width: rem-calc(193);
    height: rem-calc(412);

    background-color: $dark-blue-4;
    background-image: linear-gradient(to bottom, rgba($dark-blue-6, 1)  0%, rgba($dark-blue-6, 0) 20%, rgba($dark-blue-6, 0) 80%, rgba($dark-blue-6, 1) 100%);
    border-radius: rem-calc(22);

    padding: rem-calc(20);

    .logo {
      display: block;
      width: 100%;
      height: rem-calc(25);
      margin-top: rem-calc(30);

      background-image: url(../images/northstate_logo_trademark.svg);
      background-position: center;
      background-size: auto 100%;
      background-repeat: no-repeat;
    }

    .start-timer {
      // display: none;
      position: relative;
      margin-top: rem-calc(60);
      color: $white;
      background-color: $dark-blue-4;
      text-align: center;
      width: rem-calc(150);
      height: rem-calc(150);
      border: rem-calc(3) solid $ns-light-blue;
      border-radius: 50%;

      cursor: pointer;

      font-size: rem-calc(14);
      line-height: rem-calc(14);
      text-align: center;

      transition: all 0.2s ease;

      span {
        display: block;
        margin: 0 auto;
        font-size: rem-calc(38);
        line-height: rem-calc(38);
        font-weight: bold;
      }

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: rem-calc(150);
        height: rem-calc(150);
        border-radius: 50%;
        background-color: $ns-light-blue;
        opacity: 1;
        z-index: -1;
      }

      &:hover {
        background-color: rgba($dark-blue-4, 0.75);
        // color: $dark-blue-4;
      }

      &:focus {
        &:before {
          animation: phonebuttonclick 1s forwards 1;
        }

      }
    }

    .spinner {
      display: none;
      position: relative;
      margin-top: rem-calc(60);
      color: $white;
      background-color: $dark-blue-4;
      text-align: center;
      width: rem-calc(150);
      height: rem-calc(150);
      // border: rem-calc(3) solid rgba($ns-light-blue, 0.25);
      border-radius: 50%;
      padding-top: rem-calc(30);

      font-size: rem-calc(18);
      line-height: rem-calc(18);
      text-align: center;

      span {
        display: block;
        margin: 0 auto;
        font-size: rem-calc(60);
        line-height: rem-calc(60);
        font-weight: normal;
      }

      .counter {
        position: relative;
      }

      svg {
        position: absolute;
        left: 0;
        top: 0;
      }

      .progress__meter {
          stroke: rgba($ns-light-blue, 0.25);
          fill: $dark-blue-4;
      }

      .progress__value {
          stroke: $ns-light-blue;
          transition: all 1s linear;
          transform: rotate(-90deg);
          transform-origin: 50% 50%;
      }

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: rem-calc(50);
        height: rem-calc(50);
        border-radius: 50%;
        background-color: $ns-green;
        opacity: 1;
        z-index: -1;
      }

      &.complete {
        .progress__value {
          stroke: $ns-green;
        }

        &:before {
          animation: phonebuttonclick 1s forwards 1;
        }
      }

      .spinner-reset {
        position: absolute;
        top: 110%;
        left: 50%;
        transform: translateX(-50%);

        color: $ns-light-blue;
        text-transform: uppercase;
        font-weight: bold;
        font-size: rem-calc(14);
        opacity: 0.5;
        transition: all 0.2s ease;

        cursor: pointer;

        &:hover {
          opacity: 1;
        }
      }

    }
  }

  .phonehand {
    position: relative;
    display: block;
    width: 100%;
    height: 537px;
    margin-top: rem-calc(-20);
    background-image: url(../images/phonegraphic.png);
    background-size: auto 100%;
    background-position: center;
    background-repeat: no-repeat;
  }

  .feature-item {
    position: absolute;
    background-color: rgba($white, 0.75);
    border-width: rem-calc(2);
    border-radius: rem-calc(10);
    border-style: solid;
    border-color: $middle-blue;
    padding: rem-calc(20);
    font-size: 0;
    width: 42%;
    -ms-overflow-style: none;
    overflow: hidden;

    &.color-blue {
      border-color: $middle-blue;
      &:before, .feature-time {
        color: $middle-blue;
      }
    }
    &.color-green {
      border-color: $ns-green;
      &:before, .feature-time {
        color: $ns-green;
      }
    }
    &.color-grey {
      border-color: $medium-gray-3;
      &:before {
        color: $medium-gray-3;
      }
      .feature-time {
        color: $medium-gray-3;
      }
    }

    .feature-progress {
      content: '';
      position: absolute;
      top: 0;
      width: 0%;
      height: 100%;
      background-color: rgba($ns-yellow, 0.4);
      // border-radius: rem-calc(10);
    }

    &.icon-upload {
      &:before {
        content: '\f093';
      }
    }

    &.icon-download {
      &:before {
        content: '\f019';
      }
    }

    &:nth-child(odd) {
      left: 0;
      border-right-width: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      .feature-progress {
        right: 0;
      }

      &:before {
        font-family: 'Font Awesome 5 Free';
        font-weight: bold;
        position: absolute;
        left: rem-calc(28);
        top: 50%;
        transform: translateY(-50%);
        font-size: rem-calc(20);
        z-index: 1;
      }
    }

    &:nth-child(even) {
      right: 0;
      border-left-width: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      .feature-progress {
        left: 0;
      }
      &:before {
        font-family: 'Font Awesome 5 Free';
        font-weight: bold;
        position: absolute;
        right: rem-calc(28);
        top: 50%;
        transform: translateY(-50%);
        font-size: rem-calc(20);
        z-index: 1;
      }
    }

    @for $i from 1 through 4 {
      &:nth-child(#{$i}) {
        top: (20% + (10 * ($i - 1))) ;
      }
    }

    h4 {
      color: $dark-blue;
      font-size: rem-calc(13);
      line-height: rem-calc(13);
      margin: 0;
      text-transform: uppercase;
    }

    h3 {
      color: $dark-blue;
      font-size: rem-calc(28);
      line-height: rem-calc(32);
      margin: 0;
    }
  }

  .feature-time {
    position: relative;
    width: 30%;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    font-size: rem-calc(24);
    line-height: rem-calc(24);
    color: $middle-blue;

    border-right: rem-calc(1) solid $dark-gray;

    span {
      display: block;
      margin: 0 auto;
    }
  }

  .feature-heading {
    position: relative;
    width: 70%;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
  }
}

@keyframes phonebuttonclick {
  0% {
    width: rem-calc(150);
    height: rem-calc(150);
    opacity: 1;
  }
  100% {
    width: rem-calc(600);
    height: rem-calc(600);
    opacity: 0;
  }
}

@include breakpoint(medium down) {
  .fiber-speed-feature {
    h2 {
      font-size: rem-calc(28);
      line-height: rem-calc(36);
    }
    .phonehand {
      margin-top: rem-calc(0);
    }
    .feature-time {
      font-size: rem-calc(18);
      line-height: rem-calc(18);
    }
    .feature-heading {

    }
    .feature-item {
      padding: rem-calc(20) rem-calc(10);
      &:before {
        display: none;
      }
      &:nth-child(odd) {
        padding-right: 8%;
      }

      &:nth-child(even) {
        padding-left: 8%;
      }
      h4 {

      }

      h3 {
        font-size: rem-calc(22);
        line-height: rem-calc(28);
      }
    }
  }
}

@include breakpoint(small down) {
  .fiber-speed-feature {
    padding: rem-calc(20) rem-calc(35) rem-calc(80);
    h2 {
      margin-bottom: rem-calc(30);
    }
    .button {
      width: 100%;
      margin-top: rem-calc(30);
    }
    .phonescreen {
      display: none;
    }
    .phonehand {
      background-image: none;
      height: auto;
    }
    .feature-time {
      width: 20%;
      font-size: rem-calc(20);
      line-height: rem-calc(24);
    }
    .feature-heading {
      width: 80%;
    }
    .feature-item {
      position: relative;
      width: 100%;
      margin-bottom: rem-calc(10);
      padding: rem-calc(10);

      &:nth-child(odd) {
        left: auto;
        border-right-width: rem-calc(2);
        border-top-right-radius: rem-calc(10);
        border-bottom-right-radius: rem-calc(10);
        padding-right: rem-calc(10);
      }

      &:nth-child(even) {
        right: auto;
        border-left-width: rem-calc(2);
        border-top-left-radius: rem-calc(10);
        border-bottom-left-radius: rem-calc(10);
        padding-left: rem-calc(10);
      }

      @for $i from 1 through 4 {
        &:nth-child(#{$i}) {
          top: auto ;
        }
      }

      h4 {
        color: $dark-blue;
        font-size: rem-calc(13);
        line-height: rem-calc(13);
        margin: 0;
        text-transform: uppercase;
      }

      h3 {
        color: $dark-blue;
        font-size: rem-calc(28);
        line-height: rem-calc(32);
        margin: 0;
      }
    }
  }
}
