.exit-intent-popup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  z-index: 9002;
  background-color: rgba($black, 0.5);
  // backdrop-filter: blur(4px);
  opacity: 0;
  pointer-events: none;
  transition: all 0.2s ease;
  z-index: 9999;

  .exit-message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.6);
    background-color: $white;
    padding: 60px 50px;

    display: block;
    width: 100%;
    max-width: 600px;
    // box-shadow: 0px 5px 5px 0px rgba($black, 0.5);

    border-radius: 10px;

    overflow-y: scroll;
    max-height: 100vh;

    transition: all 0.4s ease;

    .button {
      min-width: 220px;
    }
  }

  .exit-message-header {
    font-size: 24px;
    line-height: 35px;
  }

  .exit-message-progress {
    font-size: 18px;
    line-height: 26px;
    max-width: 380px;
    margin: 0 auto 26px;;
  }

  .exit-order-selection {
    border: 1px solid $purple;
    background: linear-gradient(0deg, rgba(176, 3, 211, 0.1), rgba(176, 3, 211, 0.1)), $white;
    border-radius: 6px;
    margin-bottom: 30px;
    padding: 32px;
  }

  .order-savings {
    h3 {
      font-size: 24px;
      line-height: 35px;
      margin: 0;
    }
    p {
      font-size: 14px;
      line-height: 26px;
      &:last-child {
        margin: 0;
      }
    }
  }

  &.visible {
    opacity: 1;
    pointer-events: all;
    .exit-message {
      transform: translate(-50%, -50%) scale(1);
    }
  }

  .close-button {
    font-family: Arial;
    color: $purple;
    display: block;
    width: 40px;
    height: 40px;
    border: 1px solid $purple;
    border-radius: 50%;
    top: 20px;
    right: 20px;
    transition: all 0.2 ease;
    &:hover {
      color: $vivid-violet;
      border-color: $vivid-violet;
    }
  }
}
