.special-color-banner {
  background-color: #00baee;
  color: $white;
  padding: rem-calc(30) 0;

  .cell {
    padding: 0 15px;
  }

  h2 {
    font-family: $body-font-faimly-header;
    font-size: rem-calc(24);
    line-height: rem-calc(26);
  }

  a {
    color: $white;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}
