@mixin input-placeholder {
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder          {@content}
  &:-ms-input-placeholder      {@content}
  &::placeholder {@content}
}

.ns-account-login-block {
  margin: rem-calc(60) 0;
}

.account-screen.account-screen-va {
  .edit-mode {
    .show-for-edit {
      display: block;
    }
    .show-for-display {
      display: none;
    }
  }
  .display-mode {
    .show-for-edit {
      display: none;
    }
    .show-for-display {
      display: block;
    }
  }

  .linked-account-row {
    input {
      margin: 0 !important;
    }
  }

  .linked-account-button-row {
    display: flex;
    align-items: center;
    .button {
      margin-right: 4px !important;
      margin-left: 4px !important;
    }
  }
  form {
    input {
      display: inline-block;
    }
  }

}

.account-summary-va {
  .service-item {
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid rgba($black, 0.25);

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: 0;
    }
  }
}

#ns-account-login, .ns-account-login-block {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  z-index: 9999; // put one in front of nav

  opacity: 0;
  pointer-events: none;
  visibility: hidden;

  transition: opacity 0.2s ease;

  &.ns-login-page button:not(.button) {
    color: $lumos-purple;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }

  .login-page {
    position: relative;
    .loading {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($middle-blue, 0.75);
      z-index: 10;
      i {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: rem-calc(80);
        color: $white;
        animation: spinnerSpin 1s linear infinite;
      }
    }
  }

  &.block {
    position: relative;
    z-indeX: 1;
    min-height: unset;
    opacity: 1;

    .new-account, .login-form, .ns-account-login-overlay {
      pointer-events: all;
    }

    .new-account, .login-form {
      background: transparent;
      color: $text-gray;
      border-color: transparent;

      // a {
      //   color: $text-gray;
      //   &.button {
      //     color: $white;
      //   }
      // }
    }

    .new-account {
      &:before {
        background: transparent;
        border-right: 1px solid $ns-light-blue;
        height: 65%;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    // .button {
    //   color: $white;
    // }
  }

  &.open {
    opacity: 1;
    visibility: visible;

    .new-account, .login-form, .ns-account-login-overlay {
      pointer-events: all;
    }
  }

  .ns-account-login-overlay {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($black, 0);
    z-index: 0;
    pointer-events: none;
  }


  .ns-account-login-form {
    position: relative;
    >.grid-container {
      position: relative;
      background-color: $purple-pastel-2;
      border-radius: 16px;

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: none;
        height: 72%;
        border-right: 1px solid $lumos-purple;

        @include breakpoint(medium) {
          display: block;
        }
      }
    }
  }

  .new-account, .login-form {
    // border-top: rem-calc(2) solid $white;
    color: $lumos-purple;
    // background: linear-gradient(to bottom, rgba($middle-blue, 1) 0%, rgba($middle-blue, 1) 20%, rgba($middle-blue, 0.8) 100%);
    pointer-events: none;
    padding: rem-calc(50) 10%;

    h2 {
      font-size: rem-calc(30);
      line-height: rem-calc(36);
      // margin-bottom: rem-calc(40);
    }

    label {
      color: $lumos-purple;
    }

    a, .page-button {
      color: $lumos-purple;
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        text-decoration: none;
      }
    }

    // .button {
    //   border-color: $ns-light-blue;
    //   color: $lumos-purple;
    //   text-decoration: none;
    //   // margin: 0;
    //   &:hover {
    //     background-color: $ns-light-blue;
    //   }
    // }

    .login-links {
      font-size: rem-calc(13);
      line-height: rem-calc(22);
    }

    .alert {
      a {
        color: $purple;
      }
    }
  }

  .new-account {
    position: relative;
    padding: rem-calc(50) 5%;

    // &:before {
    //   content: '';
    //   position: absolute;
    //   top: 0;
    //   right: 0;
    //   display: block;
    //   width: 20%;
    //   height: 100%;
    //   background: linear-gradient(to right, rgba($lumos-purple-4, 0) 0%,rgba($lumos-purple-4, 0.25) 100%);
    //   pointer-events: none;
    // }

    h2 {
      // border-bottom: rem-calc(1) solid $ns-light-blue;
      // padding-bottom: rem-calc(25);
      // margin-bottom: rem-calc(30);
    }

    p {
      margin-bottom: rem-calc(16);
    }
  }

  .login-form {
    position: relative;
    // background: $middle-blue;
  }

  .callout.alert {
    padding: rem-calc(10);
    font-size: rem-calc(14);
  }

  .is-invalid-input:not(:focus) {
    border-color: $alert-red;
  }

  .form-error, .is-invalid-label {
    color: $lumos-purple;
    text-align: center;
    text-transform: uppercase;
  }
}

.ns-account-login-block .form-error, .ns-account-login-block .is-invalid-label {
  color: $alert-red;
}

.ns-account-login-block {
  .login-page {
    .loading {
      background-color: rgba($light-gray, 0.75);
      i {
        color: $lumos-purple;
      }
    }
  }
}

.show_in_register, .show_in_login {
  display: none !important;
}
div.tokenerr:nth-of-type(2) {
  display: none !important;
}

#utility-nav a[href$="#ns-account-login"] {
  &:focus {
    background-color: $white;
    color: $purple;
    &:hover {
      color: $purple;
    }
  }
}

@include breakpoint( small down ) {
  #ns-account-login, .ns-account-login-block {
    .new-account, .login-form {
      padding: rem-calc(20) 5%;
    }
    .new-account {
      border-top: 0;
      &:before {
        display: none;
      }
    }
  }
}

.statement-summary-table.unstriped {
  border: none;
  margin-top: 40px;
  thead {
    background-color: transparent;
    border: none;
    border-bottom: 2px solid #BFBFBF;
    th {
      text-align: center;
      text-transform: uppercase;
      color: $lumos-purple;
      font-weight: bold;
    }
  }
  tbody {
    border: none;
    tr {
      border-bottom: 1px solid #BFBFBF;
    }
    th {
      text-align: right;
      width: 50%;
      padding-left: 15px;
      padding-right: 15px;
    }
    td {
      text-align: left;
      width: 50%;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

.phone-log-table.unstriped {
  @include breakpoint(large) {
    width: 100%;
  }
  td {
    @include breakpoint(small only) {
      white-space: nowrap;
    }
  }
}

.phone-log-table.unstriped.responsive-card-table {
  thead {
    th {
      background-color: transparent;
    }
  }
  @include breakpoint(small down) {
    tbody {
      tr {
        border: rem-calc(1) solid $text-gray;
        border-top: rem-calc(5) solid $lumos-purple;
        padding: rem-calc(10) 5%;
        &:nth-child(even) {
          background-color: $medium-gray-6;
        }
      }

      td {
        display: block;
        padding-left: 0;
        &:before {
          display: block;
          width: 100%;
          position: relative;
          top: 0;
          left: 0;
          text-align: left;
        }
      }
    }

    &.small {
      tbody {
        td {
          display: block;
          padding-left: 0;
          text-align: left !important;
          &:before {
            display: block;
            width: 100%;
            position: relative;
            top: 0;
            left: 0;
            text-align: left;
          }
        }
      }
    }
  }
  &.small {
    td, th {
      @include breakpoint(small down) {
        padding: rem-calc(8) rem-calc(16) rem-calc(16) 50%;
      }
    }

  }
}

.phone-log-table.unstriped, .account-table.unstriped {
  margin-bottom: 0;
  border: none;
  @include breakpoint(small down) {
    width: 100%;
  }
  &.small {
    font-size: 13px;
    td, th {
      padding: 0.75rem 1.5rem;
    }
  }
  thead {
    border: none;
    background-color: $lumos-purple;
    color: #FFF;
  }
  tbody {
    border: none;
    background-color: transparent;
    tr {
      border: none;
      border-bottom: 1px solid #BFBFBF;
      background-color: transparent;
      &:hover {
        background: linear-gradient(0deg, rgba(176, 3, 211, 0.1), rgba(176, 3, 211, 0.1)), #FFFFFF;
      }
    }
    td {
      &:last-child {
        a {
          font-weight: bold;
        }
      }
    }
  }
}

.confirm-delete, .confirm-addition {
  padding: 30px;

  h2 {
    font-size: 20px;
    line-height: 26px;

    @include breakpoint(medium) {
      font-size: 28px;
      line-height: 34px;
    }
  }

  .lead {
    font-size: rem-calc(16);
    line-height: rem-calc(18);
  }

  // .button {
  //   font-size: 16px;
  //   margin-right: 15px;
  //   &.alert {
  //     border: none;
  //     color: #FFF;
  //   }
  // }
}

.account-table {
  margin-top: 50px;
  tfoot {
    background-color: transparent;
    border: 0;
  }

  td:not([colspan]), th:not([colspan]) {
    width: 33%;
    text-align: left;
  }

  &.emails-table {
    .form-error {
      text-align: left;
    }
    &.unstriped {
      // margin-top: 0;
      // margin-bottom: 0;
    }
    td:not([colspan]), th:not([colspan]) {

      text-align: left;
      &:nth-child(1){
        width: 30%;
      }
      &:nth-child(2){
        width: 20%;
      }
      &:nth-child(3){
        width: 10%;
      }
      &:nth-child(4){
        width: 10%;
      }
      &:nth-child(5){
        width:20%;
      }
      &.action-buttons {
        text-align: right;
      }

      @include breakpoint(medium down) {
        width: 100%;
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
          width: 100%;
          display: block;
        }
      }
    }

    fieldset {
      padding: 0;
      border: 0;
    }

    @include breakpoint(medium down) {
      legend {
        display: block;
      }
    }

    &.stack tfoot {
      display: table-footer-group;
    }

    @include breakpoint(medium down) {
      & > tbody > tr {
        border: 1px solid #bfbfbf;
        border-top: 5px solid #00365c;
      }
    }

  }

  &.unstriped {
    margin-bottom: 100px;
    @include breakpoint(medium) {
      margin-bottom: 50px;
    }
    > tbody > tr {
      &:last-child {
        &:hover {
          background-color: transparent;
        }
      }
      > td {
        padding: 0;
      }
    }
  }
  .action-buttons {
    @include breakpoint(medium) {
      width: 248px;
    }
  }
  table {
    margin-bottom: 0;
    tr {
      border-bottom: none !important;

      td.account-edit-row {
        padding: 0;
        ul {
          margin-top: 0;
          margin-bottom: 0;
          .accordion-content[aria-hidden="false"] {
            margin-bottom: 50px;
          }
        }
      }
    }
  }
  .accordion {
    background-color: transparent;
    margin-top: 18px;
    margin-bottom: 22px;
    .accordion-content {
      background: linear-gradient(0deg, rgba(176, 3, 211, 0.1), rgba(176, 3, 211, 0.1)), #FFFFFF;
      border: 1px solid $purple;
      border-radius: 10px;
      position: relative;
      @include breakpoint(small only) {
        margin-left: -1rem;
        margin-right: -1rem;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
    &.acct-edit-panel .accordion-content {
      @include breakpoint(small only) {
        margin-left: 0;
        margin-right: 0;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
    }
  }
  form {
    margin-left: -15px;
    margin-right: -15px;
    @include breakpoint(medium) {
      padding: 30px 40px;
    }
    input[type="radio"] + label {
      padding: 0 .5625rem;
    }
    .form-actions {
      text-align: center;
      margin-top: 40px;
    }
    // .button {
    //   font-size: 16px;
    //   min-width: 146px;
    // }
    p {
      @include breakpoint(medium) {
        text-align: center;
      }
    }
    label, legend {
      font-size: 16px;
      font-weight: bold;
      color: #444444;
      padding: 0;
      @include breakpoint(medium) {
        margin: 0 0 1rem;
        padding: .5625rem 0;
        text-align: right;
        margin-left: auto;
      }
    }
    input {
      border-color: #888888;
      box-shadow: none;
    }
  }
  .accordion-title {
    border-bottom-color: currentColor !important;
    border-radius: .375rem !important;
    position: relative;
    top: 2px;
    display: block;
    width: 100%;
    max-width: 339px;
    font-size: 16px;
    padding: .85em 1em;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    @include breakpoint(small only) {
      max-width: none;
      width: auto;
    }
    &:before {
      content: '';
    }
    &:after, .icon {
      transition: 250ms ease;
    }
    &:after {
      content: '\00D7';
      float: right;
      display: inline-block;
      font-size: 30px;
      color: #888888;
      line-height: 18px;
      opacity: 0;
    }
    .icon {
      opacity: 1;
      float: left;
    }
    &[aria-expanded="true"] {
      border-color: $purple !important;
      border-bottom: none !important;
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      background: linear-gradient(0deg, rgba(176, 3, 211, 0.1), rgba(176, 3, 211, 0.1)), #FFFFFF;
      z-index: 1;
      @include breakpoint(small only) {
        margin-left: -1rem;
        margin-right: -1rem;
        max-width: none;
        width: auto;
        border-top-right-radius: 10px !important;
        border-top-left-radius: 10px !important;
      }
      &:after {
        opacity: 1;
      }
      .icon {
        opacity: 0;
      }
    }
  }
  .action {
    color: $purple;
    font-size: 13px;
    font-weight: bold;
    margin-left: 20px;
    cursor: pointer;
    .icon {
      display: none;
    }
  }
  .close-btn {
    display: none;
    .icon {
      display: inline-block !important;
      margin-left: 5px;
    }
  }
  // .button {
  //   cursor: pointer;
  // }
  .action-buttons {
    text-align: right;
  }
  .primary-account-row {
    font-weight: bold;
  }
  @include breakpoint(small only) {
    th, td {
      &:nth-child(2) {
        display: none;
      }
    }
    .action-buttons .action {
      .icon {
        display: block;
      }
      .text {
        display: none;
      }
    }
  }
}

.account-details {
  max-width: 711px;
  width: 100%;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
}

.emails-table {
  border: none;
  .action-buttons {
    .action {
      @include breakpoint(medium down) {
        margin-left: 15px;
        vertical-align: middle;
      }
      .icon {
        line-height: 30px;
      }
    }
  }
  thead {
    background-color: #00365C;
    border: none;
    color: #FFF;
  }
  tbody {
    border: none;
    tr, tr:nth-child(2n) {
      border-bottom: 1px solid #BFBFBF;
    }
    tr {
      @include breakpoint(medium down) {
        position: relative;
        margin-bottom: 20px;
        border: 1px solid #BFBFBF;
        border-top: 5px solid #00365C;
        td {
          font-size: 13px;
          &:before {
            display: block;
            font-weight: bold;
            color: #00365C;
          }
          &:first-child:not(.account-edit-row) {
            padding-top: 15px;
            &:before {
              content: 'Email Address';
            }
          }
          &:nth-child(2) {
            &:before {
              content: 'Name';
            }
          }
          &:nth-child(3) {
            &:before {
              content: 'Quota';
            }
          }
          &:nth-last-child(2) {
            padding-bottom: 15px;
            &:before {
              content: 'Status';
            }
          }
        }
      }
    }
  }
  .action-buttons {
    @include breakpoint(medium down) {
      padding: 0;
      position: absolute;
      top: 7px;
      right: 15px;
    }
  }
}

.my-account-inner {
  padding: 50px 60px;
  padding-top: 0;
  @include breakpoint( small down ) {
    padding: 0 10px;
    padding-top: 0;
  }
  .action {
    color: $purple;
    font-size: 13px;
    font-weight: bold;
    margin-left: 20px;
    cursor: pointer;
    .icon {
      display: none;
    }
  }
  .action-buttons {
    text-align: right;
  }
  .primary-account-row {
    font-weight: bold;
  }
  @include breakpoint(medium down) {
    .action-buttons .action {
      .icon {
        display: block;
        &.remove {
          font-size: 30px;
          font-weight: normal;
        }
      }
      .text {
        display: none;
      }
    }
  }
}

.my-account-row {
  margin-top: 40px;
  > .callout {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  &.email-accounts-list {
    > .callout {
      padding-top: 1rem;
      padding-bottom: 1rem;
      @include breakpoint(medium) {
        padding: 50px;
      }
    }
  }
  h2 {
    // color: #004976;
    @include breakpoint(medium) {
      font-size: 24px;
    }
  }
}

// Service page
.service-page-body {
  @extend .article-body;
  margin: 0;
  border-bottom: none;
  &:before {
    background: linear-gradient(45deg,transparent,transparent 50%,#000);
    right: 100%;
  }
  &:after {
    background: linear-gradient(-45deg,transparent,transparent 50%,#000);
    left: 100%;
  }
  &:before, &:after {
    content: "";
    display: block;
    position: absolute;
    top: 1.875rem;
    opacity: .07;
    width: 5.625rem;
    height: 5.625rem;
  }
}

.service-details {
  max-width: 897px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  .service {
    margin-bottom: 15px;
    padding: 30px;
    padding-bottom: 40px;
    border-color: #888888;
    @include breakpoint(small only) {
      padding: 1.75rem 1rem 2.75rem;
    }
  }
  .feature-price {
    float: right;
    font-weight: bold;
  }
  select {
    display: inline-block;
    width: auto;
    margin-right: rem-calc(8);
  }
  .help-icon {
    width: 16px;
    height: 16px;
    display: inline-block;
    border: 1px solid currentColor;
    border-radius: 100%;
    font-size: 11px;
    font-weight: 900;
    text-align: center;
    line-height: 1.3;
    color: #003F6A;
    vertical-align: middle;
    margin-bottom: 6px;
    margin-left: 0.9em;
  }
  .grid-x {
    justify-content: space-between;
  }
  .service-actions {
    margin-top: 1.5rem;
    border-top: 2px dotted #1ABCED;
    padding-top: 2rem;
    @include breakpoint(large) {
      padding-top: 0;
      border-top: none;
      border-left: 2px dotted #1ABCED;
      width: 251px;
      padding-left: 35px;
      margin-top: 1rem;
      padding-right: 35px;
    }
  }
  .service-action-links {
    margin: 0;
    list-style: none;
    font-weight: bold;
    > li {
      line-height: 1.375;
      margin-bottom: 1rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  p {
    margin-top: 1rem;
    @include breakpoint(small only) {
      text-align: center;
    }
  }
  .service-body {
    > * {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  p.service-subtitle {
    font-size: 20px;
    font-weight: bold;
    text-align: left;
    margin-bottom: 1.5rem;
    margin-top: 0;
    margin-bottom: 0.6rem;
  }
  .service-upgrade-status {
    &, &.accordion-title {
      font-size: rem-calc(16);
      font-weight: bold;
      letter-spacing: 0.01em;
      margin-top: 1rem;
      display: inline-block;
      padding: 0;
      width: 100%;
      border: 0;
      &:hover, &:focus {
        background: transparent;
      }
    }
  }
  .service-title {
    color: #004976;
    font-size: 24px;
    margin-bottom: 1rem;
    @include breakpoint(small only) {
      text-align: center;
    }
  }
  .service-features {
    > li {
      margin-bottom: 0.25rem;
    }
  }
  .service-icon {
    @include breakpoint(small only) {
      margin-bottom: 20px;
    }
    @include breakpoint(medium only) {
      padding-right: 20px;
    }
    img {
      width: 100px;
      margin-right: auto;
      display: block;
      @include breakpoint(small only) {
        margin-left: auto;
      }
    }
  }
}

.feature-columns {
  text-align: center;
  p {
    line-height: 1.375;
  }
  .grid-x {
    margin-left: -30px;
    margin-right: -30px;
  }
  hr {
    margin-top: 30px;
    margin-bottom: 30px;
    border-bottom: 2px dotted #1ABCED;
  }
  .cell {
    margin-top: 40px;
    margin-bottom: 40px;
    padding-left: 30px;
    padding-right: 30px;
    > *:last-child {
      margin-bottom: 0;
    }
  }
  .no-bullet {
    letter-spacing: 0.01em;
    > li {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .column-icon {
    max-width: 100px;
    margin-bottom: 15px;
    border-radius: 100%;
  }
}

// Parental controls page
.page-id-3979 {
  .account-screen {
    .account-header {
      margin-bottom: 40px;
      + hr {
        display: none;
      }
    }
  }
}

// Channel Lineup
.page-template-template-account-channellineup {
  .account-header {
    h1 {
      display: none;
    }
    + hr {
      display: none;
    }
  }
}

// Equipment List
.page-template-template-account-equipmentlist {
  .grid-container {
    &.account-screen, &.my-account-page {
      padding-left: 1rem;
      padding-right: 1rem;
      max-width: 1115px;
    }
  }
  .my-account-page .my-account-inner {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
  }
}

.caps {
  text-transform: uppercase;
}

button.link-button {
  outline: 0;
  @extend a;
  font-weight: bold;
}

.page-template-template-account-automaticpayments,
.page-template-template-account-statementdelivery {
  .row {
    @include breakpoint(medium) {
      margin-top: 1.5rem;
    }
    &:first-child {
      margin-top: 0;
    }
  }
  .align-right-desktop {
    @include breakpoint(medium) {
      text-align: right;
    }
  }
  .my-account-inner {
    @include breakpoint(small only) {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      .callout {
        margin-left: -1rem;
        margin-right: -1rem;
        padding: 1.25rem 1rem;
      }
    }
    .callout {
      padding: 40px 60px;
      &.slim {
        padding: 20px 20px;
      }
    }
    hr {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
  }
}

.margin-below {
  margin-bottom: 1rem;
}

.my-account-page {
  .statement-form {
    // .button {
    //   margin-bottom: 0;
    //   display: block;
    //   width: 100%;
    // }
  }

  .helpButton {
    display: inline-block;
    font-size: rem-calc(12);
    line-height: rem-calc(14);
    font-weight: bold;
    width: rem-calc(16);
    text-align: center;
    color: $purple;
    border: rem-calc(1) solid $purple;
    border-radius: 50%;
    margin-bottom: rem-calc(10);
    &.extramargin {
      margin-bottom: rem-calc(20);
    }
  }

  label {
    font-size: 16px;
    line-height: 1.2;
    // color: #444444;
    @include breakpoint(small only) {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  input[type="text"], input[type="tel"], input[type="number"] {
    border-color: $dark-gray !important;
    box-shadow: none;
    border-width: rem-calc(1);
    border-style: solid;
  }
  input[type="checkbox"], input[type="radio"] {
    width: 18px;
    height: 18px;
    position: relative;
    top: 4px;
    margin-right: 11px;
  }
  input[type="date"] {
    border-color: #888888;
    box-shadow: none;
  }
  .checkbox-wrap {
    min-height: 16px;
    position: relative;
    padding-left: 30px;
    line-height: 1.5;
    @include breakpoint(small only) {
      margin-top: 0;
    }
    input {
      position: absolute;
      left: 0;
      margin: 0;
    }
  }
  // .button {
  //   font-size: 16px;
  // }
  .new-payment-method {
    @include breakpoint(small only) {
      margin-top: 1rem;
      margin-bottom: 0.75rem;
      // .button {
      //   display: block;
      //   width: 100%;
      // }
    }
    @include breakpoint(medium) {
      text-align: right;
    }
  }
  .text-alert {
    color: $purple;
  }
  .payment-buttons, .action-buttons {
    @include breakpoint(small only) {
      margin-top: 2rem;
    }
    // .button {
    //   padding-left: 30px;
    //   padding-right: 30px;
    //   &.save {
    //     min-width: 153px;
    //   }
    // }
    // .button.white {
    //   color: #0074BD;
    //   background-color: transparent;
    //   border-color: transparent;
    // }
  }
  .filter-grid {
    @include breakpoint (medium) {
      margin-right: -1rem;
    }
  }
  .statement-date-label {
    @include breakpoint(medium) {
      padding-right: 15px;
      padding-left: 15px;
    }
  }
  .statement-date-select {
    margin-top: 1rem;
    @include breakpoint(large) {
      margin: 0;
    }
  }
  .callout.primary {
    background: linear-gradient(0deg, rgba(176, 3, 211, 0.1), rgba(176, 3, 211, 0.1)), #FFFFFF;
    border-color: $purple;
  }
  .equipment-list-body {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .equipment-list {
    margin-left: -15px;
    margin-right: -15px;
    .callout {
      height: 100%;
    }
  }
  .status {
    text-transform: uppercase;
    font-weight: bold;
    &.active {
      color: #319B42;
    }
    &.inactive {
      color: #CC0000;
    }
  }
  .equipment-image {
    float: right;
  }
  .equipment-description {
    font-size: 13px;
  }
  .equipment-meta {
    margin-bottom: 0;
    margin-top: 30px;
  }
  .status-wrapper {
    margin-bottom: 20px;
    .status {
      display: inline-block;
      vertical-align: middle;
    }
    img {
      width: 24px;
      margin-left: 0.75em;
    }
  }
  .icon-key {
    list-style: none;
    padding: 0;
    text-align: center;
    margin-left: -20px;
    margin-right: -20px;
    font-weight: bold;
    @include breakpoint(medium) {
      text-align: right;
    }
    img {
      width: 24px;
      vertical-align: middle;
      margin-right: 1em;
    }
    > li {
      display: inline-block;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  .input-small {
    font-size: 13px;
    height: 30px;
    line-height: 29px;
  }
  .inline-select {
    margin-left: 0.5em;
    margin-right: 0.5em;
    display: inline-block;
  }
  .dataTables_length {
    @include breakpoint(small only) {
      text-align: center;
    }
  }
  #ns_tv_listing_table_filter .input-group {
    justify-content: flex-end;
    @include breakpoint(small only) {
      justify-content: center;
    }
  }
  .dataTables_info {
    @include breakpoint(small only) {
      text-align: center;
      margin-top: 1em;
      margin-bottom: 0.5em;
    }
  }
  div.dataTables_wrapper div.dataTables_paginate {
    float: none;
  }
  div.dataTables_wrapper div.dataTables_filter label {
    @include breakpoint(small only) {
      float: none;
    }
  }
  .dataTables_wrapper.dt-foundation {
    .row {
      @extend .grid-x;
      align-items: center;
      margin-top: 30px;
      @include breakpoint(small only) {
        justify-content: center;
      }
    }
  }
  // .downloads .button {
  //   display: block;
  // }
  .filter-label {
    text-transform: uppercase;
    font-size: 13px;
    color: #888888;
    font-weight: bold;
    line-height: 1.3;
    font-family: Overpass,Arial,sans-serif;
    @include breakpoint(small only) {
      text-align: center;
    }
  }
  #ns_tv_listing_table_filter {
    .input-group {
      position: relative;
      .input-group-button {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        button {
          background-color: #0074BD;
          font-size: 90%;
          margin: 3px;
          border-radius: 3px;
          padding: 4px;
          border: none;
          min-width: 24px;
        }
      }
      input {
        min-width: 245.5px;
        border-radius: 5px;
      }
    }
  }
  #ns_tv_listing_table {
    .channel-logo {
      max-width: 36px;
      margin-right: 20px;
    }
    .channel-name-wrapper {
      display: flex;
      align-items: center;
    }
    .channel-name {
      display: inline-block;
    }
    th {
      background-color: #00365C;
      color: #FFF;
      border-right: #fafafa 1px solid;
      background-image: none;
      @include breakpoint(small only) {
        font-size: 13px;
      }
      &:nth-child(3), &:nth-child(4) {
        @include breakpoint(small only) {
          display: none;
        }
      }
      &:after {
        font-family: "Font Awesome 5 Free";
        content:"\f078";
        float: right;
        color: #1ABCED;
        line-height: 25px;
      }
      &[aria-sort="ascending"] {
        &:after {
          font-family: "Font Awesome 5 Free";
          content:"\f077";
        }
      }
    }
    tbody {
      border: none;
      > tr {
       > td {
         padding: 0.9rem 1.9rem;
         border-bottom: 2px solid #BFBFBF;
         @include breakpoint(small only) {
           font-size: 13px;
         }
         &:nth-child(odd) {
           background-color: #FFF;
         }
         &:nth-child(even) {
           background-color: #fafafa;
         }
         &:first-child {
           text-align: center;
           font-weight: bold;
         }
         &:nth-child(3), &:nth-child(4) {
           @include breakpoint(small only) {
             display: none;
           }
         }
       }
      }
    }
  }
  .pagination {
    text-align: center;
    @include breakpoint(medium) {
      text-align: left;
      margin-bottom: 0;
    }
    .total-pages {
      margin-bottom: 15px;
      @include breakpoint(medium) {
        width: 33%;
        vertical-align: middle;
        display: inline-block;
        margin-bottom: 0;
      }
    }
    a {
      display: inline-block;
      vertical-align: middle;
      width: rem-calc(32);
      height: rem-calc(32);
      text-align: center;
      font-size: rem-calc(16);
      font-weight: bold;
      line-height: rem-calc(32);
      border: rem-calc(1) solid $middle-blue;
      color: $middle-blue;
      border-radius: 50%;
      padding: 0;
      margin: 0 rem-calc(5);
      cursor: pointer;
      transition: all 0.2s ease;
      @include breakpoint(large) {
        width: rem-calc(40);
        height: rem-calc(40);
        line-height: rem-calc(40);
        margin: 0 rem-calc(7);
      }

      img {
        width: 55%;
      }

      &.skip-button {
        border-color: $ns-light-blue;
        line-height: rem-calc(28);
        @include breakpoint(large) {
          line-height: rem-calc(35);
        }
        img {
          margin-top: 27%;
        }
      }

      &.current, &:hover {
        border-color: $ns-light-blue;
        background-color: rgba($ns-light-blue, 0.05);
        color: $medium-gray-2;
      }
    }

    .current {
      display: inline-block;
      vertical-align: middle;
      text-align: center;
      font-size: rem-calc(16);
      font-weight: bold;
      border: rem-calc(1) solid $ns-light-blue;
      border-radius: 50%;
      padding: 0;
      background-color: rgba($ns-light-blue, 0.05);
      color: $medium-gray-2;
    }
  }
  .shadow {
    margin: 0;
  }
  .main-col {
    @include breakpoint(medium) {
      padding-right: 15px;
    }
  }
  .sidebar {
    @include breakpoint(medium) {
      padding-left: 15px;
    }
  }
  .breadcrumb-container {
    .breadcrumbs {
      margin: 0;
      padding: 40px 30px;
      padding-bottom: 10px;
      color: #444444;
      @include breakpoint ( medium up ) {
        padding: 20px 0px;
      }
      > li {
        display: none;
        font-size: 13px;
        text-transform: none;
        color: #444444;
        @include breakpoint ( medium up ) {
          display: inline-block;
        }
        &:after {
          content: "\2022";
          color: #444444;
          margin: 0 .5rem;
        }
        &:last-child {
          &:after {
            display: none;
          }
        }
        &:not(.back-link) > a {
          color: #444444;
        }
        &.back-link {
          display: block;
          text-transform: uppercase;
          @include breakpoint( medium up ) {
            display: none;
          }
          &:after {
            display: none;
          }
        }
        > a {
          font-size: inherit;
          font-weight: bold;
        }
      }
    }
  }
  h1 {
    color: $lumos-purple;
    font-size: 32px;
    line-height: 1.2;
    @include breakpoint( medium up ){
      margin-bottom: 1rem;
      font-size: 42px;
      &.mobile {
        display: none;
      }
    }
    @include breakpoint( small down ) {
      &.desktop {
        display: none;
      }
    }
  }
  h4 {
    // color: $lumos-purple;
    font-weight: normal;
    @include breakpoint( small down ) {
      font-size: 24px;
    }
  }
  .my-account-inner {
    padding: 0px 60px 50px;
    @include breakpoint( small down ) {
      padding: 0 10px;
    }
  }
  .account-details {
    max-width: 711px;
    width: 100%;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
  }
  .account-header {
    border-bottom: 1px solid #707070;
    padding-bottom: 20px;
    @include breakpoint( small down ) {
      padding: 20px;
    }
  }
  .editable-header {
    margin-bottom: 0.5em;
    .edit-link {
      font-size: 16px;
      font-weight: bold;
      float: right;
      font-family: Overpass, sans-serif;
      margin-top: 0.75em;
      @include breakpoint( small down ) {
        margin-top: 0.5em;
      }
      i {
        font-size: 75%;
      }
    }
  }
  .account-callout {
    padding: 20px;
    @include breakpoint( medium up ) {
      padding: 30px 60px;
    }
  }
  .center {
    text-align: center;
  }
}

.call-details {
  padding: 30px;
  @include breakpoint(large) {
    padding: 60px;
  }
  h2 {
    color: #004976;
    line-height: 1.2;
    margin-bottom: 40px;
    @include breakpoint(medium) {
      font-size: 24px;
    }
  }
  .close-button {
    font-size: 48px;
    color: #bbbbbb;
    top: 15px;
    right: 25px;
    @include breakpoint(medium) {
      top: 40px;
      right: 60px;
    }
  }
}

.editing {
  .static-editable, .link-button.edit {
    display: none;
  }
}

.method-wrapper {
  border-radius: 10px;
  padding: 12px;
  border: 1px solid transparent;
  transition: 250ms ease;
  @include breakpoint(medium) {
    margin-left: 10px;
    margin-right: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
  > label {
    > input[type="radio"] {
      margin-bottom: 0;
    }
  }
  &.open {
    background-color: #E6F4FB;
    border-color: #1ABCED;
    > label {
      font-weight: bold;
    }
    &#add-method-wrapper {
      background-color: transparent;
      border-color: transparent;
      > label {
        font-weight: normal;
      }
    }
  }
}

// Editing form styles
.form-editable {
  display: none;
  input, select {
    color: #444444;
    border-color: #888888;
    box-shadow: none;
  }
  .editing & {
    display: block;
  }
  .action-buttons {
    margin-top: 1rem;
    display: block;
    text-align: left;
    .hide {
      display: inline-block !important;
    }
  }
}

.bill-preview {
  max-width: 150px;
  height: auto;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
  + * {
    text-align: center;
  }
}

.grid-x.small-gutter {
  margin-left: -5px;
  margin-right: -5px;
  .cell {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.page-template-template-account-managepayement {
  input, .select-wrap, .inline-label {
    margin-bottom: 1.25rem;
  }
  .payment-buttons {
    margin-top: 30px;
  }
  .method-inner {
    padding-top: 10px;
    @include breakpoint(medium) {
      padding: 20px 15px;
    }
  }
  h3 {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 16px;
    font-family: Overpass,Arial,sans-serif;
  }
  hr {
    margin-bottom: 30px;
  }
  .my-account-row {
    > .callout {
      padding-top: 30px;
      padding-bottom: 30px;
      @include breakpoint(medium) {
        padding: 30px;
      }
    }
  }
  .help-text {
    font-size: 13px;
    font-style: normal;
    color: #444444;
    margin-top: -1rem;
  }
  input[type="text"], input[type="tel"], input[type="number"], select {
    @include input-placeholder {
      color: #888888;
    }
    &:invalid, &[disabled] {
      color: #888888;
    }
  }
  .checkbox-wrap {
    margin-top: 20px;
  }
  .checkbox-wrap label {
    @include breakpoint(small only) {
      font-size: 13px;
    }
  }
  .tabs-content {
    background: linear-gradient(0deg, rgba(176, 3, 211, 0.1), rgba(176, 3, 211, 0.1)), #FFFFFF;
    border-color: $purple;
    border-top: 1px solid $purple;
    border-radius: 5px;
    @include breakpoint(medium) {
      border-radius: 10px;
    }
  }
  .tabs-panel {
    padding: 20px 25px;
    @include breakpoint(medium) {
      padding-top: 30px;
    }
    hr {
      border-bottom: 2px solid $purple dotted;
      margin-top: 30px;
    }
  }
  .transparent-input {
    background-color: transparent;
    @include input-placeholder {
      color: #888888;
    }
  }
  .nickname-input {
    @include breakpoint(small only) {
      width: calc(100% - 30px);
      margin-left: auto;
    }
    @include breakpoint(medium) {
      margin-top: 30px;
    }
  }
  .tabs, .tabs-content {
    @include breakpoint(medium) {
      margin-left: 10px;
    }
  }
  .tabs {
    display: flex;
    border: none;
    .tabs-title {
      position: relative;
      top: 4px;
      background-color: transparent;
      cursor: pointer;
      @include breakpoint(medium) {
        top: 15px;
      }
      > a {
        &:hover, &:active, &:focus {
          background-color: transparent;
          outline: 0;
        }
      }
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        background: linear-gradient(0deg, rgba(176, 3, 211, 0.1), rgba(176, 3, 211, 0.1)), #FFFFFF;
        display: block;
        width: 100%;
        height: 3px;
        display: none;
        @include breakpoint(medium) {
          height: 14px;
        }
      }
      &:first-child {
        &:after {
          left: 1px;
        }
      }
      &.is-active {
        &:after {
          display: block;
        }
        > a {
          background: linear-gradient(0deg, rgba(176, 3, 211, 0.1), rgba(176, 3, 211, 0.1)), #FFFFFF;
          border: 1px solid $purple;
          border-top-right-radius: 5px;
          border-top-left-radius: 5px;
          border-bottom: 0;
          > label {
            font-weight: bold;
          }
        }
      }
      > a {
        padding: .25rem 1rem;
        padding-top: 0.6rem;
        @include breakpoint(medium) {
          padding-bottom: 15px;
          padding-top: 15px;
        }
        > label {
          font-size: 3.54vw;
          @media (min-width: 452px) {
            font-size: 16px;
          }
        }
      }
    }
  }
}


#autoPayTabs, #bank-or-credit {
  & input[name="payment-method"] {
    pointer-events: none;
  }
}
