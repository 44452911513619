.career-perks-block {
  background-color: $lumos-purple;
  // margin-bottom: rem-calc(-30);

  .career-perks-block-conent {
    background-color: $lumos-purple;
    position: relative;
    padding: rem-calc(70) 8% rem-calc(30);
    text-align: center;

    color: $white;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      display: block;
      width: 100vw;
      height: 100%;
      background-color: $lumos-purple;
      z-index: -1;
    }

    // &:before, &:after {
    //   content: '';
    //   display: block;
    //   position: absolute;
    //   top: rem-calc(30);
    //   opacity: 0.07;
    //   width: rem-calc(90);
    //   height: rem-calc(90);
    // }
    //
    // &:before {
    //   background: linear-gradient(45deg, rgba($black, 0.0) 0%,rgba($black, 0) 50%, rgba($black, 1) 100%);
    //   right: calc(100%);
    // }
    //
    // &:after {
    //   background: linear-gradient(-45deg, rgba($black, 0.0) 0%,rgba($black, 0) 50%, rgba($black, 1) 100%);
    //   left: calc(100%);
    // }
  }

  h2 {
    color: $vivid-violet;
    font-size: rem-calc(42);
    line-height: 120%;
    margin: 0 0 rem-calc(30);
    font-weight: bold;
  }

  .career-features {
    padding: 0;
    margin: 0;
    list-style: none;
    li {
      font-size: 0;
      padding: rem-calc(40) 0;
      margin: 0;
      border-bottom: rem-calc(2) dotted $vivid-violet;

      &:last-child {
        border-width: 0;
      }
    }

    .icon, .info {
      display: inline-block;
      vertical-align: top;
    }

    .icon {
      width: 38%;
      img {
        max-height: rem-calc(122);
      }
    }

    .info {
      width: 62%;
      text-align: left;

      p {
        font-size: rem-calc(18);
        line-height: 155%;
      }

      h3 {
        font-weight: bold;
        margin-bottom: rem-calc(32);
        line-height: 120%;
        color: $vivid-violet;
        margin: 0 0 16px;
      }
    }
  }
}

@include breakpoint(medium down) {
  .career-perks-block {
    .career-perks-block-conent {
      padding: rem-calc(40) rem-calc(15);
    }

    h2 {
      font-size: rem-calc(36);
    }

    .career-features {
      padding: rem-calc(0) rem-calc(10);

      .icon, .info {
        width: 100%;
        text-align: center;
      }

      .icon {
        padding-bottom: rem-calc(30);
      }
    }
  }
}
