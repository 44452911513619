.contact-us-block {
  padding: 0;

  .contact-us-row .grid-x {
    justify-content: center;

    .grid-x {
      .grid-x {
        justify-content: flex-start;
      }
    }
  }

  .contact-outline {
    background-color: $white;
    border: rem-calc(1) solid $purple;
    border-radius: rem-calc(5);
    padding: rem-calc(30);
    margin-bottom: rem-calc(20);
    min-height: rem-calc(165);
    @include breakpoint(small down) {
      min-height: unset;
    }
    h3 {
      display: block;
      font-size: rem-calc(24);
      line-height: 1em;
      margin-bottom: rem-calc(20);
      color: $lumos-purple;
    }
    p:last-child {
      margin-bottom: 0;
    }
  }

  .location-row {
    margin-bottom: rem-calc(60);
    &:last-child {
      margin-bottom: 0;
    }

    .grid-x {
      justify-content: left;
    }
  }

  .location-label {
    border-right: rem-calc(1) solid $lumos-purple;
    padding-right: 2%;
    @include breakpoint(small down) {
      border-right: 0;
      border-bottom: rem-calc(1) solid $lumos-purple;
      margin-bottom: rem-calc(30);
    }
    h3 {
      color: $lumos-purple;
      font-size: rem-calc(24);
      line-height: 1.2em;
      font-weight: normal;
      text-transform: uppercase;
    }
  }

  .location-info {
    h3 {
      color: $lumos-purple;
    }
    p {
      color: $lumos-purple;
    }
    em, i {
      color: $lumos-purple;
      font-style: normal;
    }
    strong {
      color: $lumos-purple;
    }
  }


  .contact-block {
    border: rem-calc(1) solid transparent;
    border-radius: rem-calc(5);
    padding: rem-calc(30);
    margin-bottom: rem-calc(20);
    min-height: rem-calc(165);
    // margin-bottom: rem-calc(20);
    @include breakpoint(small down) {
      min-height: unset;
      padding: rem-calc(15) rem-calc(30);
    }
    h3 {
      display: block;
      font-size: rem-calc(24);
      line-height: 1em;
      margin-bottom: rem-calc(20);
      color: $lumos-purple;
    }
    p:last-child {
      margin-bottom: 0;
    }
  }

  .contact-container {
    padding: 0 0;
  }

  .locations-container {
    // background-color: $white;
    padding: rem-calc(60) 0;
  }


  h1 {
    font-size: rem-calc(34);
    line-height: rem-calc(48);
    color: $lumos-purple;
    margin-bottom: rem-calc(60);
  }

  .tabs {
    background-color: transparent;
    display: flex;
    justify-content: center;
    border: 0;
  }

  .tabs-title {
    position: relative;
    float: none;
    border-left: rem-calc(1) solid rgba($vivid-violet, 0.2);
    border-top: rem-calc(1) solid rgba($vivid-violet, 0.2);
    border-right: rem-calc(1) solid rgba($vivid-violet, 0.2);
    background-color: rgba($vivid-violet, 0.2);
    border-radius: rem-calc(5) rem-calc(5) 0 0;
    margin: 0 rem-calc(5);
    padding: 0;
    width: 25%;
    transition: all 0.2s ease;

    &:hover {
      background-color: rgba($vivid-violet, 0.35);
    }

    a {
      display: block;
      width: 100%;
      background-color: transparent;
      font-size: rem-calc(24);
      line-height: rem-calc(24);
      color: $lumos-purple;
      padding: rem-calc(20) 0;
      text-align: center;
      white-space: nowrap;
      outline: none;
    }

    &.is-active {
      border-color: $purple;
      background-color: $white;
      &:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        display: block;
        width: 100%;
        height: 2px;
        background-color: $white;

      }
    }
  }

  .tabs-content {
    border-top: rem-calc(1) solid $purple;
    padding: rem-calc(30) 0 rem-calc(30);
  }


  .contact-content {
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: flex-start;

      & > li {
        padding: 0;
        width: 100%;
        flex: 50%;
      }
    }
  }


  h2 {
    width: 100%;
    font-size: rem-calc(32);
    line-height: rem-calc(32);
    color: $lumos-purple;
    font-weight: bold;
    margin: 20px 0 40px;
    // text-transform: uppercase;
  }

  h3, .header-placeholder {
    width: 100%;
    font-size: rem-calc(24);
    line-height: rem-calc(24);
    color: $vivid-violet;
    font-weight: 500;
    margin-top: rem-calc(0);
    margin-bottom: rem-calc(20);
  }

  p {
    color: $lumos-purple;
    margin-bottom: rem-calc(10);
    &:last-child {
      margin-bottom: rem-calc(20);
    }
  }

  .light {
    color: $lumos-purple;
  }

  strong {
    font-size: rem-calc(16);
    line-height: rem-calc(21);
    color: $lumos-purple;
    font-weight: bold;
  }

  .tabs-panel .grid-x {
    justify-content: center;
    padding: 0 20%;
  }
}

@include breakpoint(medium down) {
  .contact-us-block {
    .tabs-title {
      width: 48%;
      a {
        font-size: rem-calc(16);
        line-height: rem-calc(16);
        font-weight: bold;
      }
    }
  }
}

@include breakpoint(small down) {
  .contact-us-block {
    .tabs-panel .grid-x {
      padding: 0 5%;
    }
  }
}
