.special-two-column-block {
  background-color: $white;
  padding: rem-calc(40) rem-calc(20);
  @include breakpoint(large) {
    padding: rem-calc(70) 0;
  }

  & > .grid-container {
    & > .grid-x {
      @include breakpoint(large) {
        padding: 0 rem-calc(40);
      }

      & > .cell {
        padding-bottom: rem-calc(30);
      }
    }
  }

  h2 {
    font-family: $body-font-faimly-header;
    font-size: rem-calc(24);
    line-height: rem-calc(26);
    margin-bottom: rem-calc(16);
  }

  a {
    color: #00baee;
  }

  .left-column {
    padding-top: 15px;
    padding-bottom: rem-calc(40);
  }

  .right-column {
    .icon-container {
      padding-right: 15px;
    }

    .copy-container {
      padding-top: 15px;
      padding-bottom: rem-calc(40);
    }
  }



}
