.business-landing-page {
  font-family: Roboto,Arial,sans-serif;
  .grid-container {
    max-width: 87.5rem;
    padding: 0;
    padding-right: .9375rem;
    padding-left: .9375rem;
  }

  * {
    font-family: Roboto,Arial,sans-serif !important;
  }

  &.twenty-two {

    .form-header {
      font-size: 36px;
      line-height: 40px;
    }

    .business-form-group {
      position: relative;
      max-width: 1100px;
      margin-left: auto;
      margin-right: auto;
      background-color: $white;
      border: 1px solid rgba(#888888, 0.71);
      border-radius: 6px;
      padding: 15px;
      margin-bottom: 16px;

      @include breakpoint(medium) {
        padding: 40px;
      }

      .gform_legacy_markup_wrapper {
        margin: 0;
      }

      .form-desc {
        text-transform: uppercase;
      }

      input[type="text"] {
        height: 40px !important;
        padding-left: 13px !important;
        padding-right: 13px !important;

        &::placeholder {
          color: #6A6969;
          opacity: 1;
        }

        &:-ms-input-placeholder {
          color: #6A6969;
          opacity: 1;
        }

        &::-ms-input-placeholder {
          color: #6A6969;
          opacity: 1;
        }
      }

      .gform_footer {
        text-align: center;
        .button {
          margin: 0;
        }
      }

      iframe[title=reCAPTCHA] {
        left: 15px;
        transform: none;
        @include breakpoint(medium) {
          left: 40px;
        }
      }
    }

    .blue-bg {
      h3 {
        position: relative;
        display: inline-block;
        font-size: 30px;
        line-height: 35px;
        // border-top: 1px solid $white;
        // border-bottom: 1px solid $white;
        text-transform: uppercase;
        padding: 15px 15px;
        font-weight: 600;

        &:before, &:after {
          content: '';
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          display: block;
          max-width: 860px;
          width: calc(100% - 16px);
          height: 1px;
          border-bottom: 1px solid $white;
        }

        &:before {
          bottom: 100%;
        }
        &:after {
          top: 100%;
        }
      }
    }

    .main-copy {
      margin-left: 3%;
      margin-right: 3%;
    }

    .button, .wp-block-button .wp-block-button__link {
      color: $white;
    }

    .minor-header {
      font-size: 36px;
      line-height: 40px;
    }

    hr {
      max-width: 780px;
      margin-top: 15px;
      margin-bottom: 15px;
    }

    .business-content {
      &.gray-bg {
        background-color: transparent !important;
        // &:before {
        //   border-bottom: 7px solid $vivid-violet;
        // }

        h3 {
          font-size: 36px;
          line-height: 40px;
          font-weight: normal;
        }
        ul {
          display: inline-block;
          text-align: left;
          list-style: none;

          li:before {
            content: "\2022";
            color: $slate;
            font-weight: bold;
            display: inline-block;
            width: 1em;
            margin-left: -1em;
          }
        }
      }
    }
  }


  .blue-bg {
    position: relative;
    background-color: #3DBEE3;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      display: block;
      width: 100vw;
      height: 100%;
      background-color: #3DBEE3;
      z-index: -1;
    }
  }

}

.top-nav.business {
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: $white;

  .grid-container {
    max-width: 87.5rem;
    padding: 0;
    padding-right: .9375rem;
    padding-left: .9375rem;
  }

  & > .grid-container {
    & > .grid-x {
      padding: 0 3%;
    }
  }

  #header_logo, #header_logo_alt {
    max-height: 75px;
    width: 250px;
    padding: 0;
    @include breakpoint(large) {
      // padding: 0 0 0 20px;
    }
  }
}

.business-disclaimer {
  font-size: 14px;
  & > .grid-container {
    max-width: 1400px;
  }

  &.twenty-two {
    margin-bottom: 60px;
  }
}

.business-content {
  max-width: 1060px;
  padding: 50px 3.85%;
  margin: 0 auto;

  font-size: 25px;
  line-height: 32px;

  &.gray-bg {
    position: relative;
    background-color: #F1F2F2;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      display: block;
      width: 100vw;
      height: 100%;
      background-color: #F1F2F2;
      z-index: -1;
    }
  }

  .has-medium-font-size {
    font-size: 26px;
    line-height: 36px;
    @include breakpoint(large) {
      font-size: 36px;
      line-height: 46px;
    }
  }

  .wp-block-button {
    .wp-block-button__link {

    }
  }

  .wp-block-columns {
    font-size: 20px;
    line-height: 30px;

    strong {
      color: $purple;
    }

    @include breakpoint(600) {
      .wp-block-column {
        &:first-child {
          border-right: 1px solid #BBC6C3;
        }
      }
    }
  }
}

.blue-bg-columns {
  position: relative;
  align-items: center;
  margin: 0;
  color: $lumos-purple;
  max-width: 1400px !important;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    width: 100vw;
    height: 100%;
    background-color: $slate;
  }
  .wp-block-column {
    position: relative;
    @include breakpoint(600px) {
      flex-basis: 50%;
    }

    &:nth-child(odd) {
      @include breakpoint(600px) {
        height: 540px;
      }
    }

    &:nth-child(even) {
      padding: 40px 9%;
      margin: 0;
    }
  }
  .wp-block-image {
    position: relative;
    float: right;
    width: 50vw;
    height: 100%;
    max-width: unset;
    overflow: hidden;

    // dumb gutenbork breakpoint
    @include breakpoint(599px down) {
      width: 100vw;
      margin-right: -10px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  h2 {
    font-size: rem-calc(55);
    line-height: rem-calc(60);
    font-weight: 100;
    margin-bottom: rem-calc(30);

    @include breakpoint(medium down) {
      font-size: rem-calc(40);
      line-height: rem-calc(44);
      margin-bottom: rem-calc(20);
    }
  }

  p {
    font-size: rem-calc(20);
    line-height: rem-calc(28);
    margin-bottom: rem-calc(50);

    @include breakpoint(medium down) {
      font-size: rem-calc(14);
      line-height: rem-calc(22);
      margin-bottom: rem-calc(0);
    }

    &:last-child {
      margin: 0;
    }
  }

  a {
    @extend .button;
    background-color: $white;
    margin: 0;
    &:hover {
      background-color: darken($white, 20%);
    }
  }
}


.business-blue.blue-bg-columns {
  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    width: 100vw;
    border-bottom: 10px solid $vivid-violet;
  }
  .wp-block-column:nth-child(2n) {
    padding: 40px 3%;
    h2 {
      color: $purple;
    }

    .has-medium-font-size {
      @include breakpoint(large) {
        font-size: 29px;
        line-height: 40px;
      }
    }
  }
}

.business-form {
  max-width: 1060px;
  padding: 50px 15px;
  margin: 0 auto;
  @include breakpoint(large) {
    padding: 70px 15px;
  }

  .gform_heading {
    text-align: center;
    h3 {
      @include breakpoint(large) {
        font-size: 36px;
        line-height: 46px;
      }
    }
    .gform_description {
      @include breakpoint(large) {
        font-size: 36px;
        line-height: 46px;
        color: #808285;
        margin-bottom: 60px;
      }
    }
  }

  .gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) {
    border-radius: 0;
  }

  a {
    text-decoration: underline;
    font-size: 22px;
    color: $purple;
  }

  .gfield_required {
    display: none;
  }

  .gform_wrapper ul li.gfield {
    margin-top: 0 !important;
  }

  .gform_footer {
    text-align: center;
    .button {
      margin: 0 !important;
      font-size: 18px !important;
    }
  }

  .gform_wrapper div.validation_error {
    color: $purple;
    border-color: $purple;
  }

  .gform_wrapper .gfield_error .gfield_label {
    color: $purple;
  }

  .gform_wrapper li.gfield_error input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]),
  .gform_wrapper li.gfield_error textarea {
    // color: $purple;
    border-color: $purple;
  }

  .gform_wrapper li.gfield.gfield_error,
  .gform_wrapper li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning {
    padding-top: 0 !important;
    border-color: transparent !important;
    background-color: transparent !important;
  }

  .gform_wrapper .validation_message {
    color: $purple;
  }

  .gform_wrapper li.gfield.gfield_error.gfield_contains_required div.ginput_container,
  .gform_wrapper li.gfield.gfield_error.gfield_contains_required label.gfield_label {
    margin-top: 0 !important;
  }

  .gform_wrapper .field_description_below .gfield_description {
    padding-top: 0 !important;
  }
}
