.investor-block {
  background-color: $medium-light-gray;
  margin-bottom: rem-calc(-30);
  .investor-block-content {
    position: relative;
    padding: rem-calc(70) 15%;
    background-color: $medium-light-gray;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: rem-calc(30);
      right: 100%;
      opacity: 0.07;
      width: rem-calc(90);
      height: rem-calc(90);
      background: linear-gradient(45deg, rgba($black, 0.0) 0%,rgba($black, 0) 50%, rgba($black, 1) 100%);
      pointer-events: none;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: rem-calc(30);
      left: 100%;
      opacity: 0.07;
      width: rem-calc(90);
      height: rem-calc(90);
      background: linear-gradient(-45deg, rgba($black, 0.0) 0%,rgba($black, 0) 50%, rgba($black, 1) 100%);
      pointer-events: none;
    }
  }

  .document-container {
    text-align: center;
    .button {
      position: relative;
      display: block;
      width: 94%;
      margin: 0 auto;
      text-align: left;
      background-color: rgba($dark-blue, 1);
      padding: rem-calc(20) rem-calc(30);
      border: rem-calc(1) solid $light-blue;
      margin-bottom: rem-calc(20);

      &:after {
        position: absolute;
        right: rem-calc(25);
        top: 50%;
        transform: translateY(-50%);
        content: "\f061";
        font-family: Font Awesome\ 5 Free;
        font-size: rem-calc(21);
        line-height: rem-calc(40);
        font-weight: 700;
        border: rem-calc(1) solid #fff;
        display: block;
        width: rem-calc(40);
        height: rem-calc(40);
        text-align: center;
        border-radius: 50%;
      }

      &:hover {
        background-color: rgba($dark-blue, 0.95);
      }

      span {
        display: block;
      }

      .document-label {
        font-size: rem-calc(16);
        line-height: rem-calc(20);
        color: $light-blue;
        text-transform: uppercase;
      }

      .document-title {
        font-size: rem-calc(24);
        line-height: rem-calc(24);
        color: $white;
        padding: 0 0;
      }
    }
  }

  h3 {
    text-align: center;
    color: $dark-blue-4;
    font-size: rem-calc(24);
    line-height: rem-calc(34);
    margin-bottom: rem-calc(20);
  }

  .financial-statements {
    &.accordion {
      background-color: transparent;
    }
    .accordion-item {
      width: 94%;
      margin: 0 auto rem-calc(10);
      border: rem-calc(1) solid $dark-gray;
      border-radius: rem-calc(5);
      background-color: $white;

      .accordion-title {
        font-size: rem-calc(16);
        line-height: rem-clac(16);
        text-transform: uppercase;
        font-weight: bold;
        padding: rem-calc(12) rem-calc(20);
        color: $text-gray;
        background-color: $white;
        border-radius: rem-calc(5);
        border-width: 0;
        transition: all 0.2s ease;

        &:before {
          content: "\f063";
          font-family: 'Font Awesome 5 Free';
          font-weight: bold;
          transition: transform 0.2s ease;
          right: rem-calc(20);
        }
      }

      &.is-active {
        .accordion-title {
          color: $white;
          background-color: $dark-blue-4;
          &:before {
            color: $ns-light-blue;
            transform: rotate(-180deg);
          }
        }
      }

      .accordion-content {
        font-size: 0;
        padding: rem-calc(30) 5%;
      }

      .button {
        font-size: rem-calc(16);
        line-height: rem-calc(16);
        margin: rem-calc(5) 1%;
        width: 18%;
        min-width: rem-calc(110);
        text-align: left;

        i {
          float: right;
        }

        &:hover {
          background-color: $dark-blue-4;
          color: $white;
        }
      }
    }
  }
}

@include breakpoint(small down) {
  .investor-block {
    .investor-block-content {
      padding: rem-calc(40) 5%;
    }
    .document-container {
      .button {
        width: 100%;
        padding: rem-calc(20) rem-calc(30);

        &:after {
          display: none;
        }

        .document-label {
          text-align: center;
          font-size: rem-calc(16);
          line-height: rem-calc(20);
        }

        .document-title {
          text-align: center;
          font-size: rem-calc(24);
          line-height: rem-calc(33);
        }
      }
    }
    .financial-statements {
      .accordion-item {
        width: 100%;
        .button {
          width: 100%;
        }
      }
    }
  }
}
