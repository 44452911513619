.homepage-header {
  height: 622px;
  max-height: 100vh;

  background-color: $lumos-purple;
  background-position: center center;
  background-size: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  // background-attachment: fixed;

  margin: 0 0 0;
  z-index: 0;

  color: $white;
  // text-shadow: 0px 0px 5px rgba($lumos-purple, 0.8);

  @include breakpoint(medium down) {
    background-position: calc(100% + 80px) 50%;
  }

  a:not(.button) {
    color: $white;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }

    // &.button {
    //   text-decoration: none;
    //   font-size: 18px;
    //   min-width: 250px;
    //   background-color: $ns-light-blue;
    // }
  }

  .grid-container,
  .grid-x,
  .cell {
    position: relative;
    height: 100%;
  }

  &.normal {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
  }

  &.short {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;

    height: 400px;
    max-height: 100vh;

    .to-next-section {
      display: none;
    }
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
    width: 100%;
    height: 100%;
    // background: linear-gradient(to bottom, rgba($black, 0) 0%, rgba($black, 0.5) 100%);
    pointer-events: none;
  }

  &.text-color-black {
    background-color: $white;
    color: $lumos-purple;
    // text-shadow: 0px 0px 5px rgba($white, 0.8);

    &:before {
      background: linear-gradient(to right, rgba($white, 0.98) 0%, rgba($white, 0.9) 40%, rgba($white, 0.8) 55%, rgba($white, 0) 75%, rgba($white, 0) 100%);

      @include breakpoint(medium down) {
        background: linear-gradient(to right, rgba($white, 0.98) 0%, rgba($white, 0.8) 60%, rgba($white, 0) 100%);
      }

      @include breakpoint(small down) {
        background: linear-gradient(to right, rgba($white, 0.98) 0%, rgba($white, 0.8) 90%, rgba($white, 0.95) 100%)
      }
    }

    h2 {
      color: $lumos-purple;
    }

    // .to-next-section {
    //   color: $white;
    // }

    // a {
    //   color: $lumos-purple;
    // }
    //
    // .button {
    //   color: $white;
    //   &.hollow {
    //     border-color: $lumos-purple;
    //     color: $lumos-purple;
    //     &:hover {
    //       background-color: rgba($lumos-purple, 0.2)
    //     }
    //   }
    // }
  }

  .subhead {
    font-size: rem-calc(13);
    line-height: rem-calc(20);
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: rem-calc(20);
  }

  h2 {
    // font-size: rem-calc(28);
    // line-height: rem-calc(36);
    font-size: rem-calc(48);
    line-height: 120%;
    font-weight: 500;
    margin-bottom: 30px;
    // color: $ns-green;
  }

  h3 {
    // font-size: rem-calc(42);
    // line-height: rem-calc(53);
    font-size: rem-calc(30);
    line-height: rem-calc(46);
    font-weight: normal;
  }

  p {
    font-size: rem-calc(18);
    line-height:155%;
  }

  .contact_info {
    padding: rem-calc(10) 0 rem-calc(10) rem-calc(60);
    margin: rem-calc(60) 0 rem-calc(30);
    color: $white;
    border-left: rem-calc(1) solid $white;

    a:not(.button) {
      color: $white;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: $lumos-purple;
    mix-blend-mode: multiply;
  }

  &.text-color-black {
    .contact_info {
      color: $lumos-purple;
      a {
        color: $lumos-purple;
      }
    }
    .overlay {
      background-color: $white;
    }
  }

  .disclaimer {
    font-size: rem-calc(13);
  }

  // .button {
  //   font-size: rem-calc(16);
  //   line-height: rem-calc(16);
  //   margin-top: rem-calc(30);
  //   text-shadow: none;
  //
  //   &.hollow {
  //     border-color: $white;
  //     color: $white;
  //
  //     &:hover {
  //       background-color: rgba($white, 0.2);
  //       // color: $lumos-purple;
  //     }
  //   }
  //
  //   img {
  //     display: inline-block;
  //     height: rem-calc(16);
  //     margin-right: rem-calc(10);
  //   }
  // }

  .body-text {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: rem-calc(550);
    padding: 0 rem-calc(10);
    // text-align: center;

    &.text-center {
      max-width: rem-calc(750);
      margin: 0 auto;
    }
    &.text-left {
      // left: rem-calc(58);
      left: 0;
      top: 60px;
      transform: translateX(0%) translateY(0);
    }
    &.text-right {
      left: auto;
      right: rem-calc(58);
      transform: translate(0%, -50%);
    }

    &.vertical-top {
      top: 25%;
    }

    &.vertical-bottom {
      top: auto;
      bottom: 60px;
    }

  }

  .homepage-header-container {
    position: absolute;
    top: 0;
    left: 50%;
    width: 100%;
    max-width: rem-calc(1200);
    height: 100%;
    transform: translateX(-50%);

    &:before, &:after {
      content: '';
      display: block;
      position: absolute;
      top: 100%;
      opacity: 0.07;
      width: rem-calc(90);
      height: rem-calc(90);
    }

    &:before {
      background: linear-gradient(45deg, rgba($black, 0.0) 0%,rgba($black, 0) 50%, rgba($black, 1) 100%);
      right: calc(100% - 60px);
    }

    &:after {
      background: linear-gradient(-45deg, rgba($black, 0.0) 0%,rgba($black, 0) 50%, rgba($black, 1) 100%);
      left: calc(100% - 60px);
    }
  }

  .to-next-section {
    text-decoration: none;
    position: absolute;
    bottom: rem-calc(60);
    right: rem-calc(58);
    border: rem-calc(1) solid $white;
    display: block;
    width: rem-calc(40);
    height: rem-calc(40);
    border-radius: 50%;
    text-align: center;
    color: $white;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.8);
    background-color: rgba($black, 0.25);
    text-decoration: none !important;
    transition: all 0.2s ease;

    &:before {
      content: '\f063';
      font-family: 'Font Awesome 5 Free';
      font-size: rem-calc(21);
      line-height: rem-calc(40);
      font-weight: bold;
    }

    &:hover {
      color: $purple;
      border-color: $purple;
    }
  }
  .mobile-image {
    display: none;
    border-radius: rem-calc(6);
    -ms-overflow-style: none;
    overflow: hidden;
    img {
      margin-bottom: 30px;
    }
  }
}

@include breakpoint(small down) {
  .homepage-header {
    &.short {
      min-height: 175px;
      height: auto;
    }
    .body-text {
      position: relative;
      left: 0;
      top: 0;
      transform: translate(0, 0);
      max-width: 100%;
      padding: rem-calc(35) rem-calc(35) rem-calc(100);
      // text-align: center;

      &.text-center {

      }
      &.text-left {
        left: 0;
        top: 0;
        transform: translate(0, 0);
      }
      &.text-right {
        top: 0;
        left: auto;
        right: 0;
        transform: translate(0, 0);
      }

      h2 {
        font-size: rem-calc(26);
        line-height: rem-calc(33);
        font-weight: 500;
      }

      h3 {
        // font-size: rem-calc(42);
        // line-height: rem-calc(53);
        font-size: rem-calc(30);
        line-height: rem-calc(41);
        font-weight: 500;
      }
    }
    .to-next-section {
      right: auto;
      left: 50%;
      transform: translateX(-50%);
    }

    // .button {
    //   margin-top: rem-calc(10);
    // }
  }
}


@include breakpoint(small down) {
  .homepage-header {
    position: relative;
    height: auto;
    max-height: none;

    .homepage-header-container {
      position: relative;
    }

    .body-text {
      // padding-bottom: 100px;
    }

    &.hide-bg {
      // height: auto;
      // max-height: none;
      background-position: -99999px;
      background-color: $white;
      color: $lumos-purple;
      text-shadow: 0px 0px 5px rgba($black, 0);

      &.text-color-black {
        background-color: $white;
        color: $lumos-purple;
        text-shadow: 0px 0px 5px rgba($black, 0);
      }

      &:after {
        display: none;
      }

      .body-text {
        padding-top: rem-calc(50);
        padding-bottom: rem-calc(35);
      }

      h2, h3, p {
        text-align: center;
      }

      h2 {
        font-size: rem-calc(20);
        line-height: rem-calc(28);
      }

      h3 {
        font-size: rem-calc(26);
        line-height: rem-calc(32);
      }

      .disclaimer {
        font-size: rem-calc(11);
        line-height: rem-calc(17);
      }

      .to-next-section {
        display: none;
      }
    }
    // .button {
    //   display: block;
    //   margin: 0 auto;
    //   width: 100%;
    //   margin-top: rem-calc(10);
    //   margin-bottom: rem-calc(10);
    // }
    .mobile-image {
      display: block;
    }
  }
}
