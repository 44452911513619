.featured-products-block {
  background-color: $super-light-gray;
  background-image: linear-gradient(to bottom, rgba($fade-to-blue, 0)  0%, rgba($fade-to-blue, 0) 63%, rgba($fade-to-blue, 0.5) 100%);
  margin-bottom: rem-calc(-30);

  .featured-products-block-content {
    background-color: $super-light-gray;
    background-image: linear-gradient(to bottom, rgba($fade-to-blue, 0)  0%, rgba($fade-to-blue, 0) 63%, rgba($fade-to-blue, 0.5) 100%);
    position: relative;
    padding: rem-calc(70);
    text-align: center;

    &:before, &:after {
      content: '';
      display: block;
      position: absolute;
      top: rem-calc(30);
      opacity: 0.07;
      width: rem-calc(90);
      height: rem-calc(90);
    }

    &:before {
      background: linear-gradient(45deg, rgba($black, 0.0) 0%,rgba($black, 0) 50%, rgba($black, 1) 100%);
      right: calc(100%);
    }

    &:after {
      background: linear-gradient(-45deg, rgba($black, 0.0) 0%,rgba($black, 0) 50%, rgba($black, 1) 100%);
      left: calc(100%);
    }
  }

  h2 {
    color: $dark-blue;
    font-size: rem-calc(34);
    line-height: rem-calc(46);
    margin: 0 0 rem-calc(30);
  }

  ul.tabs {
    margin: 0 5%;
    border-top: rem-calc(1) dotted $link-blue;
    border-bottom: rem-calc(1) dotted $link-blue;
    border-left: 0;
    border-right: 0;
    text-align: center;
    background-color: transparent;
    white-space: nowrap;
    overflow-y: hidden;
    -ms-overflow-style: none;

    *::-webkit-scrollbar {
      display: none;
    }

    li.tabs-title {
      float:none;
      display: inline-block;
      a {
        color: rgba($middle-blue, 0.5);
        font-size: rem-calc(18);
        line-height: rem-calc(36);
        font-weight: bold;
        border-bottom: rem-calc(6) solid transparent;
        border-radius: 0;
        padding: rem-calc(20) rem-calc(10);
        margin: 0 rem-calc(10);
        outline: none;
        transition: all 0.2s ease;

        &:hover {
          background-color: transparent;
          color: rgba($middle-blue, 1);
        }
      }

      &.is-active {
        a {
          color: rgba($middle-blue, 1);
          border-bottom: rem-calc(6) solid $ns-light-blue;
        }
      }
    }
  }

  .tabs-content {
    background-color: transparent;
    border-color: transparent;
  }

  .tabs-panel {
    p {
      padding: rem-calc(30) 0;
      width: 70%;
      margin: 0 auto;
    }

    .button {
      margin: rem-calc(50) 0 0;
    }
  }

  .review-image {
    text-align: center;
    img {
      max-width: 80%;
      border-radius: 50%;
      -ms-overflow-style: none;
      overflow: hidden;
    }
  }

  .quote-bubble {
    position: relative;
    background-color: $light-blue;
    border-radius: rem-calc(20);
    text-align: left;
    padding: rem-calc(20);

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      right: 100%;
      transform: translateY(-50%);
      height: 0;
      border-style: solid;
      border-width: 0 0 50px 50px;
      border-color: transparent transparent $light-blue transparent;

    }

    p {
      padding: 0;
      width: 100%;
      margin: 0;
    }

    .copy-text {
      font-size: rem-calc(22);
      line-height: rem-calc(32);
      color: $dark-blue;
      margin-bottom: rem-calc(30);
    }

    .copy-reviewer {
      text-transform: uppercase;
      font-size: rem-calc(16);
      line-height: rem-calc(25);
      font-weight: bold;
      color: $white;
      margin-bottom: rem-calc(10);
    }
    .copy-title {
      font-size: rem-calc(13);
      line-height: rem-calc(20);
      color: $white;
    }
  }
  .inset-photo {
    position: relative;
    text-align: right;
    margin-top: -25%;
    padding-right: 2%;
    img {
      max-width: 50%;
      border-radius: 50%;
      -ms-overflow-style: none;
      overflow: hidden;
    }
  }
}


@include breakpoint(medium down) {
  .featured-products-block {
    .featured-products-block-content {
      padding: rem-calc(50) rem-calc(20);

      h2 {
        font-size: rem-calc(28);
        line-height: rem-calc(36);
      }
      ul.tabs {
        margin: 0;
      }
      .tabs-panel {
        p {
          width: 100%;
        }
      }
    }

  }
}

@include breakpoint(small down) {
  .featured-products-block {
    .featured-products-block-content {
      .tabs-panel {
        .button {
          width: 100%;
        }
      }
    }
    .quote-bubble {
      &:before {
        display: none;
      }
      .copy-text {
        font-size: rem-calc(16);
        line-height: rem-calc(22);
      }
      .copy-reviewer {
        padding-right: 48%;
        font-size: rem-calc(16);
        line-height: rem-calc(25);
      }
      .copy-title {
        padding-right: 48%;
        font-size: rem-calc(13);
        line-height: rem-calc(20);
      }
    }
  }
}
